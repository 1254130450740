import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import backendApis from '../../utils/backendApis'
import AuthStore from '../../stores/AuthStore'
import converter from '../../utils/converter'

const LikeButton = observer(({ likeCount, url }) => {
  const token = AuthStore?.token
  const likeCountTemp = converter.likeConverter(likeCount)

  const [like, setLike] = useState(false)

  const onClick = () => {
    if (like) {
      setLike(false)
    } else {
      setLike(true)
      backendApis.like(token, 'POST', { url })
    }
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      {!like ? (
        <img
          src='/images/likeWhite.png'
          alt='like_white'
          className='mb-1 w-7 h-7'
        />
      ) : (
        <img
          src='/images/likeRed.png'
          alt='like_red'
          className='mb-1 w-7 h-7 press-like'
        />
      )}
      <div className='text-sm font-bold text-white'>{likeCountTemp}</div>
    </div>
  )
})

export default LikeButton
