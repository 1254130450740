import { observer, reaction } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import RepostVideoStore from '../../../stores/repostVideoStore'
import ModalStore from '../../../stores/ModalStore'
import backendApis from '../../../utils/backendApis'
import AuthStore from '../../../stores/AuthStore'

const RepostActivityViewButton = observer(({ currentVideoIndex }) => {
  if (RepostVideoStore?.videos.length === 0) return
  const token = AuthStore?.token
  const currentRepostId = RepostVideoStore?.videos[currentVideoIndex]._id
  const [repostViewerCount, setRepostViewerCount] = useState(0)
  const onClick = async () => {
    ModalStore.setCurrentBottomSheet({
      open: true,
      type: 'repostReactors',
      repostId: currentRepostId,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return
      const result = await backendApis.repostDetail(
        token,
        'GET',
        currentRepostId,
        { fields: 'viewerCount' },
      )
      if (result?.status === 200) {
        setRepostViewerCount(result?.data?.viewerCount)
      }
    }
    fetchData()
  }, [token, currentRepostId])

  return (
    <button
      type='button'
      onClick={onClick}
      className='fixed w-[7rem] h-12 right-5 bottom-24 bg-[#454545cd] rounded-full text-white text-sm flex flex-row items-center justify-center p-3'
    >
      <div>
        <img
          src='/images/heart.png'
          alt='heart'
          aria-hidden='true'
          className='w-4 h-4 mr-2'
        />
      </div>
      <div>{`${repostViewerCount}명 공감`}</div>
    </button>
  )
})

export default RepostActivityViewButton
