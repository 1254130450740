import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import RepostVideoStore from '../../../stores/repostVideoStore'
import timeUtils from '../../../utils/timeUtils'

const TextDiv = ({ children, className }) => (
  <div className={`absolute text-white ${className}`}>{children}</div>
)

const RepostCloseButton = observer(({ reposterInfo }) => {
  const { userName } = reposterInfo || {}
  const { teamInfo, videos, currentIndex } = RepostVideoStore || {}
  const { teamName } = teamInfo || {}
  const currentVideo = videos?.[currentIndex]
  const teamReposterName = currentVideo?.userName
  const repostedAt = currentVideo?.createdAt
  const navigate = useNavigate()

  const onRepostClose = () => {
    navigate('/')
  }

  return (
    <div className='fixed w-full h-20 bg-gradient-to-b from-[#000000] to-[#00000000] top-0'>
      <button
        type='button'
        onClick={onRepostClose}
        className='absolute top-6 w-4 h-4 left-5'
      >
        <img src='/images/backWhite.png' alt='close-friend-repost-feed' />
      </button>

      <TextDiv className='top-5 left-14 font-bold text-lg '>
        {teamName ? `${teamName}의 같이보기` : `${userName}의 같이보기`}
      </TextDiv>
      {teamReposterName && (
        <TextDiv className='top-12 left-14 text-sm'>
          {teamReposterName}님의 공유 영상
        </TextDiv>
      )}

      <TextDiv className='top-12 right-5 text-sm'>
        {timeUtils.getRelativeTime(repostedAt)}
      </TextDiv>
    </div>
  )
})

export default RepostCloseButton
