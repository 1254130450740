import { Oval } from 'react-loader-spinner'

const Loading = ({ isLoading, full = true }) => {
  if (!isLoading) return null
  return (
    <div
      aria-hidden='true'
      className={`z-10 flex items-center justify-center ${
        full ? 'w-screen h-screen' : 'w-screen h-100'
      }`}
    >
      <Oval color='#EA3F49' secondaryColor='#212121' height='40' width='40' />
    </div>
  )
}

export default Loading
