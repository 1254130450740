import { observer } from 'mobx-react-lite'
import React from 'react'

import useStatistics from '../../hooks/useStatistics'
import LikeButton from './likeButton'
import CommentButton from './commentButton'
import VideoStore from '../../stores/VideoStore'

const ChannelInteractions = observer(({ currentIndex }) => {
  const currentVideo = VideoStore?.channelVideos?.[currentIndex]
  const { likeCount, commentCount } = useStatistics({ src: currentVideo })

  return (
    <div
      className='absolute z-30 right-4'
      style={{ bottom: `${-100 * currentIndex + 24}%` }}
    >
      <LikeButton likeCount={likeCount} />
      <div className='h-6' />
      <CommentButton commentCount={commentCount} url={currentVideo} />
    </div>
  )
})

export default ChannelInteractions
