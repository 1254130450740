import { useReward } from 'react-rewards'
import { observer } from 'mobx-react-lite'
import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'

const RepostEmoticonReaction = observer(
  ({ emoji, emojiName, userId, repostId, videoId, onEmojiClick }) => {
    const token = AuthStore?.token
    const sessionId = AuthStore?.sessionId
    const fromUserId = UserStore?.userInfo?.userId
    const viewerImageUri = UserStore?.userInfo?.userImageUri
    const viewerName = UserStore?.userInfo?.userName
    // front 이슈로 Uri 가 undefined 되는 경우를 봄, 이를 방지하기 위해 Backend 에서 한번 호출하는 것이 나을 것 같음

    const { reward, isAnimating } = useReward(emojiName, 'emoji', {
      emoji: [emoji],
      lifetime: 150,
      angle: 90,
      elementCount: 8,
      elementSize: 20,
    })

    const onClick = () => {
      reward()

      const eventName = 'clickReaction'
      const eventDesc = {
        followeeId: userId,
        emoji,
        emojiName,
        version: process.env.REACT_APP_VERSION,
      }
      backendApis.eventLog(token, 'POST', {
        userId: fromUserId,
        sessionId,
        eventName,
        eventDesc,
      })
      backendApis.repostViewerActivity(token, 'POST', repostId, {
        activityType: 'react',
        viewerId: userId,
        reactedEmoji: emoji,
      })
      onEmojiClick()
    }

    return (
      <button
        type='button'
        className='flex-1 text-4xl select-none'
        onClick={onClick}
        disabled={isAnimating}
      >
        <span id={emojiName} />
        {emoji}
      </button>
    )
  },
)
export default RepostEmoticonReaction
