import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import backendApis from '../../../utils/backendApis'
import AuthStore from '../../../stores/AuthStore'
import converter from '../../../utils/converter'
import ModalStore from '../../../stores/ModalStore'

const RepostVideoSettingButton = observer(({ teamId, url }) => {
  const token = AuthStore?.token

  const onClick = () => {
    ModalStore?.setCurrentModal({
      open: true,
      mainType: 'whiteBackground',
      type: 'deleteRepostVideo',
    })
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      <img
        src='/images/buttons/deleteButton.png'
        alt='settingButton'
        className='mb-1 w-10 h-10'
      />
      <div className='text-sm font-bold text-white'>삭제</div>
    </div>
  )
})

export default RepostVideoSettingButton
