import React from 'react'
import UserStore from '../../stores/UserStore'

const Toast = ({ info, setInfo, from = null }) => {
  const open = info?.open
  const duration = info?.duration

  if (!open || !from) return null
  setTimeout(() => setInfo({ open: false }), duration)

  if (from === 'money') {
    const addCoin = info?.addCoin || 0
    if (!addCoin) return null

    return (
      <div className='absolute flex flex-row items-center justify-center bottom-14 -left-2 animate-bounce '>
        <img src='/images/smallCoin.png' alt='smallCoin' className='w-6 h-6' />
        <span className='text-md font-bold text-[#FAE194]'>+{addCoin}</span>
      </div>
    )
  }
  if (from === 'coindrop') {
    return (
      <>
        <div className='absolute z-0 trapezoid left-3 top-14' />
        <img
          src='/images/coindrop.png'
          alt='coindrop'
          className='absolute w-4 h-6 left-4 top-14'
        />
      </>
    )
  }
  if (from === 'withdraw') {
    return (
      <div className='absolute bottom-14 right-4 bg-[#FDF1D9] animate-bounce flex items-center justify-center p-2 rounded-xl border-2 border-[#EEBB86]'>
        3천원 이상부터
        <br />
        출금할 수 있어요!
      </div>
    )
  }

  if (from === 'nightCoin') {
    const coin = info?.coin || 0
    const video = info?.video || 0
    if (!coin || !video) return null

    return (
      <>
        <div className='absolute z-10 w-32 h-12 bg-black rounded-full right-3 opacity-40 top-20' />
        <div className='absolute z-20 p-2 text-sm font-bold leading-4 text-white w-22 top-20 right-10'>
          {video}초 보고, <br />
          <span className='font-bold text-yellow-300'>{coin}</span> 골드
        </div>
      </>
    )
  }
}

export default Toast
