import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import SwipeableViews from 'react-swipeable-views'
import { useSearchParam } from 'react-use'

import AuthStore from '../../../stores/AuthStore'
import RepostVideoStore from '../../../stores/repostVideoStore'
import VideoStore from '../../../stores/VideoStore'
import backendApis from '../../../utils/backendApis'

const RepostCarousel = observer(
  ({ onFolloweeRepostClose, videoLength, children }) => {
    const token = useSearchParam('token') || AuthStore?.token

    let viewLog
    if (VideoStore?.isFocused) {
      viewLog = {
        enteredAt: new Date(),
        url: RepostVideoStore?.videos?.[RepostVideoStore?.currentIndex]?.url,
        type: 'youtube',
        sessionId: AuthStore?.sessionId,
      }
    }
    const updateIndex = (newIndex) => {
      if (newIndex >= 0) {
        setTimeout(() => RepostVideoStore.setCurrentIndex(newIndex), 40)
        backendApis.userViewLog(token, 'POST', {
          ...viewLog,
          leftAt: new Date(),
        })
      }
      if (newIndex === videoLength) {
        RepostVideoStore.setCurrentIndex(null)
        RepostVideoStore.setVideos([])
        onFolloweeRepostClose()
      }
    }

    const memoizedChildren = useMemo(
      () =>
        React.Children.map(children, (child) =>
          React.cloneElement(child, { width: '100%', height: '100%' }),
        ),
      [children],
    )

    return (
      <SwipeableViews
        axis='y'
        resistance
        enableMouseEvents
        index={RepostVideoStore?.currentIndex || 0}
        onChangeIndex={updateIndex}
        containerStyle={{ height: '100vh', width: '100vw' }}
        style={{ height: '100vh', width: '100vw' }}
        slideStyle={{ transitionDuration: '0.1s' }}
      >
        {memoizedChildren}
      </SwipeableViews>
    )
  },
)

export default RepostCarousel
