import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import UserStore from '../../stores/UserStore'
import VideoStore from '../../stores/VideoStore'

const first = [
  '김',
  '이',
  '박',
  '최',
  '강',
  '한',
  '장',
  '양',
  '전',
  '윤',
  '방',
  '유',
  '나',
  '문',
  '안',
  '홍',
  '서',
  '정',
  '하',
  '도',
]
const last = [
  '윤',
  '성',
  '현',
  '환',
  '직',
  '우',
  '식',
  '남',
  '총',
  '추',
  '창',
  '동',
  '달',
  '미',
  '현',
  '준',
  '헌',
  '지',
  '구',
  '각',
  '우',
  '웅',
  '반',
]

const RANDOM = Math.floor(Math.random() * 4 + 4)
const RANDOM2 = Math.floor(Math.random() * 2 + 15)

const name = `${first[Math.floor(Math.random() * first.length)]}*${
  last[Math.floor(Math.random() * last.length)]
}`

const WithdrawExample = observer(() => {
  const currentIndex = VideoStore?.currentIndex
  const onboarding = UserStore?.onboarding

  const [visible, setVisible] = useState(null)

  useEffect(() => {
    if (onboarding) {
      if (currentIndex !== RANDOM2) return
    } else if (currentIndex !== RANDOM) return
    if (visible !== null) return
    setTimeout(() => setVisible(true), 500)
    window.location.href = '#vibrate'
    setTimeout(() => setVisible(false), 3500)
  }, [currentIndex])

  return (
    <div
      className={`${visible === true && 'fade-in-100'} ${
        visible === false && 'fade-out-0'
      } ${
        visible === null && 'opacity-0'
      } absolute p-2 text-sm font-bold text-white w-52 -top-16 rounded-lg`}
      style={{ backgroundColor: '#24000095' }}
    >
      {name} 님이 3,000원을 출금했어요
    </div>
  )
})

export default WithdrawExample
