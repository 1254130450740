import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import ModalStore from '../../../stores/ModalStore'
import UserStore from '../../../stores/UserStore'
import AuthStore from '../../../stores/AuthStore'
import backendApis from '../../../utils/backendApis'

const RepostButton = observer(({ url, videoId }) => {
  const onClick = async () => {
    const teamList = UserStore?.teamList ?? []
    if (teamList?.length) {
      ModalStore.setCurrentBottomSheet({
        open: true,
        type: 'repostTeam',
        teamList,
      })
    } else if (localStorage.getItem('repostGuideAt')) {
      ModalStore.setCurrentBottomSheet({
        open: true,
        type: 'createTeam',
      })
    } else {
      localStorage.setItem('repostGuideAt', Date.now())
      ModalStore.setCurrentModal({
        open: true,
        type: 'repostTeamGuide',
        mainType: 'whiteBackground',
      })
    }
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      <img
        src='/images/friendFeed/repostTeam.png'
        alt='share'
        className='w-7 h-7 mb-1'
      />
      <div className='text-sm font-bold text-white'>같이보기</div>
    </div>
  )
})

export default RepostButton
