import React from 'react'
import RepostVideoStore from '../../../stores/repostVideoStore'

const ViewerActivity = ({ viewer }) => {
  if (!viewer) return null

  const { userImageUri, userName } = viewer

  return (
    <div className='flex flex-row items-center w-full px-3 py-2'>
      {/* 왼쪽 이미지 */}
      <div className='ml-2 mr-2 w-14'>
        <img
          src={userImageUri}
          alt='user_profile'
          className='object-cover w-8 h-8 rounded-full'
          onError={(event) => {
            event.target.src = '/images/videoPlayer/defaultProfile.jpeg'
            event.onerror = null
          }}
        />
      </div>
      <div className='flex flex-row w-full'>
        <div className='mr-1'>{`${
          userName?.length > 8 ? `${userName.slice(0, 8)}...` : userName
        } 님`}</div>
      </div>
    </div>
  )
}

const RepostViewerList = ({ index }) => {
  const target = RepostVideoStore?.videos?.[index]
  if (!target) return null

  const viewers = target?.viewers

  const onClickInvite = () => {
    window.location.href = `#shareLink.${JSON.stringify({
      shareType: 'ShortsFriendLink',
      title: '[올웨이즈] 친구야 같이 보자!',
      description: '친구가 좋아하는 영상을 같이 시청해 보세요.',
      imageUrl:
        'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
    })}`
  }

  if (viewers?.length > 0) {
    return (
      <div className='w-full'>
        <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 rounded-t-lg h-14 border-b-gray-50'>
          같이 본 사람 {viewers.length}명
        </div>

        <div className='w-full mt-10'>
          {viewers.map((each) => (
            <ViewerActivity key={each._id} viewer={each} />
          ))}
        </div>
      </div>
    )
  }
  if (!viewers?.length || viewers?.length === 0) {
    return (
      <div className='w-full'>
        <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 rounded-t-lg h-14 border-b-gray-50'>
          같이 본 사람 0명
        </div>
        <div className='flex flex-col items-center'>
          <div className='w-full p-4 mt-10 text-center'>
            같이 영상 보고 싶은 친구를 초대해 보세요
          </div>
          <div
            className='w-5/6 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] text-lg rounded-3xl text-white text-center font-bold scale my-4'
            aria-hidden='true'
            onClick={onClickInvite}
          >
            친구 초대하기
          </div>
        </div>
      </div>
    )
  }
}

export default RepostViewerList
