import React from 'react'
import { observer } from 'mobx-react-lite'
import { useSearchParam } from 'react-use'

import backendApis from '../../utils/backendApis'
import UserStore from '../../stores/UserStore'
import ModalStore from '../../stores/ModalStore'
import { useNavigate } from 'react-router-dom'
import AuthStore from '../../stores/AuthStore'

const onClickExchange = async (navigate, coin, money, token, from) => {
  if (from === 'onboarding' && coin < 5000) {
    navigate('/money')
    ModalStore.setCurrentBottomSheet({ open: false })
    return
  }
  if (money >= 3000) {
    ModalStore.setCurrentBottomSheet({ open: false })
    ModalStore.setCurrentModal({
      open: true,
      type: 'over3000',
    })
    return
  }
  const result = await backendApis.money(token, 'POST', { type: 'exchange' })
  if (result?.status === 200) {
    UserStore.setMoneyInfo(result?.data)
  } else if (result?.status === 201) {
    ModalStore.setCurrentBottomSheet({ open: false })
    ModalStore.setCurrentModal({
      open: true,
      type: 'coinLack',
      minValue: result?.minValue || 5000,
    })
  } else if (result?.status === 202) {
    ModalStore.setCurrentBottomSheet({ open: false })
    ModalStore.setCurrentModal({
      open: true,
      type: 'over3000',
    })
  }
}

const onClickWithdraw = (navigate) => {
  navigate('/money')
  ModalStore.setCurrentBottomSheet({ open: false })
}

const ExchangeSection = observer(({ from = null }) => {
  if (!UserStore?.moneyInfo) return
  const coin = UserStore?.moneyInfo?.coin || 0
  const money = UserStore?.moneyInfo?.money || 0
  const token = AuthStore?.token
  const onboarding = UserStore?.onboarding
  const navigate = useNavigate()

  return (
    <div className='py-4 w-full h-20 flex flex-row justify-between bg-[#FDF2E6] fixed'>
      {/* 왼쪽 */}
      <div className='px-4 w-1/2 h-full border-r-2 border-[#F6DBCF]'>
        <div className='flex flex-row items-center'>
          <div className='text-[#CE4031] mr-1'>내 골드</div>
          <img
            src='/images/smallCoin.png'
            alt='smallCoin'
            className='w-5 h-5'
          />
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='text-[#CE4031] text-2xl font-bold'>{coin}</div>
          <div
            aria-hidden='true'
            className='px-2 rounded-2xl bg-gradient-to-b from-[#FCF1E7] to-[#F9D9B3] border-2 border-[#EEBB86] text-[#B23C3A] font-bold'
            onClick={() => onClickExchange(navigate, coin, money, token, from)}
          >
            {from === 'onboarding' && coin >= 5000 && (
              <img
                src='/images/downSign.png'
                alt='downSign'
                className='absolute w-8 h-8 bottom-12 animate-bounce'
              />
            )}
            환전
          </div>
        </div>
      </div>
      {/* 오른쪽 */}
      <div className='w-1/2 h-full px-4'>
        <div className='flex flex-row items-center'>
          <div className='text-[#CE4031] mr-1'>내 현금</div>
          <img src='/images/money.png' alt='money' className='w-5 h-5' />
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='text-[#CE4031] text-2xl font-bold'>
            {money}
            <span className='text-sm ml-0.5'>원</span>
          </div>
          <div
            aria-hidden='true'
            className='px-2 rounded-2xl bg-gradient-to-b from-[#FDF1D9] to-[#F5BE5D] border-2 border-[#EEBB86] text-[#B23C3A] font-bold'
            onClick={() => onClickWithdraw(navigate, from, onboarding)}
          >
            {from === 'onboarding' && coin < 5000 && (
              <img
                src='/images/downSign.png'
                alt='downSign'
                className='absolute w-8 h-8 bottom-12 animate-bounce'
              />
            )}
            출금
          </div>
        </div>
      </div>
    </div>
  )
})

export default ExchangeSection
