import React from 'react'

const VisibilityMark = ({ visible }) => {
  if (!visible) return

  return (
    <img
      src='/images/mark.png'
      alt='mark'
      className='absolute left-0 z-30 w-5 h-6 -top-2'
    />
  )
}

export default VisibilityMark
