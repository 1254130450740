import { observer } from 'mobx-react-lite'
import React, { useState, useRef, useEffect } from 'react'
import YouTube from 'react-youtube'
import RepostVideoStore from '../../../stores/repostVideoStore'
import RepostDurationGauge from './repostDurationGauge'
import VideoStore from '../../../stores/VideoStore'
import RepostPlayerInteractions from '../repostPlayerInteractions'
import RepostVideoDescription from '../repostVideoDescription'

const opts = {
  playerVars: {
    autoplay: 1,
    controls: 0,
    playsinline: 1,
    webkitplaysinline: 1,
    modestbranding: 1,
    fs: 0,
    quality: 'medium',
    allowfullscreen: 0,
    webkitallowfullscreen: 0,
  },
}

const RepostPlayer = observer(({ src, index, currentIndex }) => {
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)

  // 스크롤 할때 자동재생
  useEffect(() => {
    if (!videoRef?.current) return
    if (currentIndex === index - 1) {
      videoRef?.current?.internalPlayer.mute()
      // videoRef?.current?.internalPlayer.seekTo(0)
    } else if (currentIndex === index + 1) {
      videoRef?.current?.internalPlayer.seekTo(0)
      videoRef?.current?.internalPlayer.pauseVideo()
      videoRef?.current?.internalPlayer.mute()
    } else if (currentIndex === index) {
      videoRef?.current?.internalPlayer.seekTo(0)
      videoRef?.current?.internalPlayer.playVideo()
      videoRef?.current?.internalPlayer.unMute()
      RepostVideoStore.setStatus('play')
    }
  }, [currentIndex])

  useEffect(() => {
    if (currentIndex !== index) return

    if (RepostVideoStore?.isFocused) {
      videoRef?.current?.internalPlayer.playVideo()
      videoRef?.current?.internalPlayer.unMute()
      RepostVideoStore.setStatus('play')
    } else {
      videoRef?.current?.internalPlayer.pauseVideo()
      videoRef?.current?.internalPlayer.mute()
      RepostVideoStore.setStatus('pause')
    }
  }, [RepostVideoStore?.isFocused])

  useEffect(() => {
    if (currentIndex !== index) return

    if (VideoStore?.isFocused) {
      videoRef?.current?.internalPlayer.playVideo()
      videoRef?.current?.internalPlayer.unMute()
      RepostVideoStore.setStatus('play')
    } else {
      videoRef?.current?.internalPlayer.pauseVideo()
      videoRef?.current?.internalPlayer.mute()
      RepostVideoStore.setStatus('pause')
    }
  }, [VideoStore?.isFocused])

  const handlePlayPause = () => {
    if (currentIndex !== index) return
    if (!videoRef?.current) return
    if (playing) {
      videoRef?.current?.internalPlayer.pauseVideo()
      RepostVideoStore.setStatus('pause')
      setPlaying(false)
    } else {
      videoRef?.current?.internalPlayer.playVideo()
      RepostVideoStore.setStatus('play')
      setPlaying(true)
    }
  }

  const onEnd = () => {
    if (currentIndex !== index) return
    if (!videoRef?.current) return
    RepostVideoStore.setStatus('loop')
    videoRef?.current?.internalPlayer.playVideo()
  }

  const onReady = (e) => {
    const state = e.target.getPlayerState()
    if (state !== 3) {
      RepostVideoStore.setVideos(
        RepostVideoStore?.videos?.filter((each) => each?.url !== src),
      )
    }
    RepostVideoStore.setDuration({
      ...RepostVideoStore?.duration,
      [index]: e.target.playerInfo.duration,
      [index - 3]: null,
      [index + 3]: null,
    })
  }

  return (
    <div
      id={index}
      aria-hidden={index === currentIndex ? 'false' : 'true'}
      className='z-30 w-screen h-screen'
    >
      <div
        className='absolute w-full h-full bg-transparent'
        aria-hidden='true'
        onClick={handlePlayPause}
      />
      <YouTube
        ref={videoRef}
        className='w-full h-full'
        iframeClassName={currentIndex <= index ? 'w-full h-full' : 'w-2 h-2'}
        videoId={src}
        opts={opts}
        onEnd={onEnd}
        onReady={onReady}
      />
      {currentIndex === index && (
        <RepostDurationGauge currentIndex={currentIndex} />
      )}
      <RepostPlayerInteractions index={index} currentIndex={currentIndex} />
      {/* {currentIndex === index && <RepostVideoDescription index={index} />} */}
    </div>
  )
})

export default RepostPlayer
