import React from 'react'
import ExchangeSection from '../molecules/exchangeSection'

const QuestWithdraw = () => (
  <div>
    <img src='/images/congrats.gif' alt='congrats' />
    <div className='absolute flex flex-col items-center justify-center w-full -mt-12'>
      <div className='font-bold text-white'>축하드려요!</div>
      <div className='h-4' />
      <div className='font-bold text-white'>
        총 <span className='text-[#ffce49]'>5만 골드</span>를 획득했어요
      </div>
      <div className='font-bold text-white'>아래에서 환전할 수 있어요</div>
      <div className='h-12' />
    </div>
    <div className='absolute bottom-0 w-full'>
      <div className='w-full h-4 bg-white arch rounded-tl-xl rounded-tr-xl' />
      <ExchangeSection from='onboarding' />
      <div className='h-20' />
      <div className='w-full h-4 bg-white arch rounded-bl-xl rounded-br-xl' />
      <div className='w-full h-10 bg-black' />
    </div>
  </div>
)

export default QuestWithdraw
