import converter from './converter'

class Check {
  checkCodepush(userCodepush, serviceCodepush) {
    const user = converter.codepushConverter(userCodepush)
    const service = converter.codepushConverter(serviceCodepush)

    if (user && service && user >= service) return true
    return false
  }
}

export default new Check()
