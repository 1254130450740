import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './tailwind.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.001,
  enabled: process.env.NODE_ENV === 'production',

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  // https://github.com/react-native-webview/react-native-webview/issues/2680
  ignoreErrors: ["undefined is not an object (evaluating 'window.webkit.messageHandlers')"]
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
