import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RepostVideos from '../components/organisms/repost/repostVideos'
import RepostVideoStore from '../stores/repostVideoStore'
import { useSearchParam } from 'react-use'
import AuthStore from '../stores/AuthStore'
import backendApis from '../utils/backendApis'
import UserStore from '../stores/UserStore'
import RepostCloseButton from '../components/organisms/repost/repostCloseButton'
import VideoStore from '../stores/VideoStore'
import ModalStore from '../stores/ModalStore'
import { Oval } from 'react-loader-spinner'

const RepostScreen = observer(() => {
  const navigate = useNavigate()
  const reposterId = useSearchParam('reposterId')
  const teamId = useSearchParam('teamId')
  const isFirstTeam = useSearchParam('isFirstTeam')
  // TODO: reposterId 없으면 랜딩 안됨
  const repostId = useSearchParam('repostId')
  const token = useSearchParam('token') || AuthStore?.token
  const [isLoading, setIsLoading] = useState(true)
  const [reposterInfo, setRepostInfo] = useState({})
  const sessionId = AuthStore?.sessionId
  const viewerId = UserStore?.userInfo?.userId
  const onFolloweeRepostClose = () => {
    navigate('/')
  }

  // Landing Page 초기화 Effect
  useEffect(() => {
    const initialLandingPage = UserStore?.initialLandingPage
    if (initialLandingPage?.pathname === '/reposts') {
      // 초기 랜딩 페이지가 ; 이고 랜딩 완료되었으니, 초기화 한다.
      UserStore.initializeInitialLandingPage()
    }
  }, [UserStore?.initialLandingPage])

  // RepostVideo 초기화 Effect
  useEffect(() => {
    if (!token) return
    // eventLog
    if (teamId) {
      // TODO: eventLog

      // RepostVideo 초기화
      VideoStore?.setIsFocused(true)
      RepostVideoStore?.setCurrentIndex(0)
      RepostVideoStore?.setIsFocused(true)
      RepostVideoStore?.setStatus(null)
      RepostVideoStore?.setDuration(null)
      RepostVideoStore?.setVideos([])

      const fetchData = async () => {
        /* teamInfo First */
        const teamInfoResult = await backendApis.repostTeamInfo(token, 'GET', {
          teamId,
        })
        RepostVideoStore?.setTeamInfo(teamInfoResult?.data)

        /* 공유된 비디오 정보리스트 */
        // TODO: 유효 시간 필터링 ex. 24시간 이내인 것만 가져오기 등등
        const reostVideoResult = await backendApis.repostTeamVideo(
          token,
          'GET',
          {
            teamId,
          },
        )
        if (
          reostVideoResult?.status === 200 &&
          reostVideoResult?.data?.length
        ) {
          RepostVideoStore.setVideos([...reostVideoResult.data])
          // repostId 를 가지고 들어온 경우 해당 비디오로 바로 이동시킨다.
          if (repostId) {
            RepostVideoStore.videos.forEach((video, index) => {
              if (video._id === repostId) {
                // TODO: 해당 비디오의 인덱스를 첫번째로 만들기
                RepostVideoStore.setCurrentIndex(index)
              }
            })
          }
        }
        setIsLoading(false)
        if (isFirstTeam === 'true') {
          ModalStore?.setCurrentModal({
            open: true,
            type: 'createTeamFirst',
            coin: 5000,
          })
        }
      }
      fetchData()

      /* 내가 봤던 영상은 후순위로 밀기 */
      /* 오늘 다봤으면 toast: 오늘 공유된 영상을 다 봤어요! */
      /* 이미 봤냐?: 이것도 7일치만 저장해서 확인, 48시간동안 공유돼요 */
      /* team이 48시간 이내에 공유한 영상들 불러옴: 본사람 수 같이 기록됨 uniqueViewCount */
      /* D7 올리려면 재미 or 보상 도파민 */
    } else {
      const eventName = 'pageviewFolloweeRepost'
      const eventDesc = {
        followeeId: reposterId,
        version: process.env.REACT_APP_VERSION,
      }
      backendApis.eventLog(token, 'POST', {
        userId: viewerId,
        sessionId,
        eventName,
        eventDesc,
      })

      // RepostVideoStore 초기화
      VideoStore?.setIsFocused(true)
      RepostVideoStore?.setTeamInfo({})
      RepostVideoStore.setCurrentIndex(0)
      RepostVideoStore.setIsFocused(true)
      RepostVideoStore.setStatus(null)
      RepostVideoStore.setDuration(null)
      RepostVideoStore.setVideos([])

      // Repost Video 는 우선 1번에 공유한 비디오를 가져온다.
      const fetchData = async () => {
        if (!token) return

        // reposter 의 정보를 가져와, 이름을 표시한다.
        const reposterInfoResult = await backendApis.userInfo(
          token,
          'GET',
          {},
          reposterId,
        )

        if (reposterInfoResult?.status === 200) {
          setRepostInfo(reposterInfoResult.data)
        }

        const result = await backendApis.repost(token, 'GET', {
          userId: reposterId,
        })
        if (result?.status === 200 && result?.data?.length) {
          RepostVideoStore.setVideos([...result.data])

          // repostId 를 가지고 들어온 경우 해당 비디오로 바로 이동시킨다.
          if (repostId) {
            RepostVideoStore.videos.forEach((video, index) => {
              if (video._id === repostId) {
                RepostVideoStore.setCurrentIndex(index)
              }
            })
          }
        }
        setIsLoading(false)
      }
      fetchData()
    }
  }, [token, reposterId, repostId, teamId, isFirstTeam])

  return (
    <div className='relative bg-black'>
      <div className='absolute bg-black'>
        {RepostVideoStore?.videos?.length >= 1 ? (
          <RepostVideos
            reposterId={reposterId}
            viewerId={viewerId}
            onFolloweeRepostClose={onFolloweeRepostClose}
            isLoading={isLoading}
          />
        ) : (
          <div className='absolute w-[100vw] h-[100vh] flex justify-center items-center text-white font-semibold text-lg'>
            {isLoading ? (
              <Oval
                color='#e5e5e5'
                secondaryColor='#ffffff'
                height='60'
                width='60'
                strokeWidth={5}
              />
            ) : (
              <div>공유된 영상이 없습니다.</div>
            )}
          </div>
        )}
      </div>
      {!isLoading && <RepostCloseButton reposterInfo={reposterInfo} />}
    </div>
  )
})

export default RepostScreen
