import { useNavigate } from 'react-router-dom'
import ModalStore from '../../../stores/ModalStore'
import UserStore from '../../../stores/UserStore'

const FriendProfile = ({
  userId,
  userImageUri,
  userName,
  userLastRepostCreatedAt,
}) => {
  if (!userId) return null
  const navigate = useNavigate()
  const onClick = () => {
    if (userLastRepostCreatedAt) {
      navigate(`/reposts?reposterId=${userId}`)
    } else if (userId !== UserStore?.userInfo?.userId) {
      ModalStore.setCurrentModal({
        open: true,
        type: 'friendRepostNudge',
        friendId: userId,
        friendName:
          userName.length > 5 ? `${userName.substr(0, 5)}...` : userName,
        myName:
          UserStore?.userInfo?.userName?.length > 5
            ? `${UserStore?.userInfo?.userName?.substr(0, 5)}...`
            : UserStore?.userInfo?.userName,
      })
    } else {
      ModalStore.setCurrentModal({
        open: true,
        type: 'noRepost',
      })
    }
  }
  return (
    <div className='flex-start'>
      <button
        type='button'
        key={userId}
        className='flex flex-col items-center h-16 mx-0.5 w-16'
        // onClick={onClick}
      >
        <div
          className={`rounded-full p-[0.15rem] flex flex-col items-center justify-center ${
            userLastRepostCreatedAt
              ? 'bg-gradient-to-r from-[#ff56d5] via-red-500 to-yellow-500'
              : 'bg-[#858585]'
          }
          bg-[#858585]
          `}
        >
          <div className='w-11 h-11 rounded-full bg-[#2e2e2e] flex items-center justify-center'>
            <img
              src={userImageUri}
              alt='add-user '
              className='flex items-center justify-center object-cover w-10 h-10 rounded-full'
            />
          </div>
        </div>

        <div className='mt-1 text-xs text-white'>
          {userName.length > 5 ? `${userName.substr(0, 5)}...` : userName}
        </div>
      </button>
    </div>
  )
}

export default FriendProfile
