import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import Toast from '../atoms/toast'
import initialize from '../../utils/initialize'
import MoneyInfo from './moneyInfo'
import VideoStore from '../../stores/VideoStore'
import backendApis from '../../utils/backendApis'

const TomorrowCoin = observer(() => {
  const token = AuthStore?.token
  const tomorrowCoin = UserStore?.moneyInfo?.tomorrowCoin || 0
  const [toastInfo, setToastInfo] = useState(false)

  const getCoin = toastInfo?.open

  useEffect(() => {
    setToastInfo({ open: true, duration: 1500 })
  }, [tomorrowCoin])

  useEffect(() => {
    if (
      !tomorrowCoin ||
      UserStore?.questLog?.getTomorrowCoin === false ||
      ModalStore?.currentModal?.open ||
      !VideoStore?.isFocused
    ) {
      return
    }
    initialize.tomorrowCoin(token)
  }, [
    tomorrowCoin,
    UserStore?.questLog?.getTomorrowCoin,
    ModalStore?.currentModal?.open,
    VideoStore?.isFocused,
  ])

  return (
    <>
      <Toast info={toastInfo} setInfo={setToastInfo} from='coindrop' />
      <div
        className='absolute flex flex-col items-center top-20'
        aria-hidden='true'
        onClick={() => {
          ModalStore.setCurrentModal({
            open: true,
            mainType: 'noBackground',
            type: 'tomorrowCoinInfo',
            value: tomorrowCoin,
          })
          const sessionId = AuthStore?.sessionId
          const userId = UserStore?.userInfo?.userId
          const eventName = 'tomorrowCoin'
          const eventDesc = {
            version: process.env.REACT_APP_VERSION,
          }
          backendApis.eventLog(token, 'POST', {
            userId,
            sessionId,
            eventName,
            eventDesc,
          })
        }}
      >
        <img
          src='/images/moneybag.png'
          alt='moneybag'
          className={`w-12 h-12 z-20 ${getCoin ? 'vibrate' : null}`}
        />
        <span
          className={`absolute top-6 z-20 text-xs font-bold text-[#CF3930] drop-shadow-lg ${
            getCoin ? 'vibrate' : null
          }`}
        >
          {tomorrowCoin}
        </span>
        <span className='bg-gradient-to-b z-20 from-[#EB7766] to-[#CF3930] w-12 absolute top-10 text-xs rounded-lg text-white text-center'>
          내일획득
        </span>
        <MoneyInfo from='tomorrowCoin' dependency={toastInfo?.open} />
      </div>
    </>
  )
})

export default TomorrowCoin
