import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import initialize from '../../utils/initialize'
import MoneyTimer from '../molecules/moneyTimer'
import DailyCheckIn from '../molecules/dailyCheckIn'
import ExtraCoin from './extraCoin'
import Onboarding from '../molecules/onboarding'
import FriendFeed from '../molecules/repost/friendFeed'
import WithdrawExample from '../molecules/withdrawExample'
import DailyInvite from '../molecules/dailyInvite'

const Services = observer(() => {
  const token = AuthStore?.token
  const userId = UserStore?.userInfo?.userId
  const navigate = useNavigate()

  useEffect(() => {
    // service = money + quest
    initialize.service(token, userId, navigate)
  }, [token, userId])

  if (!UserStore?.moneyInfo) return null

  return (
    <>
      <FriendFeed />

      {/* 왼쪽 */}
      <div className='absolute z-10 flex flex-col justify-center left-4 top-32'>
        <WithdrawExample />
        <DailyInvite />
        <DailyCheckIn />
      </div>

      {/* 오른쪽 */}
      <div className='absolute z-10 flex flex-col justify-center right-2 top-32'>
        <MoneyTimer />
        <ExtraCoin />
      </div>

      {/* 온보딩 */}
      <Onboarding />
    </>
  )
})

export default Services
