import { observer } from 'mobx-react-lite'
import AuthStore from '../../../stores/AuthStore'
import backendApis from '../../../utils/backendApis'
import { useEffect, useState } from 'react'

const ViewerActivity = ({ viewer }) => {
  if (!viewer) return null

  const { viewerImageUri, viewerName, reactedEmoji } = viewer

  return (
    <div className='flex flex-row items-center w-full px-3 mb-5'>
      {/* 왼쪽 이미지 */}
      <div className='ml-2 mr-4 w-14'>
        <img
          src={viewerImageUri}
          alt='user_profile'
          className='object-cover w-10 h-10 rounded-full'
          onError={(event) => {
            event.target.src = '/images/videoPlayer/defaultProfile.jpeg'
            event.onerror = null
          }}
        />
      </div>
      <div className='flex flex-row w-full'>
        <div className='mr-1'>{`${viewerName}님이 ${reactedEmoji}를 남겼어요`}</div>
      </div>
    </div>
  )
}

const RepostReactorList = observer(({ repostId }) => {
  const token = AuthStore?.token
  const [viewers, setViewers] = useState([])

  const fetchData = async () => {
    if (!token) return
    const result = await backendApis.repostViewerActivity(
      token,
      'GET',
      repostId,
      { activityType: 'react' },
    )
    if (result?.status === 200) {
      setViewers(result.data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [token, repostId])

  const onClickInvite = () => {
    window.location.href = `#shareLink.${JSON.stringify({
      shareType: 'ShortsFriendLink',
      title: '[올웨이즈] 친구야 같이 보자!',
      description: '친구가 좋아하는 영상을 같이 시청해 보세요.',
      imageUrl:
        'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
    })}`
  }

  if (viewers?.length > 0) {
    return (
      <div className='w-full'>
        <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 rounded-t-lg h-14 border-b-gray-50'>
          공감한 사람 {viewers.length}명
        </div>

        <div className='w-full mt-14'>
          {viewers.map((each) => (
            <ViewerActivity key={each._id} viewer={each} />
          ))}
        </div>
      </div>
    )
  }
  if (viewers?.length === 0) {
    return (
      <div className='w-full'>
        <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 rounded-t-lg h-14 border-b-gray-50'>
          반응한 사람 0명
        </div>
        <div className='flex flex-col items-center'>
          <div className='w-full p-4 mt-10 text-center'>
            같이 영상 보고 싶은 친구를 초대해 보세요
          </div>
          <div
            className='w-5/6 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] text-lg rounded-3xl text-white text-center font-bold scale my-4'
            aria-hidden='true'
            onClick={onClickInvite}
          >
            친구 초대하기
          </div>
        </div>
      </div>
    )
  }
})

export default RepostReactorList
