import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import RepostVideoStore from '../../../stores/repostVideoStore'
import { Oval } from 'react-loader-spinner'

const Profile = ({ name, img }) => (
  <div className='flex flex-col justify-center items-center w-[21%] aspect-[7/8]'>
    <div className='flex flex-col justify-center items-center rounded-full w-full aspect-square'>
      <img
        src={img}
        alt='user_profile'
        className='w-[95%] aspect-square rounded-full'
      />
    </div>
    <div className='w-full mt-2 h-5 text-center text-clip overflow-hidden font-semibold text-sm'>
      {name}
    </div>
  </div>
)
const Invite = ({ img, isLoading, onClick }) => (
  <div
    className='flex flex-col justify-center items-center w-[21%] aspect-[7/8]'
    aria-hidden='true'
    onClick={onClick}
  >
    <div
      className={`flex flex-col justify-center items-center rounded-full w-full aspect-square ${
        isLoading && 'pointer-events-none'
      }`}
    >
      {isLoading ? (
        <Oval
          color='#268BED'
          strokeWidth='8'
          secondaryColor='#b9defe'
          height='22'
          width='22'
        />
      ) : (
        <img
          src={img}
          alt='user_profile'
          className='w-[57%] aspect-square rounded-full'
        />
      )}
    </div>
    <div className='w-full mt-2 h-5 text-center text-clip overflow-hidden font-semibold text-sm'>
      팀원 초대
    </div>
  </div>
)

const Teammates = observer(() => {
  const [isLoading, setIsLoading] = React.useState(false)

  const onClickInviteTeam = () => {
    setIsLoading(true)
    window.location.href = `#shareLink.${JSON.stringify({
      shareType: 'InviteTeamLink',
      teamId: RepostVideoStore?.teamInfo?._id,
      title: '[올웨이즈] 팀과 함께 재밌는 영상 같이 보고, 돈도 벌어요!',
      description:
        '재밌는 영상을 보면서 현금도 모아보세요. 다 모으면 바로 출금 가능해요.',
      imageUrl:
        'https://assets.ilevit.com/67371a43-5877-4222-9db2-4e6a3f43708b.png',
    })}`
    setTimeout(() => setIsLoading(false), 1500)
  }

  const onClickcopyLink = async (link) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText('Text which you want to copy')
    } else {
      document.execCommand('copy', true, 'Text which you want to copy')
    }
  }

  return (
    <div className='w-full flex flex-col justify-center items-center pt-2 pb-9 px-8'>
      <div className='w-full flex flex-col items-center'>
        <p className='font-bold text-xl'>
          {RepostVideoStore?.teamInfo?.teamName}
        </p>
        {/* <p>영상을 같이 볼 팀원을 초대해보세요!</p> */}
      </div>
      {/* TODO: 변수화 받아오기  */}
      <div className='w-full flex flex-row flex-start gap-4 mt-4'>
        {RepostVideoStore?.teamInfo?.members.map((member) => (
          <Profile
            name={member.userName}
            img={member.userImageUri}
            key={member.userId}
          />
        ))}
        {RepostVideoStore?.teamInfo?.members.length < 4 && (
          <Invite
            img='/images/plusBlue.png'
            isLoading={isLoading}
            onClick={onClickInviteTeam}
          />
        )}
      </div>
      <div className='flex flex-col w-full mt-5'>
        {/* <div
          className='w-full h-12 flex flex-row items-center bg-[#efefef] rounded-lg px-5 py-2'
          aria-hidden='true'
          onClick={() => {
            console.log('linkCopy')
          }}
        >
          <img className='w-5 h-5' src='images/linkCopy.png' alt='link_copy' />
          <span className='ml-3 font-semibold'>초대 링크 복사</span>
        </div> */}

        {/* TODO: 팀 나가기 */}
        {/* <div
          className='w-full h-12 flex flex-row items-center bg-[#efefef] rounded-lg px-5 py-2 mt-3'
          aria-hidden='true'
          onClick={() => {
            console.log('team Leave')
          }}
        >
          <img
            className='w-5 h-5'
            src='images/teamLeaveRed.png'
            alt='link_copy'
          />
          <span className='ml-3 font-bold text-[#ff8181]'>팀 나가기</span>
        </div> */}
      </div>
      {/* <div className='w-full flex mt-4'>팀원 초대하기</div> */}
    </div>
  )
})

export default Teammates
