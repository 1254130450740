import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import ModalStore from '../../../stores/ModalStore'

const TeamProfile = observer(
  ({ teamId, teamImageUri, teamName, teamLastRepostCreatedAt }) => {
    if (!teamId) return null

    const navigate = useNavigate()
    const [animate, setAnimate] = useState(false)

    const onClick = () => {
      navigate(`/reposts?teamId=${teamId}`)
    }

    // TODO: 최근에 올라왔는가? -> 내가 봤는가? 구분지어서 highlight
    const [isActive, setIsActive] = useState(true)

    useEffect(() => {
      if (ModalStore?.currentFriendRepostFeed?.teamId === teamId) {
        setAnimate(true)
        setTimeout(() => {
          ModalStore.setCurrentFriendRepostFeed({
            open: ModalStore?.currentFriendRepostFeed?.open,
            from: '',
            teamId: '',
          })
        }, 3000)
      } else {
        setAnimate(false)
      }
    }, [ModalStore?.currentFriendRepostFeed?.teamId])

    return (
      <div className='flex-start'>
        <button
          type='button'
          key={teamId}
          className='flex flex-col items-center h-16 mx-0.5 w-16'
          onClick={onClick}
        >
          {animate ? (
            <motion.div
              animate={{ scale: [1, 1.2, 0.7, 1] }}
              transition={{ ease: 'easeInOut', duration: 1, delay: 1 }}
              className={`rounded-full p-[0.15rem] flex flex-col items-center justify-center ${
                isActive
                  ? 'bg-gradient-to-r from-[#ff56d5] via-red-500 to-yellow-500'
                  : 'bg-[#858585]'
              }`}
            >
              <div className='w-11 h-11 rounded-full bg-[#2e2e2e] flex items-center justify-center'>
                <img
                  src={
                    teamImageUri ??
                    '/images/friendFeed/defaultTeamThumbnail.png'
                  }
                  alt='add-user '
                  className='flex items-center justify-center object-cover w-10 h-10 rounded-full'
                />
              </div>
            </motion.div>
          ) : (
            <div
              className={`rounded-full p-[0.15rem] flex flex-col items-center justify-center ${
                isActive
                  ? 'bg-gradient-to-r from-[#ff56d5] via-red-500 to-yellow-500'
                  : 'bg-[#858585]'
              }
          `}
            >
              <div className='w-11 h-11 rounded-full bg-[#2e2e2e] flex items-center justify-center'>
                <img
                  src={
                    teamImageUri ??
                    '/images/friendFeed/defaultTeamThumbnail.png'
                  }
                  alt='add-user '
                  className='flex items-center justify-center object-cover w-10 h-10 rounded-full'
                />
              </div>
            </div>
          )}

          <div className='mt-1 text-xs text-white'>
            {teamName?.length > 5 ? `${teamName?.substr(0, 5)}...` : teamName}
          </div>
        </button>
      </div>
    )
  },
)

export default TeamProfile
