import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Oval } from 'react-loader-spinner'

import VideoStore from '../../stores/VideoStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import check from '../../utils/check'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'

const apiKey = 'AIzaSyASYA0VIsHA5dMlAAlVj_O41QBSctgkeVs'

const ShareButton = observer(({ currentIndex }) => {
  const [isLoading, setIsLoading] = useState(false)

  const userCodepush = UserStore?.userInfo?.codepushVersion
  const codepushAvailable = check.checkCodepush(userCodepush, '2.2.65')
  if (!codepushAvailable) return null

  const videoInfo = VideoStore?.videos?.[currentIndex]

  const onClick = () => {
    setIsLoading(true)
    const token = AuthStore?.token
    const sessionId = AuthStore?.sessionId
    const userId = UserStore?.userInfo?.userId
    const eventName = 'share'
    const eventDesc = {
      url: videoInfo?.url,
      version: process.env.REACT_APP_VERSION,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
    fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoInfo?.url}&key=${apiKey}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const title = data.items[0].snippet.title
        const thumbnailUrl = data.items[0].snippet.thumbnails.maxres.url

        if (!title || !thumbnailUrl) {
          ModalStore.setCurrentAlert({
            open: true,
            text: '공유에 실패했어요. 잠시 후에 다시 시도해 주세요.',
          })
          setTimeout(() => setIsLoading(false), 1000)
          return
        }

        window.location.href = `#shareLink.${JSON.stringify({
          shareType: 'shareVideo',
          videoInfo,
          title: '[올웨이즈] 재밌는 영상 보고, 돈도 벌자!',
          description: title,
          imageUrl: thumbnailUrl,
        })}`

        setTimeout(() => setIsLoading(false), 1000)
      })
      .catch(() => {
        ModalStore.setCurrentAlert({
          open: true,
          text: '공유 기능은 잠시 후에 다시 시도해 주세요.',
        })
      })
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      {isLoading ? (
        <Oval
          color='#EA3F49'
          strokeWidth='8'
          secondaryColor='white'
          height='22'
          width='22'
        />
      ) : (
        <img src='/images/share.png' alt='share' className='w-6 h-6 mb-1' />
      )}
      <div className='text-sm font-bold text-white'>공유</div>
    </div>
  )
})

export default ShareButton
