import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import UserStore from '../../stores/UserStore'

const Onboarding = observer(() => {
  const ongoing = UserStore?.onboarding
  const onboardingToast = UserStore?.onboardingToast

  const [toggle, setToggle] = useState(true)

  useEffect(() => {
    if (!onboardingToast) return
    setTimeout(() => {
      setToggle(false)
    }, 1500)
    setTimeout(() => {
      setToggle(true)
      UserStore.setOnboardingToast(false)
    }, 2000)
  }, [onboardingToast])

  if (!ongoing || !onboardingToast) return null

  return (
    <div
      className={`absolute left-0 z-30 w-full h-18 flex flex-col justify-center px-4 py-2 bg-white rounded-b-lg leading-8 ${
        toggle ? 'move-down' : 'move-up'
      }`}
    >
      <div className='flex flex-row'>
        🎉<span className='mr-1 font-bold text-red-900'>10원</span>획득
      </div>
      <span className='text-sm'>💸 현금으로 출금이 가능해요</span>
    </div>
  )
})

export default Onboarding
