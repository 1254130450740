import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import QuestVideos from '../components/organisms/questVideos'
import AuthStore from '../stores/AuthStore'
import ModalStore from '../stores/ModalStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

const onGiveMoney = async (each, navigate) => {
  const token = AuthStore?.token
  const questsObj = UserStore?.questLog

  const { type, coin } = each

  const result = await backendApis.money(token, 'POST', { type })

  if (result?.status === 200) {
    if (type === 'nightCoin') {
      UserStore?.setQuestLog({
        ...questsObj,
        [type]: {
          ...questsObj?.[type],
          count: (questsObj?.[type]?.count || 0) + 1,
        },
      })
      ModalStore.setCurrentModal({
        open: true,
        type: 'nightCoinProgress',
        coin,
      })
      navigate('/night')
      return
    }

    UserStore?.setMoneyInfo({
      ...UserStore?.moneyInfo,
      coin: (UserStore?.moneyInfo?.coin || 0) + coin,
    })
    ModalStore.setCurrentBottomSheet({ open: false })

    UserStore?.setQuestLog({ ...questsObj, [type]: false })
    ModalStore.setCurrentModal({
      open: true,
      type: 'quest',
      subType: 'dailyTenVideos',
      coin,
    })
  }
}

const onEnd = (type, detail, navigate) => {
  const types = ['dailyTenVideos', 'coinAfterMeal', 'nightCoin']
  if (types.includes(type)) {
    onGiveMoney(detail, navigate)
  }
}

const QuestVideoScreen = observer(() => {
  const data = UserStore?.ongoingQuest
  const navigate = useNavigate()
  if (!data) return null

  const questCount = data?.questCount
  const mainType = data?.mainType
  const type = data?.type
  const detail = data?.detail

  return (
    <div className='w-screen h-screen bg-black'>
      <QuestVideos
        questCount={questCount}
        type={type}
        mainType={mainType}
        onEnd={() => onEnd(type, detail, navigate)}
      />
    </div>
  )
})

export default QuestVideoScreen
