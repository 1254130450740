import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import ModalStore from '../../../stores/ModalStore'
import RepostVideoStore from '../../../stores/repostVideoStore'

const RepostTeammatesReactionButton = observer(({ teamId, url }) => {
  const currentVideoIndex = RepostVideoStore?.currentIndex
  const currentRepostId = RepostVideoStore?.videos[currentVideoIndex]._id

  const onClick = () => {
    ModalStore?.setCurrentBottomSheet({
      open: true,
      type: 'teamReaction',
      repostId: currentRepostId,
    })
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      <img
        src='/images/buttons/teamReactionButton.png'
        alt='teamReactionButton'
        className='mb-2 w-6 h-7'
      />
      <div className='text-sm font-bold text-white'>방명록</div>
    </div>
  )
})

export default RepostTeammatesReactionButton
