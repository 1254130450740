import { observer } from 'mobx-react-lite'
import React from 'react'
import AuthStore from '../../stores/AuthStore'

import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import converter from '../../utils/converter'

const commentButton = observer(({ commentCount, url }) => {
  const commentCountTemp = converter.likeConverter(commentCount || 0)

  const onClick = () => {
    ModalStore.setCurrentBottomSheet({
      open: true,
      type: 'comments',
      url,
      length: commentCount,
    })
    const token = AuthStore?.token
    const sessionId = AuthStore?.sessionId
    const userId = UserStore?.userInfo?.userId
    const eventName = 'comment'
    const eventDesc = {
      url,
      version: process.env.REACT_APP_VERSION,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      <img src='/images/chat.png' alt='comment' className='mb-1 w-7 h-7' />
      <div className='text-sm font-bold text-white'>{commentCountTemp}</div>
    </div>
  )
})

export default commentButton
