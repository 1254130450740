import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import RepostPlayer from '../../molecules/repost/repostPlayer'
import RepostCarousel from '../../molecules/repost/repostCarousel'
import Loading from '../../atoms/loading'
import RepostVideoStore from '../../../stores/repostVideoStore'
import RepostComments from './repostComments'
import RepostActivityViewButton from './repostActivityViewButton'
import RepostViewLogButton from './repostViewLogButton'
import backendApis from '../../../utils/backendApis'
import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'

const VideoContents = ({ elem, index, currentIndex }) => {
  if (currentIndex > index + 1 || currentIndex < index - 1) {
    return <div id={index} aria-hidden='true' className='w-screen h-screen' />
  }

  return <RepostPlayer src={elem} index={index} currentIndex={currentIndex} />
}

const RepostVideos = observer(
  ({ reposterId, viewerId, onFolloweeRepostClose, isLoading }) => {
    const currentIndex = RepostVideoStore?.currentIndex
    const videos = RepostVideoStore?.videos
    const token = AuthStore?.token

    useEffect(() => {
      if (videos?.length && reposterId !== viewerId) {
        backendApis.repostViewLog(token, 'POST', {
          reposterId,
          url: videos?.[currentIndex]?.url,
          repostId: videos?.[currentIndex]?._id,
          userId: UserStore?.userInfo?.userId,
          userName: UserStore?.userInfo?.userName,
          userImageUri: UserStore?.userInfo?.userImageUri,
        })
      }
    }, [videos?.length, currentIndex])

    if (!videos?.length) return null

    return (
      <>
        <RepostCarousel
          onFolloweeRepostClose={onFolloweeRepostClose}
          videoLength={videos?.length}
        >
          {RepostVideoStore?.videos.map((elem, index) => (
            <VideoContents
              key={(elem?.url || elem) + index.toString()}
              elem={elem?.url}
              index={index}
              currentIndex={currentIndex || 0}
            />
          ))}
          <Loading isLoading={isLoading} />
        </RepostCarousel>
        {reposterId === viewerId ? (
          <>
            {/* <RepostActivityViewButton currentVideoIndex={currentIndex || 0} />
            <RepostViewLogButton
              currentVideo={videos?.[currentIndex] || {}}
              currentVideoIndex={currentIndex || 0}
            /> */}
            <RepostComments
              viewerId={viewerId}
              currentVideoIndex={currentIndex || 0}
            />
          </>
        ) : (
          <RepostComments
            viewerId={viewerId}
            currentVideoIndex={currentIndex || 0}
          />
        )}
      </>
    )
  },
)

export default RepostVideos
