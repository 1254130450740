import { observer } from 'mobx-react-lite'
import React from 'react'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import AuthStore from '../../stores/AuthStore'
import check from '../../utils/check'
import backendApis from '../../utils/backendApis'

const DailyInvite = observer(() => {
  const token = AuthStore?.token
  const userCodepush = UserStore?.userInfo?.codepushVersion
  const codepushAvailable = check.checkCodepush(userCodepush, '2.2.65')
  if (!codepushAvailable) return null

  const onClick = () => {
    ModalStore.setCurrentModal({
      open: true,
      type: 'dailyInvite',
      mainType: 'noBackground',
    })
    const sessionId = AuthStore?.sessionId
    const userId = UserStore?.userInfo?.userId
    const eventName = 'dailyInvite'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
  }

  return (
    <>
      {/* <div
        aria-hidden='true'
        className='absolute top-0 z-10 p-1 text-xs font-bold text-white bg-red-500 rounded-t-full rounded-br-full -right-5'
      >
        X100
      </div> */}
      <div
        aria-hidden='true'
        className='flex flex-col items-center'
        onClick={onClick}
      >
        <img
          src='/images/smallCoin.png'
          alt='smallCoin'
          className='w-10 h-10 left-4'
        />
        <span className='bg-gradient-to-b from-[#EB7766] to-[#CF3930] w-12 absolute top-10 text-xs rounded-lg text-white text-center -mt-2'>
          친구초대
        </span>
      </div>
    </>
  )
})

export default DailyInvite
