import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import ModalStore from '../../../stores/ModalStore'
import AuthStore from '../../../stores/AuthStore'
import FriendProfile from './friendProfile'
import MyProfile from './myProfile'
import UserStore from '../../../stores/UserStore'
import FriendManage from './friendManage'
import initialize from '../../../utils/initialize'
import backendApis from '../../../utils/backendApis'
import VideoStore from '../../../stores/VideoStore'

const InviteFollowees = observer(({ followees }) => {
  const [recommendedFollowees, setRecommendedFollowees] = useState(followees)
  if (recommendedFollowees.length === 0) return null

  const token = AuthStore?.token
  const userId = UserStore?.userInfo?.userId
  const sessionId = AuthStore?.sessionId

  const onInviteFolloweeClick = (followee) => {
    const eventName = 'clickInviteFollowee'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
      followeeId: followee.userId,
    }

    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
    backendApis.inviteFollowees(token, 'POST', {
      followees: [followee],
    })
    ModalStore.setCurrentToast({
      open: true,
      text: '친구에게 초대 알림이 갔어요',
      time: 2,
    })
    setRecommendedFollowees(
      recommendedFollowees.filter((elem) => elem.userId !== followee.userId),
    )
  }

  const ignoreFollowee = async (followee) => {
    const result = await backendApis.ignoreFollowee(token, 'POST', {
      followeeId: followee.userId,
    })
    if (result?.status !== 200) {
      ModalStore.setCurrentToast({
        open: true,
        text: '오류가 났어요. 잠시 후 다시 시도해 주세요',
        time: 2,
      })
    } else {
      ModalStore.setCurrentToast({
        open: true,
        text: '이 친구는 다시 추천되지 않아요',
        time: 2,
      })
      setRecommendedFollowees(
        recommendedFollowees.filter((elem) => elem.userId !== followee.userId),
      )
    }
  }

  const onIgnoreFolloweeClick = (followee) => {
    const eventName = 'clickIgnoreFollowee'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
      followeeId: followee.userId,
    }

    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })

    ignoreFollowee(followee)
  }

  const onInviteAllFolloweesClick = () => {
    const eventName = 'clickInviteAllFollowees'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
    }

    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })

    backendApis.inviteFollowees(token, 'POST', { followees })
    ModalStore.setCurrentToast({
      open: true,
      text: '친구들에게 초대 알림이 갔어요',
      time: 2,
    })
    setRecommendedFollowees([])
  }

  return (
    <div className='w-screen h-screen bg-black text-white flex flex-col items-center justify-between font-bold'>
      <div className='text-2xl flex flex-col items-center justify-center pt-36'>
        <div>비디오 탭으로</div>
        <div>올팜 친구 부르기</div>
        <div className='font-medium text-sm py-2'>
          초대를 누르면 친구에게 알림이 가요
        </div>
      </div>
      <div className='friend-list'>
        {recommendedFollowees.map((elem, index) => (
          <div
            key={elem.userId}
            className='friend w-full flex flex-row items-center justify-between my-2'
          >
            <div className='flex flex-row items-center'>
              <div className='w-[4rem] h-[4rem] rounded-full overflow-hidden border-[1px] border-[#ffffff] object-cover '>
                <img
                  src={elem.userImageUri}
                  alt='profile'
                  aria-hidden='true'
                  className='object-cover w-[4rem]'
                />
              </div>
            </div>
            <div className='m-2 text-md px-6'>{elem.userName}</div>
            <button
              type='button'
              className='text-sm font-bold text-[#cdcdcd] bg-[#00000068] border-[#777676] border-[1px] px-4 py-1 rounded-sm mr-2'
              onClick={() => onIgnoreFolloweeClick(elem)}
            >
              무시
            </button>
            <button
              type='button'
              className='text-sm font-bold bg-[#EB4F4768] border-[#EB4F47] border-[1px] px-8 py-1 rounded-sm'
              onClick={() => onInviteFolloweeClick(elem)}
            >
              초대
            </button>
          </div>
        ))}
      </div>
      <div className='buttons flex flex-row mb-10 justify-around'>
        <button
          type='button'
          className='all-add-button text-md bg-[#EB4F47] px-16 py-2 rounded-sm'
          onClick={onInviteAllFolloweesClick}
        >
          모두 초대
        </button>
      </div>
    </div>
  )
})

export default InviteFollowees
