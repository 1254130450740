import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import ModalStore from '../stores/ModalStore'

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const OnboardingButton = ({ currentIndex, onClick }) => (
  <>
    <div key='circle-guide' className='flex flex-row mt-12 '>
      {[0, 1, 2].map((elem, index) => {
        if (index === currentIndex) {
          return (
            <div
              key={elem}
              className='circle bg-[#eb4f47] h-[0.4rem] w-[0.9rem] rounded-full mx-1'
            />
          )
        }
        return (
          <div
            key={elem}
            className='circle bg-[#E6E7EB] h-[0.4rem] w-[0.4rem] rounded-full mx-1'
          />
        )
      })}
    </div>
    <button
      key='next-button'
      type='button'
      className='text-white bg-[#eb4f47] w-[16rem] py-2 mt-6 rounded-[8px] font-bold'
      onClick={onClick}
    >
      {currentIndex === 2 ? '영상 보러가기' : '다음'}
    </button>
  </>
)

const OnboardingDesc = ({ currentIndex }) => {
  if (currentIndex === 0) {
    return (
      <>
        <div className='font-bold text-xl'>
          <span className='text-[#eb4f47]'>같이보기</span> 기능을 사용해봐요!
        </div>
        <div className='text-md mt-6'>
          <span className='text-[#eb4f47] font-bold'>같이보기</span> 버튼을
          누르면
        </div>
        <div className='text-md'>재밌게 본 영상이 친구에게 공유돼요!</div>
      </>
    )
  }
  if (currentIndex === 1) {
    return (
      <>
        <div className='font-bold text-xl'>
          공유한 영상을 <span className='text-[#eb4f47]'>같이봐요!</span>
        </div>
        <div className='text-md mt-6'>내가 공유한 영상은</div>
        <div className='text-md'>친구들이 열어볼 수 있어요</div>
      </>
    )
  }
  return (
    <>
      <div className='font-bold text-xl'>
        공유한 영상에 <span className='text-[#eb4f47]'>공감해요!</span>
      </div>
      <div className='text-md mt-6'>친구들이 공유한 영상에</div>
      <div className='text-md'>다양한 감정을 표현할 수 있어요!</div>
    </>
  )
}

const OnboardingCard = ({ imgSrc, onClick, currentIndex }) => (
  <div className='w-screen h-screen flex flex-col items-center overflow-hidden '>
    <AnimatePresence initial={false}>
      <motion.div
        variants={variants}
        animate='animate'
        initial='initial'
        exit='exit'
        key={currentIndex}
        className='w-full h-full flex flex-col items-center'
      >
        <img
          src={imgSrc}
          alt='test'
          className='w-full h-full object-cover overflow-hidden '
        />

        <div className='absolute bottom-32 flex flex-col items-center'>
          <OnboardingDesc currentIndex={currentIndex} />
        </div>
      </motion.div>
    </AnimatePresence>
    <div className='absolute bottom-10 flex flex-col items-center'>
      <OnboardingButton currentIndex={currentIndex} onClick={onClick} />
    </div>
  </div>
)

const logFullGuideUSerSeen = async (navigate, token) => {
  const result = await backendApis.fullGuide(token, 'POST', {
    guideType: 'repostFullGuide',
  })
  if (result.status === 200) {
    navigate('/')
  } else {
    ModalStore.setCurrentToast('잠시 오류가 났네요. 다시 시도해 주세요.')
  }
}

const OnboardingRepostScreen = observer(() => {
  const [index, setIndex] = useState(0)
  const navigate = useNavigate()
  const token = AuthStore?.token

  const onClick = () => {
    if (index < 2) {
      setIndex(index + 1)
    } else {
      logFullGuideUSerSeen(navigate, token)
    }
  }

  const slides = [
    {
      imgSrc: '/images/onboardingRepostScreen/repostOnboard1.png',
    },
    {
      imgSrc: '/images/onboardingRepostScreen/repostOnboard2.png',
    },
    {
      imgSrc: '/images/onboardingRepostScreen/repostOnboard3.png',
    },
  ]

  return (
    <OnboardingCard
      currentIndex={index}
      imgSrc={slides[index].imgSrc}
      desc={slides[index].desc}
      onClick={onClick}
    />
  )
})

export default OnboardingRepostScreen
