import React from 'react'
import { useNavigate } from 'react-router-dom'
import commaNumber from 'comma-number'

import UserStore from '../stores/UserStore'
import ModalStore from '../stores/ModalStore'
import Header from '../components/molecules/header'
import { observer } from 'mobx-react-lite'
import MoneyGauge from '../components/molecules/moneyGauge'

const DATA = {
  first: [
    '김',
    '이',
    '박',
    '최',
    '강',
    '한',
    '장',
    '양',
    '전',
    '윤',
    '방',
    '유',
    '나',
    '문',
    '안',
    '홍',
    '서',
    '정',
    '하',
    '도',
  ],
  last: [
    '윤',
    '성',
    '현',
    '환',
    '직',
    '우',
    '식',
    '남',
    '총',
    '추',
    '창',
    '동',
    '달',
    '미',
    '현',
    '준',
    '헌',
    '지',
    '구',
    '각',
    '우',
    '웅',
    '반',
    '혁',
    '희',
    '지',
    '종',
    '중',
    '제',
  ],
  colors: [
    '#FFEBEB',
    '#FFF5EB',
    '#FFFBEB',
    '#F9FFEB',
    '#EBF4FF',
    '#EBFFEB',
    '#EBFFFE',
    '#EBF3FF',
    '#F1EBFF',
    '#FFEBFC',
    '#FFEBF5',
  ],
  imojis: ['🥳', '😚', '😁', '😉', '😆', '😎', '🤩', '🤭', '🤗', '💸', '💰'],
}

const ScrollContent = () => {
  const { first, last, colors, imojis } = DATA
  const name = `${first[Math.floor(Math.random() * first.length)]}*${
    last[Math.floor(Math.random() * last.length)]
  }`
  const color = colors[Math.floor(Math.random() * colors.length)]
  const imoji = imojis[Math.floor(Math.random() * imojis.length)]
  const times = Math.floor(Math.random() * 20) + 10

  return (
    <div
      className='p-2.5 mx-2 rounded-lg shadow-md slide'
      style={{ backgroundColor: color }}
    >
      <span className='absolute text-lg -top-3 -left-2'>{imoji}</span>
      {name} 님이 {times}시간 만에 3,000원을 출금했어요
    </div>
  )
}

const HistoryScrolling = () => {
  const array = Array.from({ length: 50 })
  return (
    <div className='flex flex-row mt-20' style={{ width: '9800px' }}>
      {array.map(() => (
        <ScrollContent />
      ))}
    </div>
  )
}

const MoneyContents = observer(() => {
  const money = UserStore?.moneyInfo?.money || 0

  return (
    <div className='flex flex-col items-center'>
      <div className='h-16' />
      <div className='text-lg'>내 현금</div>
      <div className='h-3' />
      <div className='text-4xl font-blod'>
        {commaNumber(money)}
        <span className='text-3xl'> 원</span>
      </div>
      <div className='h-4' />
      <MoneyGauge percentage={Math.min(money, 3000) / 3000} />
      <div className='h-4' />
      <div className='text-sm font-light'>
        20일 동안 비디오를 보지 않으면, 전부 사라져요.
      </div>
      <div className='h-6 w-4/5 border-b-2 border-b-[#E1E1E1]' />
    </div>
  )
})

const MoneyButton = observer(() => {
  const money = UserStore?.moneyInfo?.money || 0
  const navigate = useNavigate()

  const onClick = () => {
    if (money < 3000) {
      ModalStore.setCurrentModal({ open: true, type: 'lackOfMoney' })
    } else {
      navigate('/withdraw')
    }
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center justify-center'
      onClick={onClick}
    >
      <div className='h-8' />
      <div className='w-5/6 flex flex-col items-center bg-gradient-to-b from-[#EC7058] to-[#C33827] text-white py-3 text-xl font-bold rounded-md'>
        출금하기
      </div>
    </div>
  )
})

const MoneyScreen = () => {
  const navigate = useNavigate()

  const callback = {
    goback: () => {
      navigate('/')
    },
    info: () => {
      ModalStore.setCurrentModal({ open: true, type: 'notice' })
    },
  }

  return (
    <div className='w-screen h-screen overflow-x-hidden bg-white'>
      <div className='h-0' />
      <Header callback={callback} goback info='유의사항' />
      <HistoryScrolling />
      <MoneyContents />
      <MoneyButton />
    </div>
  )
}

export default MoneyScreen
