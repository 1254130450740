import { observer } from 'mobx-react-lite'
import AuthStore from '../../../stores/AuthStore'
import React, { useEffect, useRef, useState } from 'react'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../stores/ModalStore'
import { useNavigate } from 'react-router-dom'
import VideoStore from '../../../stores/VideoStore'
import { Oval } from 'react-loader-spinner'

const Header = ({ title, subtitle }) => (
  <div id='header' className='flex flex-col items-center mt-5'>
    {title && (
      <p id='title' className='text-lg font-bold'>
        {title}
      </p>
    )}
    {subtitle && (
      <p id='subtitle' className='text-sm text-[#939AA5] mt-2'>
        {subtitle}
      </p>
    )}
  </div>
)

const CreateTeam = observer(({ repostId }) => {
  const token = AuthStore?.token
  const sessionId = AuthStore?.sessionId

  const [isLoading, setIsLoading] = useState(false)
  const [teamName, setTeamName] = useState('')
  const isClicked = useRef(false)

  const navigate = useNavigate()
  const fetchData = async (params) => {
    const result = await backendApis.repostTeamCreate(token, 'POST', params)
    if (result?.status === 200 && result?.data) {
      setTimeout(() => {
        ModalStore.setCurrentBottomSheet({ open: false })
        navigate(`/reposts?teamId=${result.data.teamId}`)
      }, 500)
      /* repost로 보내야 됨 */
    }
  }

  const onClick = async () => {
    if (isClicked.current) return
    isClicked.current = true

    const videoUrl = VideoStore?.videos?.[VideoStore?.currentIndex]?.url
    const videoId = VideoStore?.videos?.[VideoStore?.currentIndex]?._id

    setIsLoading(true)
    /* backend API 호출 */
    fetchData({ teamName, videoUrl, videoId })
  }

  return (
    <div id='container' className='flex flex-col items-center w-full'>
      {Header({
        title: '같이보기 팀 만들기',
        subtitle: '영상을 같이 볼 팀을 만들어보세요!',
      })}
      <div className='mt-10 w-4/5 max-w-xs'>
        <input
          type='text'
          id='teamName'
          className='bg-main-color-light border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none'
          placeholder='팀 이름을 입력해주세요'
          required
          value={teamName}
          onChange={(e) => {
            const TEAM_NAME_MAX_LENGTH = 8
            if (e.target.value.length > TEAM_NAME_MAX_LENGTH) return
            setTeamName(e.target.value)
          }}
        />
      </div>

      <button
        type='button'
        id='button'
        className={`mt-10 mb-10 h-10 w-2/3 max-w-xs py-2 bg-main-color rounded-lg text-white text-center flex flex-col items-center font-bold 
      ${teamName && !isLoading ? '' : 'opacity-50 pointer-events-none'}`}
        onClick={onClick}
      >
        {isLoading ? (
          <Oval
            color='#F2F2F2'
            strokeWidth='8'
            secondaryColor='#F4F6FD'
            height='20'
            width='20'
          />
        ) : (
          '확인'
        )}
      </button>
    </div>
  )
})

export default CreateTeam
