import React from 'react'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import { Oval } from 'react-loader-spinner'

import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'
import useContacts from '../../../hooks/useContacts'
import backendApis from '../../../utils/backendApis'

const sendMessage = ({ receiverPhoneNumber }) => {
  window.location.href = `#sendMessage.${JSON.stringify({
    shareType: 'ShortsContact',
    title: '[올웨이즈] 친구야 같이 보자!',
    description:
      '재밌는 영상을 보면서 현금을 모아보세요. 다 모으면 바로 출금 가능해요.',
    imageUrl:
      'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
    phoneNumber: receiverPhoneNumber,
    text: `${UserStore?.userInfo?.userName}님이 올웨이즈 비디오에 초대했어요!\n올웨이즈 비디오는 재밌는 영상을 보고, 현금을 출금하는 서비스예요.\n골드를 다 모으면 현금을 받을 수 있어요.\n친구가 좋아요 한 영상도 같이 볼 수 있답니다.\n${UserStore?.userInfo?.userName}님과 같이 비디오 시청하고, 현금을 출금해 보세요!\n\n지금 비디오 보고 현금 받기`,
  })}`
}

const checkReward = async (docId, data, setData) => {
  const result = await backendApis.reward(AuthStore?.token, 'GET', {
    type: 'contact',
    docId,
  })
  if (result?.status === 200) {
    setData(
      data.map((each) => {
        if (each?._id?.toString() === docId?.toString()) {
          return {
            ...each,
            reward: result?.data,
          }
        }
        return each
      }),
    )
  }
}

const Button = ({ contact, data, setData }) => {
  const { _id, reward = null, isReceived = null } = contact

  if (isReceived) {
    return (
      <div className='px-2 py-1 font-semibold text-black bg-gray-300 rounded-lg'>
        초대 완료
      </div>
    )
  }

  if (reward) {
    return (
      <div
        aria-hidden='true'
        onClick={() => sendMessage(contact)}
        className='bg-[#FBEBD7] text-[#A45B54] px-2 py-1 rounded-lg font-semibold flex flex-row items-center'
      >
        <img
          src='/images/smallCoin.png'
          alt='smallCoin'
          className='w-6 h-6 mr-1'
        />
        {commaNumber(reward)} 받기
      </div>
    )
  }

  return (
    <div
      aria-hidden='true'
      onClick={() => checkReward(_id, data, setData)}
      className='bg-[#EA3F49] text-white px-2 py-1 rounded-lg font-semibold'
    >
      보상 확인
    </div>
  )
}

const ContactContent = ({ contact, data, setData }) => {
  const { _id, receiverName, receiverPhoneNumber } = contact
  return (
    <div className='flex flex-row items-center justify-between px-1 py-2 border-b-2 border-gray-100'>
      <div>
        <div>
          {receiverName?.length > 10
            ? `${receiverName?.slice(0, 10)}...`
            : receiverName}
        </div>
        <div className='text-gray-400'>{receiverPhoneNumber}</div>
      </div>
      <Button contact={contact} data={data} setData={setData} />
    </div>
  )
}

const Contacts = observer(() => {
  const token = AuthStore?.token
  const { data, setData, isLoading } = useContacts({ token })

  if (isLoading) {
    return (
      <div className='flex items-center justify-center w-11/12 p-3 overflow-y-scroll bg-white rounded-lg h-96'>
        <Oval
          color='#EA3F49'
          strokeWidth='6'
          secondaryColor='pink'
          height='30'
          width='30'
        />
      </div>
    )
  }

  return (
    <div className='w-11/12 p-3 overflow-y-scroll bg-white rounded-lg h-96'>
      {data.map((contact) => (
        <ContactContent contact={contact} data={data} setData={setData} />
      ))}
    </div>
  )
})

export default Contacts
