import { makeObservable, observable, action } from 'mobx'

class RepostVideoStore {
  videos = []
  currentIndex = 0
  status = null
  duration = null
  isFocused = false
  teamInfo = {}

  constructor() {
    makeObservable(this, {
      videos: observable,
      currentIndex: observable,
      status: observable,
      duration: observable,
      isFocused: observable,
      teamInfo: observable,

      setVideos: action,
      setCurrentIndex: action,
      setStatus: action,
      setDuration: action,
      setIsFocused: action,
      setTeamInfo: action,
    })
  }

  setVideos(videos) {
    this.videos = videos
  }

  setCurrentIndex(currentIndex) {
    this.currentIndex = currentIndex
  }

  setStatus(status) {
    this.status = status
  }

  setDuration(duration) {
    this.duration = duration
  }

  setIsFocused(isFocused) {
    this.isFocused = isFocused
  }

  setTeamInfo(teamInfo) {
    this.teamInfo = teamInfo
  }
}

export default new RepostVideoStore()
