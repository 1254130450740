import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import UserStore from '../../stores/UserStore'

const Contents = observer(({ from }) => {
  if (from === 'moneyTimer') {
    const money = UserStore?.moneyInfo?.money || 0
    return (
      <>
        <div className='absolute z-10 h-12 bg-black rounded-full w-30 right-3 opacity-40 top-1' />
        <div className='absolute z-20 w-20 p-2 text-sm font-bold leading-4 text-white top-1 right-10'>
          현재 현금 <br />
          <span className='font-bold text-yellow-300'>{money}</span> 원
        </div>
      </>
    )
  }

  if (from === 'tomorrowCoin') {
    return (
      <>
        <div className='absolute z-10 h-12 bg-black rounded-full w-30 right-3 opacity-40 top-1' />
        <div className='absolute z-20 w-20 p-2 text-sm font-bold leading-4 text-white top-1 right-10'>
          내일 오전 <br />
          <span className='font-bold text-yellow-300'>7시</span> 이후
        </div>
      </>
    )
  }

  return null
})

const MoneyInfo = observer(({ from, dependency }) => {
  const [show, setShow] = useState({ open: true, type: 'tomorrowCoin' })

  const money = UserStore?.moneyInfo?.money || 0

  useEffect(() => {
    const random = Math.random()
    if (money > 1500 && random > 0.95) {
      setShow({ open: true, type: 'moneyTimer' })
    } else if (money <= 1200 && random > 0.75) {
      setShow({ open: true, type: 'tomorrowCoin' })
    }
  }, [dependency])

  useEffect(() => {
    setTimeout(() => setShow({ open: false }), 3000)
  }, [show?.open, show?.type])

  if (!show?.open || show?.type !== from) return null

  return <Contents from={from} />
})

export default MoneyInfo
