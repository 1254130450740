import { observer } from 'mobx-react-lite'
import React from 'react'
import AuthStore from '../../../stores/AuthStore'
import ModalStore from '../../../stores/ModalStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'

const onClickDelete = (token, followeeId) => {
  backendApis.followee(token, 'DELETE', { followeeId })
  UserStore.setFollowList(
    UserStore?.followList?.filter(
      (elem) => elem?.followeeId?.toString() !== followeeId?.toString(),
    ),
  )
}

const onClickInvite = () => {
  window.location.href = `#shareLink.${JSON.stringify({
    shareType: 'ShortsFriendLink',
    title: '[올웨이즈] 친구야 같이 보자!',
    description: '친구가 좋아하는 영상을 같이 시청해 보세요.',
    imageUrl:
      'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
  })}`
}

const FriendManageContents = observer(() => {
  const token = AuthStore?.token
  const followList = UserStore?.followList

  if (!followList?.length) {
    return (
      <>
        <div className='w-11/12 p-3 text-center bg-white rounded-lg h-80'>
          <div className='fixed'>친구 목록 | {followList?.length}</div>
          <div className='flex items-center justify-center h-full p-3 text-center'>
            친구를 초대해,
            <br />
            친구와 함께 영상을 시청해 보세요.
          </div>
        </div>
        <div
          className='w-5/6 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] text-lg rounded-3xl text-white text-center font-bold scale mt-4'
          aria-hidden='true'
          onClick={onClickInvite}
        >
          친구 초대하기
        </div>
      </>
    )
  }

  return (
    <>
      <div className='w-11/12 p-3 overflow-y-scroll bg-white rounded-lg h-80'>
        <div className='fixed'>친구 목록 | {followList?.length}</div>
        <div className='h-6' />
        {followList.map((each, index) => (
          <div
            key={each.followeeId + index.toString()}
            className='flex flex-row items-center justify-between py-3 border-b-2 border-b-gray-100'
          >
            <div className='flex flex-row items-center'>
              <img
                src={each?.followeeImageUri}
                alt='followeeImageUri'
                className='w-8 h-8 mr-2 rounded-full'
              />
              {each?.followeeName?.length > 5
                ? `${each?.followeeName?.slice(0, 5)}...`
                : each?.followeeName}
            </div>
            <div
              aria-hidden='true'
              className='bg-[#CE4031] px-3 text-sm py-1 rounded-full text-white'
              onClick={() => onClickDelete(token, each?.followeeId)}
            >
              삭제
            </div>
          </div>
        ))}
      </div>
      <div
        className='w-5/6 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] text-lg rounded-3xl text-white text-center font-bold scale mt-4'
        aria-hidden='true'
        onClick={onClickInvite}
      >
        친구 초대하기
      </div>
    </>
  )
})

export default FriendManageContents
