import React from 'react'

const BackgroundNumber = ({ num, textColor, backgroundColor, textSize }) => {
  if (!num && typeof num !== 'number') return

  const nums = num.toString().split('')
  const textStyle = `text-[#E25A3C] text-${textSize}`
  const backgroundStyle = `bg-[#FDF4EA]`

  return (
    <div className='flex flex-row'>
      {nums.map((each) => (
        <div
          className={`${textStyle} ${backgroundStyle} font-bold rounded-lg p-2 m-0.5`}
        >
          {each}
        </div>
      ))}
    </div>
  )
}

export default BackgroundNumber
