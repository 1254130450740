import React from 'react'

import AuthStore from '../../stores/AuthStore'
import converter from '../../utils/converter'
import useComment from '../../hooks/useComment'
import Loading from '../atoms/loading'
import { observer } from 'mobx-react-lite'

const Comment = ({ comment }) => {
  if (!comment) return null

  const {
    authorDisplayName,
    authorProfileImageUrl,
    textDisplay,
    textOriginal,
    publishedAt,
    likeCount,
  } = comment

  if (!authorProfileImageUrl || !textDisplay) return null
  if (textOriginal.includes('http')) return null

  const date = converter.dateConverter(publishedAt)
  const likeNumber = converter.likeConverter(likeCount)

  return (
    <div className='flex flex-row w-full px-3 mb-5'>
      {/* 왼쪽 이미지 */}
      <div className='w-10 mt-1'>
        <img
          src={authorProfileImageUrl}
          alt='user_profile'
          className='w-6 h-6 rounded-full'
          onError={(event) => {
            event.target.src = '/images/videoPlayer/defaultProfile.jpeg'
            event.onerror = null
          }}
        />
      </div>
      {/* 오른쪽 */}
      <div className='flex flex-col w-full'>
        {/* 이름 + 날짜 */}
        <div className='flex flex-row items-center text-xs text-gray-700'>
          <div className='mr-1'>{authorDisplayName} •</div>
          <div className='text-xs'>{date}</div>
        </div>
        {/* 텍스트 */}
        <div className='text-sm leading-5 whitespace-normal'>{textDisplay}</div>
        {/* 좋아요 */}
        <div className='flex flex-row items-center mt-2 text-xs'>
          {likeCount > 0 && (
            <>
              <img
                src='/images/likeBorder.png'
                alt='like_border'
                className='w-3 h-3 mr-1'
              />
              <div>{likeNumber}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const Comments = observer(({ url, length }) => {
  const token = AuthStore?.token
  const { isLoading, data } = useComment({ token, url, length })

  if (!length) {
    return (
      <div className='w-full'>
        <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 h-14 border-b-gray-50'>
          댓글 <span className='text-sm text-gray-600'>0</span>
        </div>
        <div className='w-full mb-24 text-center text-gray-600 mt-36'>
          아직 댓글이 없어요.
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='w-full h-full'>
        <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 h-14 border-b-gray-50'>
          댓글 <span className='text-sm text-gray-600'>{length}</span>
        </div>
        <div className='w-full h-full mb-48 mt-60'>
          <Loading isLoading={isLoading} full={false} />
        </div>
      </div>
    )
  }

  return (
    <div className='w-full'>
      <div className='absolute top-0 left-0 w-full p-4 text-lg font-bold bg-white border-b-2 h-14 border-b-gray-50'>
        댓글 <span className='text-sm text-gray-600'>{length}</span>
      </div>
      <div className='w-full mt-14'>
        {data.map((each) => (
          <Comment comment={each} />
        ))}
      </div>
    </div>
  )
})

export default Comments
