import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { observer } from 'mobx-react-lite'

import BottomSheetContents from '../organisms/bottomSheetContents'
import ModalStore from '../../stores/ModalStore'

const BottomSheets = observer(() => {
  const isOpen = ModalStore?.currentBottomSheet?.open

  const onDismiss = () => {
    setTimeout(() => ModalStore.setCurrentBottomSheet({ open: false }), 50)
  }

  return (
    <BottomSheet
      className='absolute bottom-0 z-10 w-full bg-white border-none outline-none'
      onDismiss={onDismiss}
      // expandOnContentDrag
      scrollLocking={false}
      blocking
      open={isOpen}
    >
      <div className='w-full overflow-x-hidden overflow-y-scroll max-h-100'>
        <BottomSheetContents />
      </div>
    </BottomSheet>
  )
})

export default BottomSheets
