import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import useChannel from '../hooks/useChannel'
import VideoStore from '../stores/VideoStore'
import ModalStore from '../stores/ModalStore'
import Loading from '../components/atoms/loading'
import Text from '../components/atoms/text'

const Introduction = ({ currentVideo }) => {
  if (!currentVideo) return null
  const { author, thumbnailUrl } = currentVideo
  return (
    <div className='flex flex-row items-center w-full px-4'>
      <img
        src={thumbnailUrl}
        alt='thumbnailUrl'
        className='w-16 h-16 mr-3 rounded-full'
        crossOrigin='anonymous'
        onError={(event) => {
          event.target.src = '/images/videoPlayer/defaultProfile.jpeg'
          event.onerror = null
        }}
      />
      <Text text={author} color='white' bold size='xl' />
    </div>
  )
}

const Video = ({ video, onClick }) => {
  if (!video) return <div className='w-full' />
  const { videoId, thumbnailUrl } = video
  return (
    <div
      aria-hidden='true'
      className='w-full h-56 m-0.5'
      onClick={() => onClick(videoId)}
    >
      <img
        src={thumbnailUrl}
        alt='thumbnailUrl'
        className='object-cover h-full'
      />
    </div>
  )
}

const Playlists = ({ data, onClick }) => {
  if (!data?.length) {
    return (
      <div className='w-full mt-10 text-center text-white'>
        해당 크리에이터의 영상이 없어요
      </div>
    )
  }
  const length = Math.ceil(data.length / 3)
  const array = Array.from({ length })
  return (
    <div className='my-2'>
      <div className='px-4 mb-2 text-sm text-white'>이 채널의 비디오</div>
      {array.map((each, index) => {
        const video1 = data?.[index * 3]
        const video2 = data?.[index * 3 + 1]
        const video3 = data?.[index * 3 + 2]

        return (
          <div key={each} className='flex flex-row w-full'>
            <Video video={video1} onClick={onClick} />
            <Video video={video2} onClick={onClick} />
            <Video video={video3} onClick={onClick} />
          </div>
        )
      })}
    </div>
  )
}

const ChannelScreen = observer(() => {
  const currentVideo = VideoStore?.videos?.[VideoStore?.currentIndex]
  const channelId = currentVideo?.channelId

  const { data, isLoading } = useChannel({ channelId })
  const navigate = useNavigate()

  const onClick = (each) => {
    navigate('/channel-videos')
    VideoStore.setChannelVideos([
      each,
      ...data.map((elem) => elem?.videoId).filter((elem) => elem !== each),
    ])
    VideoStore.setChannelIndex(0)
  }

  if (!channelId) {
    ModalStore.setCurrentAlert({
      open: true,
      text: '해당 크리에이터의 영상이 없어요.',
      onClick: () => {
        navigate('/')
        ModalStore.setCurrentAlert({ open: false })
      },
    })
  }

  if (isLoading) {
    return (
      <div className='w-screen h-screen bg-black'>
        <Loading isLoading={isLoading} />
      </div>
    )
  }

  return (
    <div className='w-screen min-h-screen overflow-y-scroll bg-black'>
      <img
        aria-hidden='true'
        src='/images/thickArrow.png'
        alt='thickArrow'
        className='absolute z-50 w-6 h-6 top-4 left-4'
        onClick={() => navigate('/')}
      />
      <div className='h-16' />
      <Introduction currentVideo={currentVideo} />
      <div className='h-4' />
      <Playlists data={data} onClick={onClick} />
    </div>
  )
})

export default ChannelScreen
