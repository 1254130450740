import { observer } from 'mobx-react-lite'
import AuthStore from '../../../stores/AuthStore'
import React, { useEffect, useRef, useState } from 'react'
import backendApis from '../../../utils/backendApis'
import ModalStore from '../../../stores/ModalStore'
import { useNavigate } from 'react-router-dom'
import VideoStore from '../../../stores/VideoStore'
import { Oval } from 'react-loader-spinner'
import UserStore from '../../../stores/UserStore'

const Header = ({ title, subtitle }) => (
  <div id='header' className='flex flex-col items-center mt-5'>
    {title && (
      <p id='title' className='text-lg font-bold'>
        {title}
      </p>
    )}
    {subtitle && (
      <p id='subtitle' className='text-sm text-[#939AA5] mt-0.5'>
        {subtitle}
      </p>
    )}
  </div>
)

const Profile = ({ name, img, memberCount }) => {
  let fontSize = '12px' // 원하는 기본 크기로 변경
  switch (memberCount) {
    case 1:
      fontSize = '13px' // 원하는 크기로 변경
      break
    case 2:
      fontSize = '12px' // 원하는 크기로 변경
      break
    case 3:
      fontSize = '11px' // 원하는 크기로 변경
      break
    case 4:
      fontSize = '10px' // 원하는 크기로 변경
      break
    default:
      fontSize = '12px' // 원하는 기본 크기로 변경
      break
  }
  return (
    <div className='flex flex-row justify-center items-center w-[40%]'>
      <div className='flex justify-center items-center rounded-full w-[30%]'>
        <img
          src={img}
          alt='user_profile'
          className='w-full aspect-square rounded-full'
        />
      </div>
      <div
        className={`w-full flex items-center ml-1.5 h-8 text-center text-clip overflow-hidden font-semibold text-[${fontSize}]`}
        style={{ fontSize }}
      >
        {name}
      </div>
    </div>
  )
}

const RepostTeam = observer(({ teamList }) => {
  const token = AuthStore?.token
  const sessionId = AuthStore?.sessionId

  const [isLoading, setIsLoading] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState([])
  const isClicked = useRef(false)

  const navigate = useNavigate()
  const fetchData = async (params) => {
    const result = await backendApis.repostTeamVideo(token, 'POST', params)
    if (result?.status === 200 && result?.data) {
      const eventDesc = {
        videoId: params.videoId,
        url: params.videoUrl,
        version: process.env.REACT_APP_VERSION,
        status: result?.status,
        dataStatus: result?.data?.status,
      }
      backendApis.eventLog(token, 'POST', {
        userId: UserStore?.userInfo?.userId,
        sessionId,
        eventName: 'repostVideo',
        eventDesc,
      })

      setTimeout(() => {
        ModalStore.setCurrentBottomSheet({ open: false })
        // friendFeedThumbnail 깜빡이 효과
        ModalStore.setCurrentToast({
          open: true,
          text: '같이보기 영상을 추가했어요!',
          time: 3,
        })
        // 공유된 피드를 자동으로 열어준다
        ModalStore.setCurrentFriendRepostFeed({
          open: true,
          from: 'repostButton',
          teamId: selectedTeam[0],
        })
      }, 500)
      /* repost로 보내야 됨 */
    }

    if (result?.status !== 200) {
      ModalStore.setCurrentBottomSheet({ open: false })
      if (result?.status === 400 && result?.data?.status === 4000) {
        ModalStore.setCurrentAlert({
          open: true,
          text: '이미 추가된 영상입니다.',
        })
      } else {
        ModalStore.setCurrentAlert({
          open: true,
          text: '잠시 후에 다시 시도해 주세요.',
        })
      }
    }
  }

  const onClickShare = async () => {
    if (isClicked.current) return
    isClicked.current = true

    const videoUrl = VideoStore?.videos?.[VideoStore?.currentIndex]?.url
    const videoId = VideoStore?.videos?.[VideoStore?.currentIndex]?._id

    setIsLoading(true)
    /* backend API 호출 */
    fetchData({ videoUrl, videoId, teamId: selectedTeam })
  }

  return (
    <div id='container' className='relative flex flex-col items-center w-full'>
      {Header({
        title: '어떤 팀과 같이 볼까요?',
        subtitle: '영상을 같이 볼 팀을 선택해주세요!',
      })}
      <div className='mt-5 w-4/5 max-w-xs'>
        {teamList.map((team) => (
          <div
            className='flex flex-row mb-2'
            aria-hidden='true'
            onClick={() => {
              // TODO: multiple check
              if (selectedTeam.includes(team._id)) {
                setSelectedTeam([])
                return
              }
              setSelectedTeam([team._id])
            }}
            key={team._id}
          >
            <div className='flex items-center cursor-pointer'>
              <input
                type='radio'
                className='hidden'
                name='example'
                value='example1'
              />
              <span className='relative w-5 h-5 inline-block mr-2 rounded-full border border-gray-500'>
                <span
                  className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-3 h-3 rounded-full bg-blue-500 ${
                    selectedTeam.includes(team._id) ? 'block' : 'hidden'
                  }`}
                />
              </span>
            </div>
            <div className='flex flex-col w-full bg-main-color-light ml-2 px-4 py-1 rounded-lg'>
              <div className='w-full font-bold py-2 border-b-2'>
                {team.teamName}
              </div>
              <div className='flex flex-row w-full flex-start gap-2 pt-2 pb-2'>
                {team.members?.map((member) => (
                  <Profile
                    name={member.userName}
                    img={member.userImageUri}
                    memberCount={team.members.length}
                    key={member.userId}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedTeam?.length ? (
        <button
          type='button'
          id='button'
          className={`mt-4 mb-14 h-10 w-2/3 max-w-xs py-2 bg-main-color rounded-lg text-white text-center flex flex-col items-center justify-center font-bold 
      ${
        selectedTeam?.length && !isLoading
          ? ''
          : 'opacity-50 pointer-events-none'
      }`}
          onClick={onClickShare}
        >
          {isLoading ? (
            <Oval
              color='#F2F2F2'
              strokeWidth='8'
              secondaryColor='#F4F6FD'
              height='20'
              width='20'
            />
          ) : (
            '공유하기'
          )}
        </button>
      ) : (
        teamList?.length < 3 && (
          <div
            className='flex flex-col items-center justify-center mt-5 mb-10 p-5 w-20 h-20 bg-main-color-light border border-main-color rounded-lg'
            aria-hidden='true'
            onClick={() => {
              ModalStore.setCurrentBottomSheet({
                open: true,
                type: 'createTeam',
              })
            }}
          >
            <img src='images/plusWhiteBlue.png' alt='plusWhiteBlue' />
            <div className='text-main-color text-[12px] font-semibold mt-1'>
              팀추가
            </div>
          </div>
        )
      )}
      {/* 팀 3개면, 아직 팀은 3개만 만들 수 있어요! */}
    </div>
  )
})

export default RepostTeam
