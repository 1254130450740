import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import ModalStore from '../../../stores/ModalStore'
import AuthStore from '../../../stores/AuthStore'
import FriendProfile from './friendProfile'
import MyProfile from './myProfile'
import UserStore from '../../../stores/UserStore'
import FriendManage from './friendManage'
import initialize from '../../../utils/initialize'
import backendApis from '../../../utils/backendApis'
import TeamProfile from './teamProfile'

const FoldButton = observer(() => {
  const token = AuthStore?.token
  const userId = UserStore?.userInfo?.userId
  const sessionId = AuthStore?.sessionId
  const isOpen = ModalStore?.currentFriendRepostFeed?.open
  const onClick = () => {
    ModalStore.setCurrentFriendRepostFeed({ open: !isOpen })
    const eventName = 'clickFoldButton'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
      statusAfterClick: !isOpen,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
  }

  return (
    <img
      src='/images/upArrow.png'
      alt='upArrow'
      className={`arrow ${!isOpen ? 'active' : ''} w-4 h-4 mx-3`}
      aria-hidden='true'
      onClick={onClick}
    />
  )
})

const FriendFeed = observer(() => {
  const token = AuthStore?.token
  const isOpen = ModalStore?.currentFriendRepostFeed?.open
  const [myLatestRepostCreatedAt, setMyLatestRepostCreatedAt] = useState(null)

  const fetchData = async () => {
    if (!token) return
    const result = await backendApis.latestRepost(token)
    if (result?.status === 200 && result?.data?.length) {
      setMyLatestRepostCreatedAt(result.data[0].createdAt)
    }
  }

  useEffect(() => {
    if (ModalStore?.currentFriendRepostFeed?.open) {
      initialize.followList(token)
      initialize.teamList(token)
      fetchData()
    }

    if (ModalStore?.currentFriendRepostFeed?.from === 'repostButton') {
      // TODO: Animation 추가 - 공유 후 내 영상을 클릭하도록 유도하는
      setMyLatestRepostCreatedAt(new Date())
    }
  }, [
    token,
    ModalStore?.currentFriendRepostFeed?.open,
    ModalStore?.currentFriendRepostFeed?.from,
  ])
  // friend
  return (
    <div
      className={`absolute top-0 z-10 flex flex-row items-center w-screen p-2 bg-[#2e2e2e] shadow-3xl rounded-b-xl opacity-90 friend ${
        !isOpen ? 'active' : ''
      }`}
    >
      <FoldButton isFold={isOpen} />
      {!isOpen ? (
        <div className='text-[#ffffff] text-sm'>친구의 영상 같이 보기 </div>
      ) : (
        <>
          <FriendManage />
          <div className='flex flex-row w-full h-full overflow-x-scroll overflow-y-hidden flex-start'>
            <MyProfile
              userId={UserStore?.userInfo?.userId}
              userImageUri={UserStore?.userInfo?.userImageUri}
              userName='내 영상'
              userLastRepostCreatedAt={myLatestRepostCreatedAt}
            />
            {!!UserStore?.teamList?.length &&
              UserStore.teamList.map((team, index) => (
                <TeamProfile
                  key={team._id + index.toString()}
                  teamId={team?._id}
                  teamImageUri={team?.teamImageUri}
                  teamName={team?.teamName}
                  teamLastRepostCreatedAt={team?.lastRepostCreatedAt}
                />
              ))}
            {!!UserStore?.followList?.length &&
              UserStore.followList.map((friend, index) => (
                <FriendProfile
                  key={friend.followeeId + index.toString()}
                  userId={friend?.followeeId}
                  userImageUri={friend?.followeeImageUri}
                  userName={friend?.followeeName}
                  userLastRepostCreatedAt={friend?.lastRepostCreatedAt}
                />
              ))}
          </div>
        </>
      )}
    </div>
  )
})

export default FriendFeed
