import React, { useEffect, useState } from 'react'
import ScalingCard from '../atoms/scalingCard'

const GoldCards = ({ next }) => {
  const [animationStart, setAnimationStart] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimationStart(true)
    }, 500)
  })

  return (
    <div className='flex flex-col items-center justify-center w-full h-full delay-1000'>
      <div
        className={'transition-all duration-500 absolute text-center text-white top-36 '.concat(
          animationStart ? 'translate-x-0' : '-translate-x-80',
        )}
      >
        <div className='text-xl font-bold'>반가워요</div>
        <div className='font-bold text-xl'>환영 선물 하나 선택해 볼래요?</div>
      </div>
      <div className='flex flex-row items-center justify-center w-full h-10/12 row-of-cards delay-1000'>
        <ScalingCard
          text='선택'
          onClick={() => {
            next()
            window.location.href = '#vibrate'
          }}
        />
        <ScalingCard
          text='선택'
          onClick={() => {
            next()
            window.location.href = '#vibrate'
          }}
        />
        <ScalingCard
          text='선택'
          onClick={() => {
            next()
            window.location.href = '#vibrate'
          }}
        />
      </div>
    </div>
  )
}

export default GoldCards
