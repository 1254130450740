import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import useTimer from '../../hooks/useTimer'
import ModalStore from '../../stores/ModalStore'
import VideoStore from '../../stores/VideoStore'

const QuestIndicator = observer(({ mainType, type, questCount, onEnd }) => {
  const currentIndex = VideoStore?.currentIndex

  const [gauge, setGauge] = useState(0)

  if (mainType === 'count') {
    const [questIndex, setQuestIndex] = useState(-1)

    useEffect(() => {
      setQuestIndex(questIndex + 1)
    }, [currentIndex])

    useEffect(() => {
      if (questIndex === questCount) onEnd()
      if (questIndex === 0 && type === 'onboarding') {
        ModalStore.setCurrentModal({ open: true, type })
      }
      setGauge((questIndex / questCount) * 90)
    }, [questIndex])

    return (
      <>
        <div className='absolute z-10 top-12 w-[85%] left-[5%] h-6 bg-[#b6b3b3] text-center rounded-full px-2 m-0 shadow-xl opacity-60' />

        <div
          style={{ width: `${gauge}%`, transition: 'width 1s' }}
          className='absolute z-10 top-[3.25rem] left-[6%] h-4 bg-gradient-to-b from-[#EB7766] to-[#CF3930] text-center rounded-full px-2 m-0 shadow-xl'
        />
        <div className='absolute z-10 right-4 top-8 w-14 h-35'>
          <div className='absolute z-10 w-14 h-14'>
            <img src='/images/coin.png' alt='coin' className='w-full h-full' />
            <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-md font-bold text-[#8B2D13] drop-shadow-xl'>
              {questIndex}/{questCount}
            </div>
          </div>
        </div>
      </>
    )
  }
  const videoStatus = VideoStore?.status

  const { time } = useTimer({
    questTime: questCount,
    currentIndex,
    videoStatus,
  })

  useEffect(() => {
    if (time <= 0) onEnd()
  }, [time])

  return (
    <div className='absolute z-10 flex flex-col justify-center right-2 top-32'>
      <img src='/images/coin.png' alt='coin' className='w-14 h-14' />
      <span className='absolute top-5 left-2 text-sm font-bold text-[#8B2D13] drop-shadow-lg'>
        00:{time >= 10 ? time : `0${time}`}
      </span>
    </div>
  )
})

export default QuestIndicator
