import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'

const sprite = [
  {
    type: 'onboardingToday',
    index: 0,
    img: '/images/coins.png',
    info: {
      coin: 20000,
    },
  },
  {
    type: 'onboardingTomorrow',
    index: 1,
    img: '/images/twoCoins.png',
    info: {
      coin: 10000,
      tomorrowCoin: 40000,
    },
  },
]

const Content = ({ each, selected, setSelected }) => {
  const { index, img } = each
  return (
    <div
      aria-hidden='true'
      className={`flex flex-col items-center pt-4 pb-2 rounded-xl  ${
        index === selected
          ? 'border-[#A45B54] border-4 bg-[#EFB390]'
          : 'border-gray-500 border-4'
      }`}
      style={{ width: '48%' }}
      onClick={() => setSelected(index)}
    >
      <img src={img} alt='coins' className='h-24' />
      <div className='flex items-center h-16 font-bold text-white'>
        {index === 0 ? (
          <div>
            오늘 <span className='text-[#FAE194]'>20000</span>골드
          </div>
        ) : (
          <div>
            오늘 10000골드, <br />
            내일 <span className='text-[#FAE194]'>40000</span>골드
          </div>
        )}
      </div>
    </div>
  )
}

const OnboardingSelect = observer(() => {
  const token = AuthStore?.token
  const [selected, setSelected] = useState(1)
  const onClick = async () => {
    const available = [0, 1]
    if (!available.includes(selected)) return
    const target = sprite.find((each) => each.index === selected)
    const { type } = target
    const result = await backendApis.money(token, 'POST', { type })
    if (result?.status === 200) {
      ModalStore.setCurrentModal({ open: false })
      UserStore.setMoneyInfo(result?.data)
      UserStore.setQuestLog({
        ...UserStore?.questLog,
        onboardingSelect: false,
      })
      if (selected === 1) {
        ModalStore.setCurrentModal({
          open: true,
          mainType: 'noBackground',
          type: 'tomorrowCoinInfo',
          value: result?.data?.tomorrowCoin,
        })
      }
    }
  }

  return (
    <>
      <div className='flex flex-row justify-between w-full'>
        {sprite.map((each) => (
          <Content each={each} selected={selected} setSelected={setSelected} />
        ))}
      </div>
      <div className='h-6' />
      <div
        className='w-full py-2.5 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold text-lg'
        aria-hidden='true'
        onClick={onClick}
      >
        골드 받기
      </div>
    </>
  )
})

export default OnboardingSelect
