import { useEffect, useRef } from 'react'

export default function useThrottle() {
  const timer = useRef(null)

  const throttle = (callback, time) => {
    if (!timer.current) {
      timer.current = setTimeout(() => {
        callback()
        timer.current = null
      }, time)
    }
  }

  useEffect(() => () => {
    if (timer.current) clearTimeout(timer)
  })

  return throttle
}
