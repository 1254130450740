import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useTimer from '../../hooks/useTimer'
import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import VideoStore from '../../stores/VideoStore'
import backendApis from '../../utils/backendApis'
import Toast from '../atoms/toast'

const nightCoinCounts = [
  { video: 5, coin: 2000 },
  { video: 7, coin: 3000 },
  { video: 10, coin: 4000 },
  { video: 15, coin: 5000 },
  { video: 30, coin: 6000 },
]

const sprite = [
  {
    type: 'dailyTenVideos',
    image: '/images/coin.png',
    onEnd: async ({ type, detail }) => {
      const token = AuthStore?.token
      const questsObj = UserStore?.questLog

      const { coin } = detail

      const result = await backendApis.money(token, 'POST', { type })
      if (result?.status === 200) {
        UserStore?.setOngoingQuest({ type: null })
        UserStore?.setMoneyInfo({
          ...UserStore?.moneyInfo,
          coin: (UserStore?.moneyInfo?.coin || 0) + coin,
        })
        ModalStore.setCurrentBottomSheet({ open: false })
        UserStore?.setQuestLog({ ...questsObj, [type]: false })
        ModalStore.setCurrentModal({
          open: true,
          type: 'quest',
          coin,
        })
      }
    },
  },
  {
    type: 'coinAfterMeal',
    image: '/images/meal2.png',
    onEnd: async ({ type, detail }) => {
      const token = AuthStore?.token
      const questsObj = UserStore?.questLog

      const { coin } = detail

      const result = await backendApis.money(token, 'POST', { type })
      if (result?.status === 200) {
        UserStore?.setOngoingQuest({ type: null })
        UserStore?.setMoneyInfo({
          ...UserStore?.moneyInfo,
          coin: (UserStore?.moneyInfo?.coin || 0) + coin,
        })
        ModalStore.setCurrentBottomSheet({ open: false })
        UserStore?.setQuestLog({ ...questsObj, [type]: false })
        ModalStore.setCurrentModal({
          open: true,
          type: 'quest',
          coin,
        })
      }
    },
  },
  {
    type: 'nightCoin',
    image: '/images/moonColored.png',
    onClick: ({ navigate }) => navigate('/night'),
    onEnd: async ({ type, questsObj, setToastInfo }) => {
      const token = AuthStore?.token
      const count = (questsObj?.[type]?.count || 0) + 1

      const result = await backendApis.money(token, 'POST', { type })
      if (result?.status !== 200) return

      if (count < 5) {
        const target = nightCoinCounts.find((each, index) => index === count)
        const video = target?.video
        const coin = target?.coin
        setToastInfo({ open: true, duration: 3000, coin, video })
        UserStore?.setQuestLog({
          ...questsObj,
          [type]: {
            ...questsObj?.[type],
            count,
          },
        })
        UserStore.setOngoingQuest({
          questCount: video,
          type: 'nightCoin',
          detail: {
            type: 'nightCoin',
            coin,
          },
        })
      } else {
        UserStore?.setQuestLog({
          ...questsObj,
          [type]: {
            ...questsObj?.[type],
            count,
          },
        })
        UserStore?.setOngoingQuest({ type: null })
        UserStore?.setMoneyInfo({
          ...UserStore?.moneyInfo,
          coin: (UserStore?.moneyInfo?.coin || 0) + 20000,
        })
        ModalStore.setCurrentModal({
          open: true,
          type: 'quest',
          coin: 20000,
        })
      }
    },
  },
]

const QuestCoin = observer(() => {
  const currentIndex = VideoStore?.currentIndex

  const questsObj = UserStore?.questLog
  const quest = UserStore?.ongoingQuest

  const questCount = quest?.questCount
  const type = quest?.type
  const detail = quest?.detail

  const videoStatus = VideoStore?.status

  const { time } = useTimer({
    questTime: questCount,
    currentIndex,
    videoStatus,
  })

  const target = sprite.find((each) => each.type === type)
  if (!target) return null

  const { image, onEnd } = target
  const onClick = target?.onClick || false
  const [toastInfo, setToastInfo] = useState({ open: false })

  useEffect(() => {
    if (time <= 0) onEnd({ type, detail, questsObj, setToastInfo })
  }, [time])

  const navigate = useNavigate()

  return (
    <>
      <div
        aria-hidden='true'
        className='absolute z-20 flex flex-col items-center top-20'
        onClick={onClick ? () => onClick({ navigate }) : () => true}
      >
        <img
          src={image}
          alt='coin_img'
          className='z-10 w-12 h-12 bg-gray-700 rounded-full opacity-70'
        />
        <div className='absolute z-20 text-sm font-bold text-[#F7EFD5] top-4 drop-shadow-lg'>
          00:{time >= 10 ? time : `0${time}`}
        </div>
      </div>
      <Toast info={toastInfo} setInfo={setToastInfo} from='nightCoin' />
    </>
  )
})

export default QuestCoin
