import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import AuthStore from '../../stores/AuthStore'
import DailyCheckInContent from '../molecules/dailyCheckInContent'
import backendApis from '../../utils/backendApis'
import MealContent from '../molecules/mealContent'
import OnboardingSelect from '../molecules/onboardingSelect'
import DailyInviteContent from '../molecules/dailyInviteContent'
import FriendManageContents from '../molecules/repost/friendManageContents'
import PushExample from '../molecules/pushExample'
import Contacts from '../molecules/repost/contacts'
import repostVideoStore from '../../stores/repostVideoStore'

const ModalContents = observer(() => {
  const token = AuthStore?.token
  const target = ModalStore?.currentModal
  const navigate = useNavigate()

  if (!target?.open) return null

  const { type } = target
  if (!type) return null
  if (type === 'repostTeamGuide') {
    const [guidePage, setGuidePage] = useState(0)

    if (guidePage === 0) {
      return (
        <div className='flex flex-col items-center justify-center'>
          <div className='flex flex-col text-xl font-bold text-center'>
            영상을 같이 보면
            <br />
            골드가 더 빠르게 모여요!
          </div>
          <div className='my-5'>
            <img
              src='/images/onboardingRepostTeam/repostTeamOnboard2.webp'
              alt='onboard'
            />
          </div>
          <div className='flex flex-row gap-2'>
            <div className='w-2 h-2 rounded-full bg-main-color' />
            <div className='w-2 h-2 rounded-full bg-main-color-light' />
          </div>
          <button
            type='button'
            className='w-3/4 h-10 max-w-xs py-2 mt-5 font-bold text-center text-white rounded-lg select-none bg-main-color'
            onClick={() => {
              setGuidePage(1)
            }}
          >
            다음
          </button>
        </div>
      )
    }
    if (guidePage === 1) {
      return (
        <div className='flex flex-col items-center justify-center'>
          <div className='flex flex-col text-xl font-bold text-center'>
            내가 본 영상을
            <br />
            친구, 가족과 함께 보세요!
          </div>
          <div className='my-5'>
            <img
              src='/images/onboardingRepostTeam/repostTeamOnboard1.webp'
              alt='onboard'
            />
          </div>
          <div className='flex flex-row gap-2'>
            <div className='w-2 h-2 rounded-full bg-main-color' />
            <div className='w-2 h-2 rounded-full bg-main-color' />
          </div>
          <button
            type='button'
            className='w-3/4 h-10 max-w-xs py-2 mt-5 font-bold text-center text-white rounded-lg select-none bg-main-color'
            onClick={() => {
              ModalStore?.setCurrentModal({ open: false })
              ModalStore?.setCurrentBottomSheet({
                open: true,
                type: 'createTeam',
              })
            }}
          >
            팀 만들기
          </button>
        </div>
      )
    }
  }

  if (type === 'coinLack') {
    const { minValue } = target
    const currentCoin = UserStore?.moneyInfo?.coin || 0
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          {minValue - currentCoin} 골드를 더 모으면,
        </div>
        <div className='text-[#A45B54] font-bold'>
          현금으로 교환할 수 있어요
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => ModalStore.setCurrentModal({ open: false })}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'moneyLack') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>3000원을 모으면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 출금할 수 있어요</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => ModalStore.setCurrentModal({ open: false })}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'tomorrowCoinInfo') {
    const { value } = target
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold bg-white px-4 py-1 rounded-full'>
          내일 오전 7시에 얻을 수 있어요
        </div>
        <div className='h-4' />
        <img src='/images/coins.png' alt='coins' className='w-24 h-24' />
        <div className='h-2' />
        <div className='text-xl font-bold text-white'>
          <span className='text-[#FAE194]'>{value}</span> 골드
        </div>
        <div className='h-4' />
        <div className='font-bold text-white'>
          더 많은 영상을 보면 더 많아져요
        </div>
        <div className='h-2' />
        <div
          className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
          aria-hidden='true'
          onClick={() => ModalStore.setCurrentModal({ open: false })}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'getTomorrowCoin') {
    const value = UserStore?.moneyInfo?.previous || 0

    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='font-bold text-white'>어제 비디오를 보고 모으신,</div>
        <div className='font-bold text-white'>{value} 골드를 얻었어요!</div>
        <div className='h-4' />
        <img src='/images/coins.png' alt='coins' className='w-24 h-24' />
        <div className='h-2' />
        <div className='text-xl font-bold text-white'>
          <span className='text-[#FAE194]'>{value}</span> 골드
        </div>
        <div className='h-4' />
        <div className='font-bold text-white'>
          더 많은 영상을 보면 더 많아져요
        </div>
        <div className='h-2' />
        <div
          className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
          aria-hidden='true'
          onClick={() => ModalStore.setCurrentModal({ open: false })}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'quest') {
    const value = target?.coin

    const onClick = () => {
      ModalStore.setCurrentModal({ open: false })
    }

    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>축하드려요!</div>
        <div className='text-[#A45B54] font-bold'>
          {commaNumber(value)} 골드를 얻었어요.
        </div>
        <div className='text-[#A45B54] font-bold'>
          오늘도 골드를 쌓아보세요!
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={onClick}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'rewardedAd') {
    const onClick = () => {
      ModalStore.setCurrentModal({ open: false })
      window.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          type: 'showRewardedAd',
          data: { adType: 'rewarded', placement: 'SHORTS_MISSION_REWARD' },
        }),
      )
    }

    return (
      <div className='flex flex-col items-center w-full h-full text-center '>
        <div className='text-[#A45B54] font-bold text-2xl mb-2'>
          광고 보고 골드 받기
        </div>
        <div className='text-[#A45B54] font-semibold text-lg'>
          광고를 보고 상단에 나타나는
          <br />
          X버튼을 눌러주세요
        </div>
        <div className='text-[#A45B54] mb-4 text-sm'>*소리가 날수도 있어요</div>
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={onClick}
        >
          광고 보기
        </div>
      </div>
    )
  }

  if (type === 'nightCoinProgress') {
    const value = target?.coin

    const onClick = () => {
      ModalStore.setCurrentModal({ open: false })
    }

    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>축하드려요!</div>
        <div className='text-[#A45B54] font-bold'>{value} 골드를 얻었어요.</div>
        <div className='text-[#A45B54] font-bold'>
          계속해서 골드를 쌓아보세요!
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={onClick}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'createTeamFirst') {
    const value = target?.coin

    const onClick = () => {
      ModalStore.setCurrentModal({ open: false })
      ModalStore.setCurrentBottomSheet({
        open: true,
        type: 'teammates',
      })
    }

    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='mb-5 text-xl font-bold'>축하드려요!</div>
        <div className='font-bold'>{value} 골드를 얻었어요.</div>
        <div className='font-bold'>
          팀원을 초대하고 더 많은 골드를 모아보세요!
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 mt-4 font-bold text-center text-white rounded-lg bg-main-color'
          aria-hidden='true'
          onClick={onClick}
        >
          팀원 초대하기
        </div>
      </div>
    )
  }

  if (type === 'repostTeamVideo') {
    const value = target?.coin

    const onClick = () => {
      ModalStore.setCurrentModal({ open: false })
    }

    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='mb-5 text-xl font-bold'>축하드려요!</div>
        <div className='font-semibold'>{value} 골드를 얻었어요.</div>
        <div className='font-semibold text-center'>
          매일 영상을 공유하고
          <br />더 많은 골드를 모아보세요!
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 mt-4 font-bold text-center text-white rounded-lg bg-main-color'
          aria-hidden='true'
          onClick={onClick}
        >
          비디오 시청하기
        </div>
      </div>
    )
  }

  if (type === 'deleteRepostVideo') {
    const onClickDelete = async () => {
      const repostId =
        repostVideoStore?.videos?.[repostVideoStore?.currentIndex]?._id

      const result = await backendApis.repost(token, 'DELETE', {
        repostId,
      })

      if (result?.status === 200) {
        repostVideoStore?.setVideos(
          repostVideoStore?.videos?.filter((each) => each?._id !== repostId),
        )
        ModalStore.setCurrentModal({ open: false })
        ModalStore.setCurrentToast({
          open: true,
          text: '삭제되었습니다.',
          time: 2,
        })
      } else {
        ModalStore.setCurrentModal({ open: false })
        ModalStore.setCurrentToast({
          open: true,
          text: '오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
          time: 2,
        })
      }
    }
    const onClickCancel = () => {
      ModalStore.setCurrentModal({ open: false })
    }

    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='mb-5 text-xl font-bold'>정말 삭제하시겠어요?</div>
        <div className='font-semibold text-center'>
          삭제하시면 다시 볼 수 없어요.
        </div>
        <div className='h-4' />
        <div className='flex flex-row w-full gap-4'>
          <div
            className='w-4/5 py-2 mt-4 font-semibold text-center rounded-lg bg-main-color-light'
            aria-hidden='true'
            onClick={onClickCancel}
          >
            취소
          </div>
          <div
            className='w-4/5 py-2 bg-main-color-light rounded-lg text-[#fe0034] text-center font-semibold mt-4'
            aria-hidden='true'
            onClick={onClickDelete}
          >
            삭제
          </div>
        </div>
      </div>
    )
  }

  if (type === 'over3000') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>3천원을 모으셨어요!</div>
        <div className='text-[#A45B54] font-bold'>3천원을 출금하시고 나서,</div>
        <div className='text-[#A45B54] font-bold'>다시 3천원을 모아보세요!</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
            navigate('/money')
          }}
        >
          출금하러 가기
        </div>
      </div>
    )
  }

  if (type === 'notice') {
    return (
      <div className='w-full h-full'>
        <div className='text-[#A45B54] font-bold text-xl text-center'>
          유의사항
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 비정상적인 이용은 출금이 어려워요.
        </div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 3,000원 단위로만 출금 할 수 있어요.
        </div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 출금 이후에는 모든 재화가 초기화돼요.
        </div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 20일 동안 시청기록이 없어도 모든 재화가 초기화돼요.
        </div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 모든 퀘스트는 오전 7시에 초기화돼요.
        </div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 관련 문의는 내 정보 &gt; 고객문의 &gt; 1:1 실시간 상담하기에서
          가능해요.
        </div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold text-sm'>
          * 서비스는 당사의 사정에 의해 진행 중인 고객님이 계셔도, 사전 고지
          없이 변경 또는 조기종료 될 수 있어요.
        </div>
      </div>
    )
  }

  if (type === 'lackWithdrawInfo') {
    const { text } = target
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold'>{text}해 주세요!</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>정확하게 입력해 주셔야,</div>
        <div className='text-[#A45B54] font-bold'>정상 출금 가능해요.</div>
      </div>
    )
  }

  if (type === 'withdrawSuccess') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>축하드려요!</div>
        <div className='text-[#A45B54] font-bold'>24시간 내에 3,000원이</div>
        <div className='text-[#A45B54] font-bold'>입금될 예정이에요.</div>
        <div className='text-[#A45B54] font-bold'>
          다시 3,000원을 모아보세요!
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'lackOfMoney') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold'>3,000원을 모아서</div>
        <div className='text-[#A45B54] font-bold'>다시 방문해 주세요!</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>비디오를 많이 보면</div>
        <div className='text-[#A45B54] font-bold'>더 빨리 모을 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-full py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold text-lg'
          aria-hidden='true'
          onClick={() => {
            navigate('/')
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'invalidAccount') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold'>입금 은행과 계좌번호를</div>
        <div className='text-[#A45B54] font-bold'>다시 확인해 주세요!</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>정확하게 입력해 주셔야,</div>
        <div className='text-[#A45B54] font-bold'>정상 출금 가능해요.</div>
      </div>
    )
  }

  if (type === 'failToTransfer') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold'>서버 상의 오류로</div>
        <div className='text-[#A45B54] font-bold'>입금에 실패했어요.</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>
          잠시 후 다시 시도해 주세요!
        </div>
      </div>
    )
  }

  if (type === 'differentName') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold'>예금주명이 달라요!</div>
        <div className='text-[#A45B54] font-bold'>
          계좌번호와 예금주명을 확인해 주세요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>정확하게 입력해 주셔야,</div>
        <div className='text-[#A45B54] font-bold'>정상 출금 가능해요.</div>
      </div>
    )
  }

  if (type === 'customerService') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold'>서버 상의 오류로</div>
        <div className='text-[#A45B54] font-bold'>입금에 실패했어요.</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>오류가 반복되면,</div>
        <div className='text-[#A45B54] font-bold'>
          고객센터에 문의해 주세요.
        </div>
      </div>
    )
  }

  if (type === 'onboarding') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='h-2' />
        <div className='text-[#bd6c64] font-bold'>
          지금부터 <span className='text-[#9e2519]'>영상 5개</span>를 보면
        </div>
        <div className='text-[#bd6c64] font-bold'>
          <span className='text-[#9e2519]'>5만 골드</span>를 즉시 드려요
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'finishOnboarding') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>더 많은 영상을 보고</div>
        <div className='text-[#A45B54] font-bold'>3천원을 모으시면,</div>
        <div className='text-[#A45B54] font-bold'>
          현금으로 인출할 수 있어요.
        </div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
            navigate('/')
          }}
        >
          영상 더 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'onboarding2') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>환영해요!</div>
        <div className='h-2' />
        <div className='text-[#A45B54] font-bold'>10,000 골드를 드릴게요.</div>
        <div className='text-[#A45B54] font-bold'>
          골드는 현금으로 바꿀 수 있어요.
        </div>
        <div className='h-4' />
        <div
          className='w-full py-2.5 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center text-lg font-bold'
          aria-hidden='true'
          onClick={async () => {
            const result = await backendApis.money(token, 'POST', {
              type: 'onboarding2',
            })
            if (result?.status === 200) {
              UserStore?.setMoneyInfo({
                ...UserStore?.moneyInfo,
                coin: (UserStore?.moneyInfo?.coin || 0) + 10000,
              })
              ModalStore.setCurrentModal({ open: false })
            }
          }}
        >
          10,000 골드 얻기
        </div>
      </div>
    )
  }

  if (type === 'onboardingSelect') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-lg font-bold text-white'>
          원하는 골드를 선택해 주세요
        </div>
        <div className='h-6' />
        <OnboardingSelect />
      </div>
    )
  }

  if (type === 'dailyCheckInVer2') {
    const { count, available } = target

    if (available) {
      return (
        <div className='flex flex-col items-center w-full h-full'>
          <div className='text-[#A45B54] font-bold text-lg'>
            7일 만에 15만 골드 받기
          </div>
          <div className='text-[#A45B54] font-bold text-sm mb-1'>
            * 연속으로 들어와야 받을 수 있어요
          </div>
          <DailyCheckInContent count={count} available={available} />
          <div className='h-2' />
          <div
            className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
            aria-hidden='true'
            onClick={async () => {
              const result = await backendApis.money(token, 'POST', {
                type: 'dailyCheckInVer2',
              })
              if (result?.status === 200 && result?.data) {
                UserStore.setMoneyInfo(result.data)
                ModalStore.setCurrentModal({
                  open: true,
                  type: 'dailyCheckInVer2',
                  count: count + 1,
                  available: false,
                })
              }
            }}
          >
            오늘 보상 받기
          </div>
        </div>
      )
    }
    if (count === 7) {
      return (
        <div className='flex flex-col items-center w-full h-full'>
          <div className='text-[#A45B54] font-bold text-lg'>축하드려요!</div>
          <div className='text-[#A45B54] font-bold text-lg'>
            7일 동안 15만 골드를 얻었어요
          </div>
          <div className='text-[#A45B54] font-bold text-lg'>
            3천원을 모아 출금해 보세요
          </div>
          <div className='h-4' />
          <div
            className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
            aria-hidden='true'
            onClick={() => {
              ModalStore.setCurrentModal({ open: false })
            }}
          >
            비디오 보기
          </div>
        </div>
      )
    }
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] font-bold text-lg'>
          오늘 출석체크 성공!
        </div>
        <div className='text-[#A45B54] font-bold text-sm mb-1'>
          * 내일 출석하면 또 받을 수 있어요
        </div>
        <div className='h-4' />
        <DailyCheckInContent count={count} available={available} />
        <div className='h-6' />
        <div
          className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          비디오 보기
        </div>
      </div>
    )
  }

  if (type === 'meal') {
    const { targetNum, available } = target

    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-lg font-bold text-white'>
          밥도 먹고 골드도 얻어보세요
        </div>
        <div className='h-3' />
        <MealContent targetNum={targetNum} available={available} />
      </div>
    )
  }

  if (type === 'coinAfterMeal') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='text-[#A45B54] mb-2 font-bold'>골드를 얻었어요!</div>
        <div className='rounded-full p-4 bg-[#F5B787]'>
          <img src='/images/golds.png' alt='golds' className='w-14 h-14' />
        </div>
        <div className='bg-gradient-to-b from-[#EB7766] to-[#CF3930] rounded-full text-white text-sm font-bold px-2 py-1 -mt-4 mb-4'>
          2000 골드
        </div>
        <div
          className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({
              open: false,
            })
            UserStore?.setOngoingQuest({
              questCount: 20,
              type: 'coinAfterMeal',
              detail: {
                type: 'coinAfterMeal',
                coin: 8000,
              },
            })
          }}
        >
          영상 보고 8000 골드 더 얻기
        </div>
      </div>
    )
  }

  if (type === 'dailyInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <DailyInviteContent />
      </div>
    )
  }

  if (type === 'successTeamInvite') {
    // TODO: 첫 유저 경험해보기
    const { teamId, teamName } = target
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold text-lg'>
          [{teamName}] 팀 초대 성공
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>팀과 함께 영상도 보고</div>
        <div className='text-[#A45B54] font-bold'>현금도 모아보세요!</div>
        <div className='h-8' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'getInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          초대를 받아주셔서 감사해요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'alreadyInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>이미 참여하신 링크예요.</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'alreadyFollow') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>이미 친구가 되어 있어요.</div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === '3timesInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          하루에 3번만 참여해 줄 수 있어요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === '1timesInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          퀘스트는 하루에 1번만 참여해 줄 수 있어요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'fullInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          이미 초대가 완료된 링크예요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'selfInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          본인 링크로는 참여가 어려워요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'leftUserInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          탈퇴 이력이 있는 고객님은
        </div>
        <div className='text-[#A45B54] font-bold'>
          초대 이벤트 참여가 어려워요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'wrongInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>다른 분의 링크예요!</div>
        <div className='text-[#A45B54] font-bold'>
          나에게 온 링크가 맞는지 확인해 주세요.
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'errorInvite') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold text-lg'>오류가 생겼어요.</div>
        <div className='text-[#A45B54] font-bold'>
          다시 링크를 클릭해 주세요!
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>재밌는 비디오를 보면,</div>
        <div className='text-[#A45B54] font-bold'>현금을 인출할 수 있어요.</div>
        <div className='h-4' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }
  if (type === 'errorTeamInvite') {
    const { msg } = target
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold text-lg'>초대 수락 실패</div>

        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>{msg}</div>
        <div className='h-8' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  if (type === 'friendManage') {
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <FriendManageContents />
      </div>
    )
  }

  if (type === 'friendRepostNudge') {
    const { friendId, friendName, myName } = target
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          {friendName ? `${friendName} 님이` : '친구가'} 아직 영상 공유 전이에요
        </div>
        <div className='text-[#A45B54] font-bold'>아래 알림을 보내 보세요!</div>
        <div className='h-4' />
        <PushExample
          title='같이 보고 싶은 영상을 나눠주세요!'
          desc={`${myName ? `${myName} 님` : '친구들'}이 기다리고 있어요.`}
        />
        <div className='h-5' />
        <div
          className='w-full py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
            backendApis.appPush(token, 'POST', {
              type: 'repostNudge',
              title: '같이 보고 싶은 영상을 나눠주세요!',
              message: `${
                myName ? `${myName} 님` : '친구들'
              }이 기다리고 있어요.`,
              receiverId: friendId,
              expireTime: 60,
            })
            ModalStore.setCurrentToast({
              open: true,
              text: '알림을 보냈어요',
              time: 2,
            })
          }}
        >
          친구에게 알림 보내기
        </div>
      </div>
    )
  }

  if (type === 'noRepost') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          오른쪽 밑에 같이보기를 눌러보세요!
        </div>
        <div className='text-[#A45B54] font-bold'>
          내가 좋아하는 영상이 친구들과 공유돼요
        </div>
      </div>
    )
  }

  if (type === 'contacts') {
    const onClickInvite = () => {
      window.location.href = `#shareLink.${JSON.stringify({
        shareType: 'ShortsFriendLink',
        title: '[올웨이즈] 친구야 같이 보자!',
        description: '친구가 좋아하는 영상을 같이 시청해 보세요.',
        imageUrl:
          'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
      })}`
    }
    return (
      <div className='flex flex-col items-center w-full h-full'>
        <div className='mb-2 text-lg font-bold text-white'>
          친구랑 영상 같이보기
        </div>
        <Contacts />
        <div
          className='w-5/6 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] text-lg rounded-3xl text-white text-center font-bold mt-4'
          aria-hidden='true'
          onClick={onClickInvite}
        >
          링크로 초대하기
        </div>
      </div>
    )
  }

  if (type === 'InviteFollowee') {
    return (
      <div className='flex flex-col items-center w-full h-full '>
        <div className='text-[#A45B54] font-bold'>
          초대를 받아주셔서 감사해요
        </div>
        <div className='h-4' />
        <div className='text-[#A45B54] font-bold'>
          친구가 공유한 영상은 상단에서 볼 수 있어요
        </div>
        <div className='h-6' />
        <div
          className='w-4/5 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-3xl text-white text-center font-bold'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({ open: false })
          }}
        >
          영상 보러 가기
        </div>
      </div>
    )
  }

  return null
})

export default ModalContents
