import { observer } from 'mobx-react-lite'
import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'
import ModalStore from '../../../stores/ModalStore'
import backendApis from '../../../utils/backendApis'
import React, { useEffect } from 'react'

const RepostGuide = observer(({ url, videoId }) => {
  const token = AuthStore?.token
  const sessionId = AuthStore?.sessionId

  // 이 Component 가 노출되면 PageView 저장
  useEffect(() => {
    // 유저에 repostGuide 가 노출되었음을 저장한다.
    backendApis.repostGuide(token, 'POST')
    UserStore.setExposedToRepostGuideAt(Date.now())
    const userId = UserStore?.userInfo?.userId
    const eventName = 'viewRepostGuide'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
  }, [])

  const onClick = async () => {
    ModalStore.setCurrentBottomSheet({ open: false })
    const userId = UserStore?.userInfo?.userId
    const eventName = 'repostVideo'
    const response = await backendApis.repost(token, 'POST', {
      userId,
      url,
      videoId,
    })
    const eventDesc = {
      videoId,
      url,
      version: process.env.REACT_APP_VERSION,
      status: response?.status,
      dataStatus: response?.data?.status,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
    if (response?.status !== 200) {
      if (response?.status === 400 && response?.data?.status === 4000) {
        ModalStore.setCurrentAlert({
          open: true,
          text: '이미 추가된 영상입니다.',
        })
      } else {
        ModalStore.setCurrentAlert({
          open: true,
          text: '잠시 후에 다시 시도해 주세요.',
        })
      }
    } else {
      ModalStore.setCurrentToast({
        open: true,
        text: '친구가 볼 수 있게 내 영상에 추가했어요',
        time: 3,
      })
      // 공유된 피드를 자동으로 열어준다
      ModalStore.setCurrentFriendRepostFeed({
        open: true,
        from: 'repostButton',
      })
    }
  }

  return (
    <div className='w-full flex flex-col justify-center items-center p-8'>
      <div className='text-lg font-bold h-14'>방금 본 영상 재밌었나요?</div>
      <div className='flex flex-col items-start pl-4 pr-4'>
        <div className='pb-1'>같이보기로 친구들에게 공유해 보세요</div>
        <div className='text-sm'>
          공유된 영상은 내 영상에서 확인할 수 있어요
        </div>
      </div>
      <div
        aria-hidden='true'
        className='flex flex-row items-center justify-center 
        bg-[#df2222] w-48 h-10 rounded-sm mt-8 px-4'
        onClick={onClick}
      >
        <div className='text-md font-bold text-white'>같이보기</div>
      </div>
    </div>
  )
})

export default RepostGuide
