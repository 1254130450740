import { useEffect, useState } from 'react'
import backendApis from '../utils/backendApis'

const apiKey = 'AIzaSyASYA0VIsHA5dMlAAlVj_O41QBSctgkeVs'

export default function useComment({ token, url, length }) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!length) return
    const fetchData = async () => {
      const result = await backendApis.comment(token, 'GET', { url })
      if (result?.status === 200 && result?.data?.length) {
        setTimeout(() => {
          setData(result?.data)
          setIsLoading(false)
        }, 1000)
      }
    }
    fetchData()
  }, [url])

  return { isLoading, data }
}
