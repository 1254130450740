import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import VideoStore from '../../stores/VideoStore'
import RepostButton from './repost/repostButton'
import CommentButton from './commentButton'
import LikeButton from './likeButton'
import ShareButton from './shareButton'
import UserStore from '../../stores/UserStore'
import ModalStore from '../../stores/ModalStore'

const Interactions = observer(({ index, currentIndex }) => {
  if (!VideoStore?.videos?.[index]) return null
  const { _id, url, likeCount, commentCount } = VideoStore.videos[index]

  // Trigger repostGuide

  // useEffect(() => {
  //   const exposedToRepostGuideAt = UserStore?.exposedToRepostGuideAt
  //   // Trigger 조건: 친구가 1명 이상이고, 한 영상을 끝까지 다봤고, 이 BottomSheet 을 한번도 보지 않은 경우
  //   if (
  //     VideoStore?.status === 'loop' &&
  //     UserStore?.followList?.length > 0 &&
  //     !exposedToRepostGuideAt
  //   ) {
  //     ModalStore.setCurrentBottomSheet({
  //       open: true,
  //       type: 'repostGuide',
  //       url,
  //       videoId: _id,
  //     })
  //   }
  // }, [VideoStore?.status === 'loop'])

  return (
    <div
      className='fixed z-30 right-2'
      style={{ bottom: `${-100 * index + 5}%` }}
    >
      <LikeButton likeCount={likeCount} url={url} />
      <div className='h-6' />
      <CommentButton commentCount={commentCount} url={url} />
      <div className='h-6' />
      <ShareButton currentIndex={currentIndex} />
      <div className='h-6' />
      <RepostButton url={url} videoId={_id} />
      <div className='h-4' />
    </div>
  )
})

export default Interactions
