import React from 'react'

const NotSelected = ({ text, type }) => (
  <div
    className={`h-full absolute fade-in opacity-50 flex flex-col justify-center ${
      type === 'left' ? 'left-8' : 'right-8'
    }`}
  >
    <div className='w-28 h-32 border-4 border-[#F6912F] rounded-3xl bg-[#FDF4D3] flex flex-col items-center justify-center'>
      <div className='font-bold text-md'>획득</div>
      <div className='flex flex-row items-center justify-center mt-2 font-bold text-black text-md'>
        <img src='/images/coin.png' alt='coin' className='w-4 h-4 mr-1' />
        {text}
      </div>
    </div>
  </div>
)

const SelectedCard = ({ next }) => (
  <div className='flex justify-center w-full h-full'>
    <img src='/images/congrats.gif' alt='congrats' className='absolute' />
    <div className='absolute text-xl font-bold text-white top-44'>
      축하드려요!
    </div>
    <div className='flex justify-center w-full h-full'>
      <NotSelected text='5,000' type='left' />
      <div className='z-20 flex items-center justify-center w-full h-full grow'>
        <div
          className='w-36 h-44 border-4 border-[#F6912F] rounded-3xl bg-[#FDF4D3] flex flex-col items-center justify-center'
          style={{
            boxShadow:
              '12px 8px 8px -4px #F2CB3E90, -12px -8px 8px -4px #F2CB3E90',
          }}
        >
          <div className='text-xl font-bold text-[#FF3E3E]'>골드 획득</div>
          <div className='flex flex-row items-center justify-center mt-4 text-2xl font-bold text-[#FF3E3E]'>
            <img src='/images/coin.png' alt='coin' className='w-6 h-6 mr-1' />
            50,000
          </div>
        </div>
      </div>
      <NotSelected text='1,000' type='right' />
    </div>
    <div className='absolute bottom-40 text-white text-lg font-bold mt-5 w-3/4 py-4 bg-gradient-to-r flex items-center justify-center z-20'>
      골드는 💸<span className='text-[#fc8200]'>현금</span>으로 바꿀 수 있어요
    </div>
    <div
      aria-hidden='true'
      className='absolute bottom-20 text-white font-bold mt-5 w-3/4 py-4 rounded-full bg-gradient-to-r from-[#F5003B] to-[#F5832E] flex items-center justify-center z-20'
      onClick={next}
    >
      받으러 가기
    </div>
  </div>
)

export default SelectedCard
