import React from 'react'
import Goback from '../atoms/goback'

const Title = ({ title }) => <div>{title}</div>

const Info = ({ text, onClick }) => (
  <div aria-hidden='true' onClick={onClick} className='text-sm'>
    {text}
  </div>
)

const Header = ({
  callback,
  goback = null,
  title = null,
  info = null,
  white = false,
}) => (
  <div className='absolute top-0 left-0 z-50 flex flex-row items-center justify-between w-screen px-4 py-4'>
    {goback && <Goback white={white} onClick={callback?.goback} />}
    {title && <Title title={title} />}
    {info && <Info text={info} onClick={callback?.info} />}
  </div>
)

export default Header
