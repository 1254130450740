import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useLocation } from 'react-use'

import AppMain from '../../pages/appMain'
import MoneyScreen from '../../pages/moneyScreen'
import WithdrawScreen from '../../pages/withdrawScreen'
import OnboardingScreen from '../../pages/onboardingScreen'
import QuestVideoScreen from '../../pages/questVideoScreen'
import NightCoinScreen from '../../pages/nightCoinScreen'
import ChannelScreen from '../../pages/channelScreen'
import ChannelVideoScreen from '../../pages/channelVideoScreen'
import OnboardingRepostScreen from '../../pages/onboardingRepostScreen'
import RepostScreen from '../../pages/repostScreen'
import BottomSheets from './bottomSheets'
import Modals from './modals'
import Alerts from './alerts'
import TextToasts from './textToasts'

const routes = [
  {
    path: '/',
    exact: true,
    element: <AppMain />,
  },
  {
    path: '/money',
    element: <MoneyScreen />,
  },
  {
    path: '/withdraw',
    element: <WithdrawScreen />,
  },
  {
    path: '/onboarding',
    element: <OnboardingScreen />,
  },
  {
    path: '/onboarding-repost',
    element: <OnboardingRepostScreen />,
  },
  {
    path: '/quest-video',
    element: <QuestVideoScreen />,
  },
  {
    path: '/night',
    element: <NightCoinScreen />,
  },
  {
    path: '/channel',
    element: <ChannelScreen />,
  },
  {
    path: '/channel-videos',
    element: <ChannelVideoScreen />,
  },
  {
    path: '/reposts',
    element: <RepostScreen />,
  },
]

const StackNavigator = () => {
  const location = useLocation()
  return (
    <BrowserRouter>
      <Routes location={location}>
        {routes.map((route, index) => (
          <Route
            key={route.path}
            exact={route?.exact}
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
      <Modals />
      <BottomSheets />
      <Alerts />
      <TextToasts />
    </BrowserRouter>
  )
}

export default StackNavigator
