import React, { useEffect, useState } from 'react'
import backendApis from '../utils/backendApis'

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export default function useContacts({ token }) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let ongoing = true
    let chunk = data?.length || 0

    const fetchData = async () => {
      while (ongoing) {
        const result = await backendApis.contacts(token, 'GET', {
          num: chunk || 0,
        })
        if (result?.status === 200 && result?.data?.length > 0) {
          const previous = data.map((each) => each?._id)
          const adding = result.data.filter(
            (elem) => !previous.includes(elem?._id),
          )
          setData((prev) => [...prev, ...adding])
          chunk += adding.length
          setIsLoading(false)
        } else {
          ongoing = false
        }
        await sleep(3000)
      }
    }
    fetchData()
  }, [])

  return { data, setData, isLoading }
}
