import { observer } from 'mobx-react-lite'
import React from 'react'

import ModalStore from '../../stores/ModalStore'
import ModalContents from '../organisms/modalContents'

const CoinImage = () => (
  <img
    src='/images/coin.png'
    alt='coin'
    className='absolute w-20 h-20 -translate-x-1/2 left-1/2 -top-10'
  />
)

const Blank = () => <div className='h-6' />

const CloseButton = ({ onClick, className }) => (
  <div
    className={`absolute z-20 ${className}`}
    aria-hidden='true'
    onClick={onClick}
  >
    <img src='/images/close.png' alt='close' className='w-8 h-8' />
  </div>
)

const Modals = observer(() => {
  const isOpen = ModalStore?.currentModal?.open
  const mainType = ModalStore?.currentModal?.mainType

  if (!isOpen) return null

  const closeModal = () => {
    setTimeout(() => ModalStore.setCurrentModal({ open: false }), 50)
  }

  if (mainType === 'noBackground') {
    return (
      <>
        <div
          className='absolute top-0 left-0 z-30 w-full h-full bg-black opacity-80'
          aria-hidden='true'
          onClick={closeModal}
        />
        <div className='absolute z-40 w-full px-4 py-6 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 rounded-xl'>
          <ModalContents />
          <CloseButton onClick={closeModal} className='right-10 -top-8' />
        </div>
      </>
    )
  }
  if (mainType === 'whiteBackground') {
    return (
      <>
        <div
          className='absolute top-0 left-0 z-30 w-full h-full bg-black opacity-30'
          aria-hidden='true'
          onClick={closeModal}
        />
        <div className='absolute top-1/2 left-1/2 w-4/5 py-6 z-40 bg-white -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'>
          <ModalContents />
          <CloseButton onClick={closeModal} className='right-0 -top-12' />
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className='absolute top-0 left-0 z-30 w-full h-full bg-black opacity-30'
        aria-hidden='true'
        onClick={closeModal}
      />
      <div className='absolute top-1/2 left-1/2 w-4/5 py-6 z-40 bg-gradient-to-b from-[#FBEBD7] to-[#FFFEFE] -translate-x-1/2 -translate-y-1/2 rounded-xl px-4'>
        <CoinImage />
        <Blank />
        <ModalContents />
        <CloseButton onClick={closeModal} className='right-0 -top-12' />
      </div>
    </>
  )
})

export default Modals
