import React from 'react'

const ChannelImage = ({
  thumbnailUrl = 'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_110x110.jpg',
}) => {
  if (!thumbnailUrl) return null

  return (
    <img
      src={thumbnailUrl}
      alt='thumbnail'
      className='w-8 h-8 border-2 border-white rounded-full'
      crossOrigin='anonymous'
      onError={(event) => {
        event.target.src = '/images/videoPlayer/defaultProfile.jpeg'
        event.onerror = null
      }}
    />
  )
}

export default ChannelImage
