import React from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../stores/ModalStore'
import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import converter from '../../../utils/converter'

const FriendManage = observer(() => {
  const token = AuthStore?.token
  const userId = UserStore?.userInfo?.userId
  const sessionId = AuthStore?.sessionId

  const onClick = () => {
    const eventName = 'clickFriendManage'
    const eventDesc = {
      version: process.env.REACT_APP_VERSION,
    }
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
    if (
      converter.codepushConverter(UserStore?.userInfo?.codepushVersion) >=
      converter.codepushConverter('2.4.0')
    ) {
      window.location.href = '#uploadContacts'
    } else {
      ModalStore.setCurrentModal({
        open: true,
        mainType: 'noBackground',
        type: 'friendManage',
      })
    }
  }

  return (
    <button
      type='button'
      className='flex flex-col items-center w-20 h-full mr-1'
      onClick={onClick}
    >
      <div className='w-11 h-11 bg-[#7e7e7e] rounded-full flex flex-row justify-center items-center'>
        <img
          src='/images/plus.png'
          alt='plus'
          aria-hidden='true'
          className='w-5 h-5'
        />
      </div>
      <div className='mt-2 text-xs text-white'>같이볼 친구</div>
    </button>
  )
})

export default FriendManage
