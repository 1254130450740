import { observer } from 'mobx-react-lite'
import React from 'react'
import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'

const times = [
  {
    type: 'breakfast',
    text: '아침',
    start: 7,
    end: 11,
  },
  {
    type: 'lunch',
    text: '점심',
    start: 12,
    end: 14,
  },
  {
    type: 'dinner',
    text: '저녁',
    start: 18,
    end: 21,
  },
]

const Content = ({ each, index, targetNum, available }) => {
  const { text, start, end } = each

  const target = index === targetNum

  let buttonText
  if (target) buttonText = '2000골드'
  else if (index < targetNum) buttonText = '완료'
  else buttonText = '진행 전'

  return (
    <div
      className={`w-full h-40 rounded-md mx-1 flex flex-col items-center ${
        target ? 'bg-gradient-to-b from-[#ED7F45] to-[#F3CB90]' : 'bg-[#FAEEDF]'
      }`}
    >
      <div
        className={`${
          target ? 'text-white' : 'text-[#D7643E]'
        } text-sm font-bold my-2 text-center`}
      >
        {text}
        <br />
        {start}~{end}시
      </div>
      <img
        src={`/images/meal${index + 1}.png`}
        alt='meals'
        className='w-20 h-16'
      />
      <div
        className={`mt-1 w-full h-full flex items-center justify-center ${
          target
            ? 'text-white  rounded-lg bg-[#ED8145] font-bold'
            : 'text-[#E7A488]'
        }`}
      >
        {buttonText}
      </div>
    </div>
  )
}

const MealContent = observer(({ targetNum, available }) => {
  const token = AuthStore.token

  return (
    <>
      <div className='flex flex-row items-center justify-center w-full bg-white rounded-lg h-44'>
        {times.map((each, index) => (
          <Content
            each={each}
            index={index}
            targetNum={targetNum}
            available={available}
          />
        ))}
      </div>
      <div className='h-4' />
      {available ? (
        <div
          className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
          aria-hidden='true'
          onClick={async () => {
            const result = await backendApis.money(token, 'POST', {
              type: 'meal',
            })
            if (result?.status === 200) {
              UserStore.setMoneyInfo(result.data)
              UserStore.setQuestLog({
                ...UserStore?.questLog,
                meal: {
                  targetNum: (UserStore?.questLog?.meal?.targetNum || 0) + 1,
                  available: false,
                },
              })
              ModalStore.setCurrentModal({
                open: true,
                type: 'coinAfterMeal',
              })
            }
          }}
        >
          밥 먹고 골드 얻기
        </div>
      ) : (
        <div
          className='w-full py-3 text-lg font-bold text-center text-white rounded-full bg-gradient-to-b from-gray-200 to-gray-500'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentModal({
              open: false,
            })
          }}
        >
          잠시 후에 얻을 수 있어요
        </div>
      )}
    </>
  )
})

export default MealContent
