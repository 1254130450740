import React, { useEffect, useState } from 'react'

const apiKey = 'AIzaSyASYA0VIsHA5dMlAAlVj_O41QBSctgkeVs'

export default function useChannel({ channelId }) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&type=video&key=${apiKey}&videoDuration=short&maxResults=50&order=viewCount`,
    )
      .then((response) => response.json())
      .then((data) => {
        const videos = data.items.map((item) => ({
          videoId: item.id.videoId,
          thumbnailUrl: item.snippet.thumbnails.high.url,
        }))

        setData(videos)
        setIsLoading(false)
      })
      .catch((error) => console.error(error))
  }, [channelId])

  return { data, isLoading }
}
