import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useSearchParam } from 'react-use'
import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import VideoStore from '../../stores/VideoStore'
import backendApis from '../../utils/backendApis'

let index = 0
let count = 0
const toggleOn = async (token, onboarding, newIndex) => {
  if (
    onboarding &&
    newIndex > 5 &&
    newIndex < 9 &&
    index < newIndex &&
    count < 5
  ) {
    UserStore.setOnboardingToast(false)
    const result = await backendApis.money(token, 'POST', {
      type: 'onboardingToast',
    })
    if (result?.status === 200) {
      UserStore.setMoneyInfo(result.data)
      UserStore.setOnboardingToast(true)
      window.location.href = '#vibrate'
      count += 1
    }
  } else {
    UserStore.setOnboardingToast(false)
  }
  index = newIndex
}

const Carousel = observer(({ children }) => {
  const token = useSearchParam('token') || AuthStore?.token

  let viewLog
  if (VideoStore?.isFocused) {
    viewLog = {
      enteredAt: new Date(),
      url: VideoStore?.videos?.[VideoStore?.currentIndex]?.url,
      type: 'youtube',
      sessionId: AuthStore?.sessionId,
    }
  }
  const updateIndex = (newIndex) => {
    if (newIndex >= 0) {
      setTimeout(() => VideoStore.setCurrentIndex(newIndex), 40)
      backendApis.userViewLog(token, 'POST', { ...viewLog, leftAt: new Date() })
    }
    if (newIndex >= VideoStore.currentIndex) {
      toggleOn(token, UserStore?.onboarding, newIndex)
    }
  }

  const memoizedChildren = useMemo(
    () =>
      React.Children.map(children, (child) =>
        React.cloneElement(child, { width: '100%', height: '100%' }),
      ),
    [children],
  )

  return (
    <SwipeableViews
      axis='y'
      resistance
      enableMouseEvents
      index={VideoStore?.currentIndex || 0}
      onChangeIndex={updateIndex}
      containerStyle={{ height: '100vh', width: '100vw' }}
      style={{ height: '100vh', width: '100vw' }}
      slideStyle={{ transitionDuration: '0.1s' }}
    >
      {memoizedChildren}
    </SwipeableViews>
  )
})

export default Carousel
