import React from 'react'
import Timer from './Timer'

const QuestDescription = ({ quest, onClick = () => {} }) => {
  if (!quest) return null
  const {
    icon,
    title,
    desc,
    buttonText,
    buttonAvailable,
    finishText = '완료',
    lastClearedAt = null,
    onTimeEnd = () => {},
    timerMinutes = 30,
  } = quest

  return (
    <div className='h-18 px-4 flex flex-row items-center justify-between border-b-2 border-b-[#FDF2E6]'>
      <div className='flex flex-row items-center'>
        {/* 아이콘 */}
        <div className='w-10 h-10 bg-[#FDF2E7] rounded-full flex items-center justify-center mr-4'>
          <img src={icon} alt='icon' className='w-6 h-6' />
        </div>
        {/* 설명 */}
        <div>
          <div className='text-[#9F6B22] font-bold'>{title}</div>
          <div className='text-sm text-[#996B48] flex flex-row items-center'>
            <img
              src='/images/smallCoin.png'
              alt='smallCoin'
              className='w-4 h-4 mr-1'
            />
            {desc}
          </div>
        </div>
      </div>
      {/* 버튼 */}
      {!buttonAvailable && lastClearedAt ? (
        <div className='text-[#E25A3C] pr-1'>
          {
            Timer({
              timeStamp: new Date(lastClearedAt),
              onTimeEnd,
              interval: 1000,
              timerMinutes,
              startFromMinutes: true,
              noDays: true,
            }).formattedTime
          }
        </div>
      ) : (
        <div
          aria-hidden='true'
          className={`text-sm px-3 py-1.5 rounded-full font-bold text-white ${
            buttonAvailable ? 'bg-[#CE4031]' : 'bg-[#9A9A9A]'
          }`}
          onClick={() => {
            if (buttonAvailable) onClick()
          }}
        >
          {buttonAvailable ? buttonText : finishText}
        </div>
      )}
    </div>
  )
}

export default QuestDescription
