import AuthStore from '../stores/AuthStore'
import ModalStore from '../stores/ModalStore'
import UserStore from '../stores/UserStore'
import backendApis from './backendApis'
import AB from './ab'

class Initialize {
  async session(token) {
    if (!token) return
    const result = await backendApis.sessionId()
    if (!result) return
    AuthStore.setSessionId(result.toString())
    AuthStore.setToken(token)

    return () => AuthStore.setSessionId(null)
  }

  async service(token, userId, navigate) {
    if (!token) return

    // onboarding 보내기
    const fullGuideResult = await backendApis.fullGuide(token, 'GET')

    /* friend repost onboarding */
    // if (fullGuideResult?.status === 200) {
    //   if (fullGuideResult?.data?.guideType === 'repostFullGuide') {
    //     navigate('/onboarding-repost')
    //   }
    // }

    const moneyResult = await backendApis.money(token, 'GET')
    if (moneyResult?.status === 200) {
      setTimeout(() => UserStore.setMoneyInfo(moneyResult?.data), 2000)
    }

    const questResult = await backendApis.quest(token, 'GET')

    if (questResult?.status === 200) {
      const quests = questResult?.data
      UserStore.setQuestLog(quests)

      if (quests?.onboarding) {
        UserStore.setOnboarding(true)
        navigate('/onboarding')
        return
      }

      if (quests?.dailyCheckInVer2?.available !== false) {
        const count = quests?.dailyCheckInVer2?.count || 0
        setTimeout(
          () =>
            ModalStore.setCurrentModal({
              open: true,
              type: 'dailyCheckInVer2',
              count,
              available: true,
            }),
          1000,
        )
      }
    }
  }

  async tomorrowCoin(token) {
    const result = await backendApis.money(token, 'POST', {
      type: 'getTomorrowCoin',
    })
    if (result?.status === 200) {
      UserStore.setMoneyInfo(result?.data)
      UserStore.setQuestLog({
        ...UserStore?.questLog,
        getTomorrowCoin: false,
      })
      ModalStore.setCurrentModal({
        open: true,
        mainType: 'noBackground',
        type: 'getTomorrowCoin',
      })
    }
  }

  async followList(token) {
    const result = await backendApis.followee(token, 'GET', {
      orderBy: 'repostCreatedAt',
    })
    if (result?.status === 200 && result?.data?.length) {
      UserStore.setFollowList(result.data)
    }
  }

  async teamList(token) {
    const result = await backendApis.teamList(token, 'GET', {})
    if (result?.status === 200 && result?.data?.length) {
      UserStore.setTeamList(result.data)
    }
  }

  async appMain(token) {
    if (!token) return
    const repostGuide = await backendApis.repostGuide(token, 'GET')
    if (repostGuide?.status === 200) {
      UserStore.setExposedToRepostGuideAt(
        repostGuide.data?.repost?.exposedToRepostGuideAt ?? null,
      )
    }
  }
}

export default new Initialize()
