import ModalStore from '../stores/ModalStore'
import backendApis from './backendApis'

const withdraw = async (token, accountInfo, setAccountInfo) => {
  const { bankCode, bankName, account, receiver } = accountInfo

  const result = await backendApis.withdraw(token, 'POST', {
    bankCode,
    bankName,
    bankAccountNumber: account,
    receiver,
  })
  if (result?.status === 2000 || result?.status === 2005) {
    // 입금 성공 혹은 입금 대기 중
    ModalStore.setCurrentModal({ open: true, type: 'withdrawSuccess' })
    setTimeout(() => {
      window.location.href = `/?token=${token}&randomString=${Math.random()
        .toString(36)
        .substr(2, 8)}`
    }, 2000)
    setAccountInfo({
      bankCode: null,
      bankName: null,
      account: null,
      receiver: null,
    })
  } else if (result?.status === 2001) {
    // 계좌 정보 없음
    ModalStore.setCurrentModal({ open: true, type: 'lackOfMoney' })
  } else if (result?.status === 2002) {
    // 계좌 정보 없음
    ModalStore.setCurrentModal({ open: true, type: 'invalidAccount' })
  } else if (result?.status === 2003) {
    // 입금 실패
    ModalStore.setCurrentModal({ open: true, type: 'failToTransfer' })
  } else if (result?.status === 2004) {
    // 예금주명 차이
    ModalStore.setCurrentModal({ open: true, type: 'differentName' })
  } else {
    // 원인 불명
    ModalStore.setCurrentModal({ open: true, type: 'customerService' })
  }
}

export default withdraw
