import React, { useEffect, useState } from 'react'
import backendApis from '../utils/backendApis'

export default function useDailyInvite({ token }) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.dailyInvite(token, 'GET')
      if (result?.status === 200) {
        setData(result?.data || [])
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  return { data, isLoading, setIsLoading }
}
