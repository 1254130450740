import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import ChannelPlayer from '../components/molecules/channelPlayer'
import MoneyTimer from '../components/molecules/moneyTimer'
import ExtraCoin from '../components/organisms/extraCoin'
import ChannelCarousel from '../components/templates/channelCarousel'
import Layout from '../components/templates/layout'
import VideoStore from '../stores/VideoStore'

const Contents = ({ elem, index, currentIndex }) => {
  if (currentIndex > index + 1 || currentIndex < index - 1) {
    return <div id={index} aria-hidden='true' className='w-screen h-screen' />
  }

  return <ChannelPlayer src={elem} index={index} currentIndex={currentIndex} />
}

const ChannelVideoScreen = observer(() => {
  const videos = VideoStore?.channelVideos
  const currentIndex = VideoStore?.channelIndex
  const navigate = useNavigate()

  return (
    <Layout>
      <img
        aria-hidden='true'
        src='/images/thickArrow.png'
        alt='thickArrow'
        className='absolute z-50 w-6 h-6 top-8 left-4'
        onClick={() => navigate('/channel')}
      />
      <ChannelCarousel>
        {videos.map((elem, index) => (
          <Contents
            key={elem + index.toString()}
            elem={elem}
            index={index}
            currentIndex={currentIndex || 0}
          />
        ))}
      </ChannelCarousel>
      <div className='absolute z-10 flex flex-col justify-center right-2 top-32'>
        <MoneyTimer />
        <ExtraCoin />
      </div>
    </Layout>
  )
})

export default ChannelVideoScreen
