class Converter {
  codepushConverter(x) {
    if (!x || !x?.includes('.')) {
      return 0
    }
    return (
      Number(x.split('.')[0] * 1000) +
      Number(x.split('.')[1] * 100) +
      Number(x.split('.')[2])
    )
  }

  dateConverter(x) {
    if (!x) return null

    const date = new Date(x)
    const currentDate = new Date()
    const diff = currentDate - date
    const minute = 60 * 1000
    const hour = 60 * minute
    const day = 24 * hour
    const week = 7 * day
    const month = 30 * day
    const year = 365 * day

    let res
    if (diff < minute) {
      res = '방금 전'
    } else if (diff < hour) {
      const minutes = Math.floor(diff / minute)
      res = `${minutes}분 전`
    } else if (diff < day) {
      const hours = Math.floor(diff / hour)
      res = `${hours}시간 전`
    } else if (diff < week) {
      const days = Math.floor(diff / day)
      res = `${days}일 전`
    } else if (diff < month) {
      const weeks = Math.floor(diff / week)
      res = `${weeks}주 전`
    } else if (diff < year) {
      const months = Math.floor(diff / month)
      res = `${months}달 전`
    } else {
      const years = Math.floor(diff / year)
      res = `${years}년 전`
    }

    return res
  }

  likeConverter(x) {
    if (!x) return 0

    let res = x
    if (x > 100000000) {
      const num = Math.round(res / 100000000)
      res = `${num}억`
    } else if (x > 1000000) {
      const num = Math.round(res / 1000000)
      res = `${num}만`
    } else if (x > 10000) {
      const num = Math.round(res / 1000) / 10
      res = `${num}만`
    } else if (x > 1000) {
      const num = Math.round(res / 100) / 10
      res = `${num}천`
    }

    return res
  }
}

export default new Converter()
