import React from 'react'
import commaNumber from 'comma-number'

const data = [
  { id: 0, value: 3000 },
  { id: 1, value: 7000 },
  { id: 2, value: 10000 },
  { id: 3, value: 10000 },
  { id: 4, value: 10000 },
  { id: 5, value: 10000 },
  { id: 6, value: 100000 },
]

const Content = ({ each, count }) => {
  const target = each.id === count
  const final = each.id === 6
  const last = each.id < count
  const day = each.id + 1

  return (
    <div
      className={`${final ? 'w-34' : 'w-16'} h-16 mx-1 rounded-lg border-2 ${
        target ? 'border-[#AF3629]' : 'border-[#469746]'
      } ${last ? 'opacity-60' : null}`}
    >
      {final && (
        <div className='absolute px-2 py-0.5 bg-[#AF3629] border-2 border-white rounded-t-full rounded-br-full text-xs text-white font-bold right-4 top-46'>
          대박
        </div>
      )}
      {last && (
        <div
          className={`absolute bg-white border-2 border-[#FFB300] text-[#FFB300] rounded-md px-1 -rotate-12 text-sm mt-7 ${
            final ? 'ml-12' : 'ml-3'
          }`}
        >
          완료
        </div>
      )}
      <div
        className={`w-full h-5 ${
          target ? 'bg-[#AF3629]' : 'bg-[#469746]'
        } text-white flex items-center justify-center text-sm`}
      >
        {`${day} 일차`}
      </div>
      <div
        className={`w-full h-10 flex items-center justify-center ${
          target ? 'text-[#AF3629]' : 'text-[#469746]'
        }`}
      >
        {commaNumber(each.value)}
      </div>
    </div>
  )
}

const DailyCheckInContent = ({ count, available }) => {
  if (available) {
    return (
      <div className='w-full h-full my-4'>
        <div className='flex flex-row items-center justify-between mb-4'>
          {data.slice(0, 4).map((each) => (
            <Content each={each} count={count} />
          ))}
        </div>
        <div className='flex flex-row items-center justify-between'>
          {data.slice(4, 8).map((each) => (
            <Content each={each} count={count} />
          ))}
        </div>
      </div>
    )
  }

  const next = data.find((each) => each.id === count)

  return (
    <div className='w-36 h-24 mx-1 rounded-lg border-4 border-[#469746]'>
      <div className='w-full h-8 bg-[#469746] text-white flex items-center justify-center font-bold'>
        내일 7시 출석
      </div>
      <div className='w-full h-14 flex items-center justify-center text-[#469746] text-lg font-bold'>
        {commaNumber(next.value)}
      </div>
    </div>
  )
}

export default DailyCheckInContent
