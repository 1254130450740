import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { useInterval, useSearchParam } from 'react-use'
import AuthStore from '../../stores/AuthStore'

import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import VideoStore from '../../stores/VideoStore'
import backendApis from '../../utils/backendApis'
import Toast from '../atoms/toast'
import VisibilityMark from '../atoms/visibilityMark'
import MoneyInfo from './moneyInfo'

const STYLES = {
  root: {
    width: 50,
    height: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: 100,
  },
  path: {
    stroke: '#FAE194',
    strokeWidth: 6,
    strokeLinecap: 'butt',
    transition: 'stroke-dashoffset 1s linear',
    transform: 'scaleX(-1)',
    transformOrigin: 'center center',
  },
  trail: {
    stroke: 'transparent',
  },
}

const DURATION = 5

const MoneyTimer = observer(() => {
  const moneyInfo = UserStore?.moneyInfo
  const standardTime = UserStore?.moneyStandardTime
  const markAvailable = UserStore?.questMarkAvailable

  if (!moneyInfo) return null

  const token = AuthStore?.token
  const [leftTime, setLeftTime] = useState(DURATION)
  const [toastInfo, setToastInfo] = useState({ open: false })

  const handleFinish = async () => {
    setLeftTime(DURATION)
    const result = await backendApis.money(token, 'POST', { type: 'videoCoin' })
    if (result?.status === 200) {
      const addCoin = result?.data?.addCoin || 0
      const addTomorrowCoin = result?.data?.addTomorrowCoin || 0
      UserStore.setMoneyInfo({
        ...moneyInfo,
        coin: moneyInfo.coin + addCoin,
        tomorrowCoin: (moneyInfo?.tomorrowCoin || 0) + addTomorrowCoin,
      })
      setToastInfo({ open: true, duration: 3000, addCoin })
    }
  }

  useInterval(() => {
    const stopStatus = ['pause', 'loop']
    const current = VideoStore?.status
    if (current && stopStatus.includes(current)) return

    const now = new Date()
    if (new Date(now.getTime() - 1000 * 60) > standardTime) return

    if (leftTime <= 0) {
      setLeftTime(leftTime - 1)
      handleFinish()
      return
    }
    setLeftTime(leftTime - 1)
  }, 1000)

  return (
    <>
      <Toast info={toastInfo} setInfo={setToastInfo} from='money' />
      <VisibilityMark visible={markAvailable} />
      <CircularProgressbarWithChildren
        value={(leftTime / DURATION) * 100}
        styles={STYLES}
      >
        <div
          className='z-20 flex flex-col items-center'
          aria-hidden='true'
          onClick={() => {
            ModalStore.setCurrentBottomSheet({ open: true, type: 'money' })
            const sessionId = AuthStore?.sessionId
            const userId = UserStore?.userInfo?.userId
            const eventName = 'moneyTimer'
            const eventDesc = {
              version: process.env.REACT_APP_VERSION,
            }
            backendApis.eventLog(token, 'POST', {
              userId,
              sessionId,
              eventName,
              eventDesc,
            })
          }}
        >
          <img src='/images/coin.png' alt='coin' className='w-12 h-12' />
          <span className='absolute top-4 text-sm font-bold text-[#8B2D13] drop-shadow-lg'>
            {moneyInfo.coin}
          </span>
          <span className='bg-gradient-to-b from-[#EB7766] to-[#CF3930] px-2 absolute top-10 text-xs rounded-lg text-white'>
            출금
          </span>
        </div>
      </CircularProgressbarWithChildren>
      <MoneyInfo from='moneyTimer' dependency={toastInfo?.open} />
    </>
  )
})

export default MoneyTimer
