import React from 'react'

const Goback = ({ white = false, onClick }) => (
  <div aria-hidden='true' onClick={onClick}>
    <img
      src={white ? '/images/backWhite.png' : '/images/back.png'}
      alt='back'
      className='w-6 h-6'
    />
  </div>
)

export default Goback
