import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../stores/ModalStore'

const TextToasts = observer(() => {
  const isOpen = ModalStore?.currentToast?.open
  const text = ModalStore?.currentToast?.text
  const time = ModalStore?.currentToast?.time

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => ModalStore.setCurrentToast({ open: false }), 1000 * time)
    }
  }, [text, time])

  if (!isOpen) return null

  return (
    <div className='absolute z-40 flex items-center justify-center w-4/5 px-6 py-4 -translate-x-1/2 -translate-y-1/2 bg-black opacity-85 top-1/2 left-1/2 rounded-xl'>
      <div className='text-white'>{text}</div>
    </div>
  )
})

export default TextToasts
