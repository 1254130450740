import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useSearchParam } from 'react-use'

import Loading from '../atoms/loading'
import Text from '../atoms/text'
import Player from '../molecules/player'
import QuestIndicator from '../molecules/questIndicator'
import Carousel from '../templates/carousel'
import VideoStore from '../../stores/VideoStore'
import AuthStore from '../../stores/AuthStore'
import ModalStore from '../../stores/ModalStore'
import backendApis from '../../utils/backendApis'
import { motion } from 'framer-motion'

const Scroll = ({ index }) => (
  <>
    <motion.div
      className='absolute left-0 z-30 w-screen h-screen bg-black'
      style={{ bottom: `${-100 * index}%` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.5 }}
      transition={{
        duration: 0.7,
      }}
    />
    <motion.div
      className='absolute z-40 flex flex-col items-center w-full transform -translate-x-1/2 left-1/2'
      style={{ bottom: `${-100 * index + 5}%` }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.7,
      }}
    >
      <div className='mb-6 text-center'>
        <Text
          text='다른 비디오를 보려면 스크롤을 해보세요'
          size='lg'
          color='white'
          bold
        />
      </div>
      <img
        src='/images/upper.png'
        alt='upper'
        className='w-14 h-14 animate-bounce'
      />
    </motion.div>
  </>
)
const Contents = ({ elem, index, currentIndex }) => {
  if (currentIndex > index + 1 || currentIndex < index - 1) {
    return <div id={index} aria-hidden='true' className='w-screen h-screen' />
  }

  return <Player src={elem} index={index} currentIndex={currentIndex} />
}

const QuestVideos = observer(({ questCount = 10, mainType, type, onEnd }) => {
  const token = useSearchParam('token') || AuthStore?.token
  const [isLoading, setIsLoading] = useState(true)
  const [guideToNextVideo, setGuideToNextVideo] = useState(false)

  // 첫 비디오에서 시간차를 두고 스크롤을 유도한다.
  useEffect(() => {
    if (ModalStore?.currentModal?.open === false) {
      setTimeout(() => {
        setGuideToNextVideo(true)
      }, 2000)
    }
  }, [ModalStore?.currentModal?.open])
  useEffect(() => {
    // Video 가 다음으로 넘어가면 스크롤 가이드를 없앤다.
    if (VideoStore?.currentIndex > 0) {
      setGuideToNextVideo(false)
    }
  }, [VideoStore?.currentIndex])

  useEffect(() => {
    const fetchData = async () => {
      if (
        VideoStore?.videos?.length &&
        VideoStore.videos.length - VideoStore.currentIndex !== 2
      )
        return
      if (!token) return
      const result = await backendApis.onboardingVideos(token, 'GET')
      if (result?.status === 200 && result?.data?.length) {
        VideoStore.setVideos([
          ...result.data.filter(
            (each) =>
              !VideoStore?.videos?.map((elem) => elem?.url).includes(each?.url),
          ),
        ])
      }
      setIsLoading(false)
    }
    fetchData()
  }, [VideoStore?.videos?.length, VideoStore?.currentIndex, token])

  return guideToNextVideo ? (
    <>
      <Carousel>
        {VideoStore?.videos.map((elem, index) => (
          <Contents
            key={(elem?.url || elem) + index.toString()}
            elem={elem?.url}
            index={index}
            currentIndex={VideoStore?.currentIndex || 0}
          />
        ))}
        <Scroll index={0} />
        <Loading isLoading={isLoading} />
      </Carousel>
      <QuestIndicator
        mainType={mainType}
        type={type}
        questCount={questCount}
        onEnd={onEnd}
      />
    </>
  ) : (
    <>
      <Carousel>
        {VideoStore?.videos.map((elem, index) => (
          <Contents
            key={(elem?.url || elem) + index.toString()}
            elem={elem?.url}
            index={index}
            currentIndex={VideoStore?.currentIndex || 0}
          />
        ))}
        <Loading isLoading={isLoading} />
      </Carousel>
      <QuestIndicator
        mainType={mainType}
        type={type}
        questCount={questCount}
        onEnd={onEnd}
      />
    </>
  )
})

export default QuestVideos
