import { makeObservable, observable, action } from 'mobx'

class ModalStore {
  currentBottomSheet = null
  currentModal = null
  currentAlert = null
  currentToast = null
  currentFriendRepostFeed = { open: true, from: '' }

  constructor() {
    makeObservable(this, {
      currentBottomSheet: observable,
      currentModal: observable,
      currentAlert: observable,
      currentToast: observable,
      currentFriendRepostFeed: observable,

      setCurrentBottomSheet: action,
      setCurrentModal: action,
      setCurrentAlert: action,
      setCurrentToast: action,
      setCurrentFriendRepostFeed: action,
    })
  }

  setCurrentFriendRepostFeed(currentFriendRepostFeed) {
    this.currentFriendRepostFeed = currentFriendRepostFeed
  }

  setCurrentBottomSheet(currentBottomSheet) {
    if (this?.currentBottomSheet?.isOpen) return
    this.currentBottomSheet = currentBottomSheet
  }

  setCurrentModal(currentModal) {
    if (this?.currentModal?.isOpen) return
    this.currentModal = currentModal
  }

  setCurrentAlert(currentAlert) {
    this.currentAlert = currentAlert
  }

  setCurrentToast(currentToast) {
    this.currentToast = currentToast
  }
}

export default new ModalStore()
