import { observer } from 'mobx-react-lite'
import React from 'react'
import ModalStore from '../../stores/ModalStore'

const Alerts = observer(() => {
  const isOpen = ModalStore?.currentAlert?.open
  if (!isOpen) return null

  const text = ModalStore?.currentAlert?.text
  const onClick = ModalStore?.currentAlert?.onClick

  const closeModal = () => {
    setTimeout(() => ModalStore.setCurrentAlert({ open: false }), 50)
  }

  return (
    <>
      <div
        className='absolute top-0 left-0 z-30 w-full h-full bg-black opacity-30'
        aria-hidden='true'
        onClick={onClick || closeModal}
      />
      <div className='absolute z-40 w-4/5 px-6 py-6 -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 rounded-xl'>
        <div className='mb-2 text-lg'>알림</div>
        <div>{text}</div>
        <div
          aria-hidden='true'
          className='flex justify-end mt-2 text-blue-600'
          onClick={onClick || closeModal}
        >
          확인
        </div>
      </div>
    </>
  )
})

export default Alerts
