import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import ChannelImage from './channelImage'
import repostVideoStore from '../../stores/repostVideoStore'
import backendApis from '../../utils/backendApis'
import AuthStore from '../../stores/AuthStore'
import { useSearchParam } from 'react-use'

const RepostVideoDescription = observer(({ index }) => {
  if (!repostVideoStore?.videos?.[index]) return null
  // token
  const token = AuthStore?.token || useSearchParam('token')

  const [author, setAuthor] = useState('')
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [title, setTitle] = useState('')

  const onClick = () => {
    // navigate('/channel')
    //! api quota 문제로 임시로 막아둠 (23.09.21)
  }

  const fetchData = async () => {
    const videoId = repostVideoStore?.videos?.[index]?.videoId
    const videoInfoResult = await backendApis.videoInfo(token, 'GET', {
      videoId,
    })
    if (videoInfoResult?.status === 200 && videoInfoResult?.data) {
      const { author, thumbnailUrl, title } = videoInfoResult.data
      setAuthor(author)
      setThumbnailUrl(thumbnailUrl)
      setTitle(title)
    }
  }
  useEffect(() => {
    fetchData()
  }, [index])

  return (
    <div
      className='fixed bottom-20 z-30 w-2/3 left-4'
      // style={{ bottom: `${-100 * index + 2}%` }}
    >
      <div
        className='flex flex-row items-center'
        aria-hidden='true'
        onClick={onClick}
      >
        <ChannelImage thumbnailUrl={thumbnailUrl} />
        <div
          className='ml-2 font-extrabold text-white'
          style={{ fontSize: 13 }}
        >
          @{author}
        </div>
      </div>
      <div className='h-2' />
      <div
        className='font-semibold leading-5 text-white'
        style={{ fontSize: 15 }}
      >
        {title}
      </div>
    </div>
  )
})

export default RepostVideoDescription
