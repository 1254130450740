import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/ko'

dayjs.extend(relativeTime)
dayjs.locale('ko')

class TimeUtils {
  getRelativeTime(date) {
    return dayjs(date).fromNow()
  }
}

export default new TimeUtils()
