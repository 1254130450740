import { makeObservable, observable, action } from 'mobx'

class VideoStore {
  videos = []
  currentIndex = 0
  status = null
  duration = null
  isFocused = false
  channelVideos = []
  channelIndex = 0

  constructor() {
    makeObservable(this, {
      videos: observable,
      currentIndex: observable,
      status: observable,
      duration: observable,
      isFocused: observable,
      channelVideos: observable,
      channelIndex: observable,

      setVideos: action,
      setCurrentIndex: action,
      setStatus: action,
      setDuration: action,
      setIsFocused: action,
      setChannelVideos: action,
      setChannelIndex: action,
    })
  }

  setVideos(videos) {
    this.videos = videos
  }

  setCurrentIndex(currentIndex) {
    this.currentIndex = currentIndex
  }

  setStatus(status) {
    this.status = status
  }

  setDuration(duration) {
    this.duration = duration
  }

  setIsFocused(isFocused) {
    this.isFocused = isFocused
  }

  setChannelVideos(channelVideos) {
    this.channelVideos = channelVideos
  }

  setChannelIndex(channelIndex) {
    this.channelIndex = channelIndex
  }
}

export default new VideoStore()
