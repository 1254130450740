import { observer } from 'mobx-react-lite'
import React, { useState, useRef, useEffect } from 'react'
import YouTube from 'react-youtube'

import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import VideoStore from '../../stores/VideoStore'
import DurationGauge from './durationGauge'
import Interactions from './interactions'
import VideoDescription from './videoDescription'

const opts = {
  playerVars: {
    autoplay: 1,
    controls: 0,
    playsinline: 1,
    webkitplaysinline: 1,
    modestbranding: 1,
    fs: 0,
    quality: 'auto',
    allowfullscreen: 0,
    webkitallowfullscreen: 0,
  },
}

const Player = observer(({ src, index, currentIndex }) => {
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)

  // 스크롤 할때 자동재생
  useEffect(() => {
    UserStore.setMoneyStandardTime(new Date())
    if (!videoRef?.current) return
    if (currentIndex === index - 1) {
      videoRef?.current?.internalPlayer.mute()
      // videoRef?.current?.internalPlayer.seekTo(0)
    } else if (currentIndex === index + 1) {
      videoRef?.current?.internalPlayer.seekTo(0)
      videoRef?.current?.internalPlayer.pauseVideo()
      videoRef?.current?.internalPlayer.mute()
    } else if (currentIndex === index) {
      videoRef?.current?.internalPlayer.seekTo(0)
      videoRef?.current?.internalPlayer.playVideo()
      videoRef?.current?.internalPlayer.unMute()
      VideoStore.setStatus('play')
    }
  }, [currentIndex])

  // 바텀싯, 모달과 호환
  useEffect(() => {
    if (currentIndex !== index) return
    if (ModalStore?.currentBottomSheet?.type === 'comments') {
      videoRef?.current?.internalPlayer.playVideo()
      VideoStore.setStatus('play')
      return
    }
    if (
      ModalStore?.currentBottomSheet?.open ||
      ModalStore?.currentModal?.open ||
      ModalStore?.currentAlert?.open
    ) {
      videoRef?.current?.internalPlayer.pauseVideo()
      VideoStore.setStatus('pause')
    } else {
      videoRef?.current?.internalPlayer.playVideo()
      VideoStore.setStatus('play')
    }
  }, [
    ModalStore?.currentBottomSheet?.open,
    ModalStore?.currentModal?.open,
    ModalStore?.currentAlert?.open,
  ])

  useEffect(() => {
    if (currentIndex !== index) return

    if (VideoStore?.isFocused) {
      UserStore.setMoneyStandardTime(new Date())
      if (
        ModalStore?.currentBottomSheet?.open ||
        ModalStore?.currentModal?.open ||
        ModalStore?.currentAlert?.open
      ) {
        return
      }
      videoRef?.current?.internalPlayer.playVideo()
      videoRef?.current?.internalPlayer.unMute()
      VideoStore.setStatus('play')
    } else {
      videoRef?.current?.internalPlayer.pauseVideo()
      videoRef?.current?.internalPlayer.mute()
      VideoStore.setStatus('pause')
    }
  }, [VideoStore?.isFocused])

  const handlePlayPause = () => {
    if (currentIndex !== index) return
    if (!videoRef?.current) return
    if (playing) {
      videoRef?.current?.internalPlayer.pauseVideo()
      VideoStore.setStatus('pause')
      setPlaying(false)
    } else {
      videoRef?.current?.internalPlayer.playVideo()
      VideoStore.setStatus('play')
      setPlaying(true)
    }
  }

  const onEnd = () => {
    if (currentIndex !== index) return
    if (!videoRef?.current) return
    VideoStore.setStatus('loop')
    videoRef?.current?.internalPlayer.playVideo()
  }

  const onReady = (e) => {
    const state = e.target.getPlayerState()
    if (state !== 3) {
      VideoStore.setVideos(
        VideoStore?.videos?.filter((each) => each?.url !== src),
      )
    }
    if (e.target?.playerInfo?.duration) {
      VideoStore.setDuration({
        ...VideoStore?.duration,
        [index]: e.target.playerInfo.duration,
        [index - 3]: null,
        [index + 3]: null,
      })
    }
  }

  return (
    <div
      id={index}
      aria-hidden={index === currentIndex ? 'false' : 'true'}
      className='z-30 w-screen h-screen'
    >
      <div
        className='absolute w-full h-full bg-transparent'
        aria-hidden='true'
        onClick={handlePlayPause}
      />
      <YouTube
        ref={videoRef}
        className='w-full h-full'
        iframeClassName={currentIndex <= index ? 'w-full h-full' : 'w-2 h-2'}
        videoId={src}
        opts={opts}
        onEnd={onEnd}
        onReady={onReady}
      />
      {currentIndex === index && <DurationGauge currentIndex={currentIndex} />}
      <Interactions index={index} currentIndex={currentIndex} />
      <VideoDescription index={index} />
    </div>
  )
})

export default Player
