import React from 'react'
import { observer } from 'mobx-react-lite'

import ModalStore from '../../stores/ModalStore'
import ExchangeSection from '../molecules/exchangeSection'
import CoinQuests from '../molecules/coinQuests'
import Comments from '../molecules/comments'
import RepostGuide from '../molecules/repost/repostGuide'
import RepostReactorList from '../molecules/repost/repostReactorList'
import RepostViewerList from '../molecules/repost/repostViewerList'
import Teammates from './repost/teammates'
import TeamReaction from './repost/teamReaction'
import CreateTeam from './repost/createTeam'
import RepostTeam from './repost/repostTeam'

const BottomSheetContents = () => {
  const target = ModalStore?.currentBottomSheet
  if (!target?.open) return null

  const { type } = target
  if (!type) return null

  if (type === 'money') {
    return (
      <>
        <ExchangeSection />
        <CoinQuests />
      </>
    )
  }

  if (type === 'comments') {
    const { url, length } = target
    return <Comments url={url} length={length} />
  }

  if (type === 'repostReactors') {
    const { repostId } = target
    return <RepostReactorList repostId={repostId} />
  }

  if (type === 'repostViewers') {
    const { index } = target
    return <RepostViewerList index={index} />
  }

  if (type === 'repostGuide') {
    const { url, videoId } = target
    return <RepostGuide url={url} videoId={videoId} />
  }

  if (type === 'teammates') {
    // const { teamId, videoId } = target
    const teamId = null
    const videoId = null

    return <Teammates />
  }

  if (type === 'teamReaction') {
    const { repostId } = target

    return <TeamReaction repostId={repostId} />
  }

  if (type === 'repostTeam') {
    const { teamList } = target
    if (teamList) {
      return <RepostTeam teamList={teamList} />
    }
  }
  if (type === 'createTeam') {
    return <CreateTeam />
  }

  return null
}

export default observer(BottomSheetContents)
