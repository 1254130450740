import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

export default function useTimer({ questTime, currentIndex, videoStatus }) {
  const [time, setTime] = useState(questTime)
  const [limit, setLimit] = useState(0)

  useEffect(() => {
    setTime(questTime)
  }, [questTime])

  useInterval(() => {
    const stopStatus = ['pause', 'loop']
    if (stopStatus.includes(videoStatus)) return
    if (limit >= 15) return
    if (time <= 0) return

    setTime(time - 1)
    setLimit(limit + 1)
  }, 1000)

  useEffect(() => {
    setLimit(0)
  }, [currentIndex])

  return { time }
}
