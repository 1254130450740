import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import backendApis from '../../../utils/backendApis'
import AuthStore from '../../../stores/AuthStore'
import converter from '../../../utils/converter'
import ModalStore from '../../../stores/ModalStore'

const RepostTeamInviteButton = observer(({ teamId }) => {
  const token = AuthStore?.token

  const onClick = () => {
    ModalStore?.setCurrentBottomSheet({
      open: true,
      type: 'teammates',
    })
  }

  return (
    <div
      aria-hidden='true'
      className='flex flex-col items-center drop-shadow-lg'
      onClick={onClick}
    >
      <img
        src='/images/buttons/teamInviteButton.png'
        alt='teamInviteButton'
        className='mb-1.5 w-7 h-7'
      />
      <div className='text-sm font-bold text-white'>팀초대</div>
    </div>
  )
})

export default RepostTeamInviteButton
