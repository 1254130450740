import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import ModalStore from '../../stores/ModalStore'
import UserStore from '../../stores/UserStore'
import check from '../../utils/check'

const DailyCheckIn = observer(() => {
  const [show, setShow] = useState(true)
  if (!show) return null

  const data = UserStore?.questLog?.dailyCheckInVer2
  if (data?.available === false && !data?.ongoing) return null

  const countTemp = data?.count || 0
  let count = countTemp
  if (data?.available !== false) count = countTemp + 1

  if (count >= 7) return null

  const onClick = () => {
    ModalStore.setCurrentModal({
      open: true,
      type: 'dailyCheckInVer2',
      count,
      available: false,
    })
  }

  const userCodepush = UserStore?.userInfo?.codepushVersion
  const codepushAvailable = check.checkCodepush(userCodepush, '2.2.65')

  if (!codepushAvailable) {
    return (
      <>
        <img
          aria-hidden='true'
          src='/images/close.png'
          alt='close'
          className='absolute w-4 h-4 -right-2 -top-2'
          onClick={() => setShow(false)}
        />
        <div
          aria-hidden='true'
          className='flex flex-col items-center top-20'
          onClick={onClick}
        >
          <span className='absolute text-xs font-bold text-yellow-200'>
            15만
          </span>
          <img src='/images/envelope.png' alt='envelope' className='h-10' />
          <span className='bg-gradient-to-b from-[#EB7766] to-[#CF3930] w-12 absolute top-10 text-xs rounded-lg text-white text-center -mt-2'>
            내일획득
          </span>
        </div>
      </>
    )
  }

  return (
    <>
      <img
        aria-hidden='true'
        src='/images/close.png'
        alt='close'
        className='absolute z-10 w-4 h-4 -right-0.5 top-18'
        onClick={() => setShow(false)}
      />
      <div
        aria-hidden='true'
        className='absolute flex flex-col items-center top-20 left-0.5'
        onClick={onClick}
      >
        <span className='absolute text-xs font-bold text-yellow-200'>15만</span>
        <img src='/images/envelope.png' alt='envelope' className='h-10' />
        <span className='bg-gradient-to-b from-[#EB7766] to-[#CF3930] w-12 absolute top-10 text-xs rounded-lg text-white text-center -mt-2'>
          내일획득
        </span>
      </div>
    </>
  )
})

export default DailyCheckIn
