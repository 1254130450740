const API_URI = process.env.REACT_APP_API_URI

const fetcher = async (url, token, method, params = {}) => {
  const resource =
    method === 'GET' ? `${url}?${new URLSearchParams(params)}` : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init.method = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

let throttle = false
const repostThrottle = false

class BackendApis {
  async repostViewerActivity(token, method, repostId, params = {}) {
    const result = await fetcher(
      `/reposts/${repostId}/viewer-activities`,
      token,
      method,
      params,
    )
    return result
  }

  async repostViewerActivityByUser(token, method, repostId, params = {}) {
    const result = await fetcher(
      `/reposts/${repostId}/viewer-activities/group-by-user`,
      token,
      method,
      params,
    )
    return result
  }

  async repostDetail(token, method, repostId, params = {}) {
    const result = await fetcher(`/reposts/${repostId}`, token, method, params)
    return result
  }

  async repost(token, method = 'POST', params = {}) {
    const result = await fetcher('/reposts', token, method, params)
    return result
  }

  async latestRepost(token, method = 'GET', params = {}) {
    const result = await fetcher('/reposts/latest', token, method, params)
    return result
  }

  async eventLog(token, method = 'POST', params = {}) {
    const result = await fetcher('/users/event-log', token, method, params)
    return result
  }

  async healthCheck(token, method = 'GET', params = {}) {
    const result = await fetcher('/health-check', token, method, params)
    return result
  }

  async sessionId(token, method = 'GET', params = {}) {
    const result = await fetcher('/session-id', token, method, params)
    return result
  }

  async userViewLog(token, method = 'POST', params = {}) {
    const result = await fetcher('/users/view-log', token, method, params)
    return result
  }

  async videos(token, method = 'GET', params = {}) {
    const result = await fetcher('/videos', token, method, params)
    return result
  }

  async onboardingVideos(token, method = 'GET', params = {}) {
    const result = await fetcher('/videos/onboarding', token, method, params)
    return result
  }

  async userInfo(token, method = 'GET', params = {}, id = null) {
    if (id) {
      const result = await fetcher(`/users/${id}`, token, method, params)
      return result
    }
    const result = await fetcher('/users', token, method, params)
    return result
  }

  async videoInfo(token, method = 'GET', params = {}) {
    const { videoId } = params
    const result = await fetcher(
      `/videos/info/${videoId}`,
      token,
      method,
      params,
    )
    return result
  }

  async money(token, method = 'GET', params = {}) {
    if (method === 'POST') {
      if (!params?.type || throttle === params.type) return null
      throttle = params?.type
      setTimeout(() => {
        throttle = null
      }, 500)
    }
    const result = await fetcher('/users/money', token, method, params)
    return result
  }

  async quest(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/quest', token, method, params)
    return result
  }

  async bankList(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/bank-list', token, method, params)
    return result
  }

  async withdraw(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/withdraw', token, method, params)
    return result
  }

  async comment(token, method = 'GET', params = {}) {
    const result = await fetcher('/videos/comment', token, method, params)
    return result
  }

  async dailyInvite(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/daily-invite', token, method, params)
    return result
  }

  async inviteGold(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/invite-gold', token, method, params)
    return result
  }

  async like(token, method = 'POST', params = {}) {
    const result = await fetcher('/users/like', token, method, params)
    return result
  }

  async appPush(token, method = 'POST', params = {}) {
    const result = await fetcher('/users/app-push', token, method, params)
    return result
  }

  async teamList(token, method = 'GET', params = {}) {
    const result = await fetcher('/reposts/team/list', token, method, params)
    return result
  }

  // 친구 초대를 받았을 때, 친구 초대를 보낸 사람 검증용으로 사용됨
  async followee(token, method = 'GET', params = {}, id = null) {
    if (id) {
      const result = await fetcher(
        `/reposts/followees/${id}`,
        token,
        method,
        params,
      )
      return result
    }
    const result = await fetcher('/reposts/followees', token, method, params)
    return result
  }

  async followReco(token, method = 'GET', params = {}) {
    const result = await fetcher('/reposts/follow-reco', token, method, params)
    return result
  }

  async inviteFollowees(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/reposts/invite-followees',
      token,
      method,
      params,
    )
    return result
  }

  async ignoreFollowee(token, method = 'POST', params = {}) {
    const result = await fetcher(
      '/reposts/ignore-followee',
      token,
      method,
      params,
    )
    return result
  }

  async repostGuide(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/repost-guide', token, method, params)
    return result
  }

  async repostViewLog(token, method = 'POST', params = {}) {
    if (method === 'POST') {
      if (
        !params?.repostId ||
        throttle === `${params?.repostId}-${params?.userId}`
      )
        return null
      throttle = `${params?.repostId}-${params?.userId}`
      setTimeout(() => {
        throttle = null
      }, 500)
      const result = await fetcher('/reposts/view-log', token, method, params)
      return result
    }
    const repostId = params?.repostId
    const result = await fetcher(
      `/reposts/view-log/${repostId}`,
      token,
      method,
      params,
    )
    return result
  }

  async repostTeamCreate(token, method = 'POST', params = {}) {
    const result = await fetcher('/reposts/team/create', token, method, params)
    return result
  }

  async repostTeamInfo(token, method = 'GET', params = {}) {
    const { teamId } = params
    const result = await fetcher(
      `/reposts/team/info/${teamId}`,
      token,
      method,
      params,
    )
    return result
  }

  async repostTeamVideo(token, method = 'GET', params = {}) {
    if (method === 'GET') {
      const { teamId } = params
      const result = await fetcher(
        `/reposts/team/video/${teamId}`,
        token,
        method,
        params,
      )
      return result
    }
    if (method === 'POST') {
      const result = await fetcher(`/reposts/team/video`, token, method, params)
      return result
    }
  }

  async repostTeamJoin(token, method = 'POST', params = {}) {
    const result = await fetcher(`/reposts/team/join`, token, method, params)
    return result
  }

  async contacts(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/contacts', token, method, params)
    return result
  }

  async reward(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/reward', token, method, params)
    return result
  }

  async fullGuide(token, method = 'GET', params = {}) {
    const result = await fetcher('/users/full-guide', token, method, params)
    return result
  }
}

export default new BackendApis()
