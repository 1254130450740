import React from 'react'

const MoneyGauge = ({ percentage }) => (
  <div className='flex flex-row items-center w-5/6 h-5 bg-red-100 rounded-full'>
    <div
      style={{ width: `${percentage * 100}%` }}
      className='h-5 bg-red-500 rounded-full '
    />
    <div
      className={`absolute flex justify-center w-5/6 text-sm ${
        percentage > 0.5 ? 'text-white' : 'text-black'
      }`}
    >
      {Math.round(percentage * 10000) / 100}%
    </div>
    <div
      className='absolute flex items-center justify-end w-5/6'
      style={{ right: '8%' }}
    >
      <img
        src='/images/moneybag.png'
        alt='moneybag'
        className='w-10 h-10 bg-white border-4 border-red-500 rounded-full p-0.5'
      />
    </div>
  </div>
)

export default MoneyGauge
