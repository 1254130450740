import React from 'react'
import { observer } from 'mobx-react-lite'

import VideoStore from '../../stores/VideoStore'
import ChannelImage from './channelImage'
import { useNavigate } from 'react-router-dom'

const VideoDescription = observer(({ index }) => {
  if (!VideoStore?.videos?.[index]) return null
  const navigate = useNavigate()

  const { author, thumbnailUrl, title } = VideoStore.videos[index]

  const onClick = () => {
    // navigate('/channel')
    //! api quota 문제로 임시로 막아둠 (23.09.21)
  }

  return (
    <div
      className='fixed z-30 w-2/3 left-4'
      style={{ bottom: `${-100 * index + 2}%` }}
    >
      <div
        className='flex flex-row items-center'
        aria-hidden='true'
        onClick={onClick}
      >
        <ChannelImage thumbnailUrl={thumbnailUrl} />
        <div
          className='ml-2 font-extrabold text-white'
          style={{ fontSize: 13 }}
        >
          @{author}
        </div>
      </div>
      <div className='h-2' />
      <div
        className='font-semibold leading-5 text-white'
        style={{ fontSize: 15 }}
      >
        {title}
      </div>
    </div>
  )
})

export default VideoDescription
