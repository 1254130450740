import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import GoldCards from '../components/molecules/goldCards'
import SelectedCard from '../components/molecules/selectedCard'
import QuestVideos from '../components/organisms/questVideos'
import QuestWithdraw from '../components/organisms/questWithdraw'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import VideoStore from '../stores/VideoStore'
import backendApis from '../utils/backendApis'

const OnboardingContents = observer(({ index, next }) => {
  const token = AuthStore?.token
  const sessionId = AuthStore?.sessionId
  const userId = UserStore?.userInfo?.userId
  const eventName = 'startOnboarding'
  const eventDesc = {
    index,
    version: process.env.REACT_APP_VERSION,
  }

  if (VideoStore?.isFocused) {
    backendApis.eventLog(token, 'POST', {
      userId,
      sessionId,
      eventName,
      eventDesc,
    })
  }

  if (index === 0) {
    // 카드 선택하는 화면
    return <GoldCards next={next} />
  }
  if (index === 1) {
    return <SelectedCard next={next} />
  }
  if (index === 2) {
    const onEnd = async () => {
      const result = await backendApis.money(token, 'POST', {
        type: 'onboarding',
      })
      if (result?.status === 200) {
        UserStore?.setMoneyInfo({
          ...UserStore?.moneyInfo,
          coin: (UserStore?.moneyInfo?.coin || 0) + 50000,
        })
        next()
      }
    }
    return (
      <QuestVideos
        questCount={5}
        mainType='count'
        type='onboarding'
        onEnd={onEnd}
      />
    )
  }
  if (index === 3) {
    return <QuestWithdraw />
  }
})

const OnboardingScreen = observer(() => {
  const [index, setIndex] = useState(0)

  const next = () => {
    setIndex((prev) => prev + 1)
  }

  return (
    <div className='w-screen h-screen bg-black'>
      <OnboardingContents index={index} next={next} />
    </div>
  )
})

export default OnboardingScreen
