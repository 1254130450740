import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import RepostVideoStore from '../../stores/repostVideoStore'

import UserStore from '../../stores/UserStore'
import ModalStore from '../../stores/ModalStore'
import RepostVideoSettingButton from './repost/repostVideoSettingButton'
import RepostTeamInviteButton from './repost/repostTeamInviteButton'
import RepostTeammatesReactionButton from './repost/repostTeammatesReactionButton'

const RepostPlayerInteractions = observer(({ index, currentIndex }) => {
  const url = RepostVideoStore?.videos[currentIndex].videoId
  // Guide trigger 가능
  return (
    <div
      className='fixed z-30 right-4'
      style={{ bottom: `${-100 * index + 5}%` }}
    >
      <RepostTeamInviteButton teamId='asd' />
      <div className='h-6' />
      <RepostTeammatesReactionButton teamId='asd' url={url} />
      <div className='h-4' />
      {/* TODO: 삭제 만들기 */}
      {RepostVideoStore?.videos[currentIndex].userId ===
      UserStore?.userInfo?.userId ? (
        <RepostVideoSettingButton teamId='asd' url={url} />
      ) : (
        <div className='h-16' />
      )}
      <div className='h-6' />
      <div className='h-10' />
    </div>
  )
})

export default RepostPlayerInteractions
