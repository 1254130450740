import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import VideoStore from '../../stores/VideoStore'

const DurationGauge = observer(({ currentIndex }) => {
  const [time, setTime] = useState(0)
  const videoStatus = VideoStore?.status
  const currentDuration = VideoStore?.duration?.[currentIndex]

  useInterval(() => {
    if (videoStatus === 'pause') return

    if (time > currentDuration) {
      return
    }
    setTime(time + 0.1)
  }, 100)

  useEffect(() => {
    if (videoStatus === 'loop') setTime(0)
  }, [videoStatus])

  useEffect(() => {
    setTime(0)
  }, [currentIndex])

  const gauge = `${(time / currentDuration) * 100}%`

  return (
    <div
      className='absolute z-30 bg-red-500 h-0.5'
      style={{ width: gauge, bottom: `${-100 * currentIndex}%` }}
    />
  )
})

export default DurationGauge
