import './App.css'
import { useEffect } from 'react'
import { useSearchParam } from 'react-use'

import StackNavigator from './components/templates/stackNavigator'
import initialize from './utils/initialize'
import appBridge from './utils/appBridge'

const App = () => {
  const token = useSearchParam('token')

  useEffect(() => {
    initialize.session(token)
  }, [token])

  useEffect(() => {
    appBridge(token)
  }, [])

  return <StackNavigator />
}

export default App
