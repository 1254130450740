import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import { observer } from 'mobx-react-lite'

import Header from '../components/molecules/header'
import backendApis from '../utils/backendApis'
import ModalStore from '../stores/ModalStore'
import AuthStore from '../stores/AuthStore'
import useThrottle from '../hooks/useThrottle'
import withdraw from '../utils/withdraw'

const Banks = ({ bankList, accountInfo, setAccountInfo }) => (
  <div className='mx-6'>
    <div className='mx-1'>은행을 선택해 주세요.</div>
    <select
      className='w-full h-12 border-2 border-[#F7F7F7] rounded-md text-md my-3'
      placeholder='  기타 은행'
      value={accountInfo?.bankCode || ''}
      onChange={(e) => {
        const correspondingBankInfo = bankList.find(
          (bankInfo) => bankInfo.bankCode === e.target.value,
        )
        if (correspondingBankInfo) {
          setAccountInfo({
            bankCode: correspondingBankInfo.bankCode,
            bankName: correspondingBankInfo.bankName,
          })
        } else {
          setAccountInfo({ ...accountInfo, bankCode: null, bankName: null })
        }
      }}
    >
      <option value=''>입금 은행</option>
      {bankList.map((bankInfo) => (
        <option key={bankInfo?.bankCode} value={bankInfo?.bankCode}>
          {bankInfo?.bankName}
        </option>
      ))}
    </select>
  </div>
)

const Account = ({ accountInfo, setAccountInfo }) => (
  <div className='mx-6'>
    <div className='mx-1 mb-2'>계좌번호를 입력해 주세요. (- 제외)</div>
    <input
      type='number'
      pattern='[0-9]*'
      id='account_number'
      className='bg-[#F7F7F7] border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5 focus:ring-blue-500 focus:border-blue-500'
      placeholder='00000000'
      required
      onChange={(e) =>
        setAccountInfo({ ...accountInfo, account: e.target.value })
      }
    />
  </div>
)

const Name = ({ accountInfo, setAccountInfo }) => (
  <div className='mx-6'>
    <div className='mx-1 mb-2'>예금주명을 입력해 주세요.</div>
    <input
      type='text'
      id='account_name'
      className='bg-[#F7F7F7] border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5 focus:ring-blue-500 focus:border-blue-500'
      placeholder='홍길동'
      required
      onChange={(e) =>
        setAccountInfo({ ...accountInfo, receiver: e.target.value })
      }
    />
  </div>
)

const onClickButton = async (
  token,
  accountInfo,
  setAccountInfo,
  setIsLoading,
) => {
  const { bankCode, bankName, account, receiver } = accountInfo

  if (!bankCode) {
    setIsLoading(false)
    ModalStore.setCurrentModal({
      open: true,
      type: 'lackWithdrawInfo',
      text: '은행을 선택',
    })
    return
  }
  if (!account) {
    setIsLoading(false)
    ModalStore.setCurrentModal({
      open: true,
      type: 'lackWithdrawInfo',
      text: '계좌를 입력',
    })
    return
  }
  if (!receiver) {
    setIsLoading(false)
    ModalStore.setCurrentModal({
      open: true,
      type: 'lackWithdrawInfo',
      text: '예금주명을 입력',
    })
    return
  }

  await withdraw(token, accountInfo, setAccountInfo)
  setIsLoading(false)
}

const WithdrawScreen = observer(() => {
  const navigate = useNavigate()
  const throttle = useThrottle()
  const token = AuthStore.token

  const [isLoading, setIsLoading] = useState(true)
  const [bankList, setBankList] = useState([])
  const [accountInfo, setAccountInfo] = useState({
    bankCode: null,
    bankName: null,
    account: null,
    receiver: null,
  })

  const callback = {
    goback: () => {
      navigate(-1)
    },
    info: () => {
      ModalStore.setCurrentModal({ open: true, type: 'notice' })
    },
  }

  useEffect(() => {
    const initializeBankList = async () => {
      const result = await backendApis.bankList(token, 'GET')
      if (result?.status === 200) {
        setBankList(result?.data)
        setIsLoading(false)
      }
    }
    initializeBankList()
  }, [])

  return (
    <div className='overflow-y-scroll'>
      {!isLoading ? (
        <>
          <Header callback={callback} goback title='출금하기' info='유의사항' />
          <div className='h-24' />
          <div className='w-full text-center text-2xl text-[#A45B54] font-bold'>
            <span className='text-lg'>출금금액: </span>3,000원
          </div>
          <div className='h-12' />
          <Banks
            bankList={bankList}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <div className='h-4' />
          <Account accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
          <div className='h-8' />
          <Name accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
          <div className='h-24' />
          <div className='fixed w-11/12 h-12 bottom-4 left-[50%] -translate-x-[50%]'>
            <div
              aria-hidden='true'
              onClick={() => {
                setIsLoading(true)
                throttle(
                  onClickButton(
                    token,
                    accountInfo,
                    setAccountInfo,
                    setIsLoading,
                  ),
                  500,
                )
              }}
              className='flex items-center justify-center w-full h-full text-xl font-bold text-white bg-red-500 rounded-lg'
            >
              출금하기
            </div>
          </div>
        </>
      ) : (
        <div className='w-screen h-screen fixed flex items-center justify-center top-0 bottom-0 bg-[#EEEEEE]'>
          <Oval
            color='#EA3F49'
            secondaryColor='#212121'
            height='40'
            width='40'
          />
        </div>
      )}
    </div>
  )
})

export default WithdrawScreen
