const emojiList = [
  {
    emoji: '😍',
    emojiName: 'heart',
  },
  {
    emoji: '🤣',
    emojiName: 'fun',
  },
  {
    emoji: '😢',
    emojiName: 'sad',
  },
  {
    emoji: '🤬',
    emojiName: 'angry',
  },
]

// TODO: 더 쌓일 예정
export default emojiList
