import { observer } from 'mobx-react-lite'
import AuthStore from '../../../stores/AuthStore'
import React, { useEffect } from 'react'
import RepostVideoStore from '../../../stores/repostVideoStore'
import backendApis from '../../../utils/backendApis'
import emojiList from '../../../utils/constants'
import { Oval } from 'react-loader-spinner'

const Profile = ({ name, img, userId, userEmoji, isWatched }) => (
  <div className='relative flex flex-col justify-center items-center bg-main-color-light rounded-lg w-[100%] asperct-[4/5]'>
    <div className='flex flex-col justify-center items-center'>
      <img
        src={img}
        alt='user_profile'
        className='w-[40%] aspect-square rounded-full mt-4'
      />
      <div className='font-bold mt-2'>{name}</div>
    </div>
    <Reactions userId={userId} userEmoji={userEmoji} />
    {!isWatched && (
      <div className='absolute w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-50 rounded-lg'>
        <p className='absolute bottom-4 text-center text-white font-bold text-lg'>
          아직 영상을
          <br />
          안봤어요!
        </p>
      </div>
    )}
  </div>
)
const Invite = ({ img, isLoading, onClick }) => (
  <div
    role='none'
    className='flex flex-col justify-center items-center border-2 border-main-color-light rounded-lg w-[100%] asperct-[4/5]'
    onClick={onClick}
  >
    <div className='w-[30%] aspect-square rounded-full mt-4'>
      {isLoading ? (
        <Oval
          color='#268BED'
          strokeWidth='8'
          secondaryColor='#b9defe'
          height='100%'
          width='100%'
        />
      ) : (
        <img
          src={img}
          alt='team_invite'
          // className='w-[30%] aspect-square rounded-full mt-4'
        />
      )}
    </div>
    <div className='font-bold text-main-color mt-3 mb-6'>팀원 초대</div>
  </div>
)

const Reactions = ({ userId, userEmoji }) => (
  <div className='grid grid-cols-2 gap-x-3 gap-y-2 w-full justify-center items-center px-4 py-3'>
    {emojiList.map((emojiInfo) => {
      const { emoji } = emojiInfo
      return (
        <div
          className='flex flex-row justify-center items-center bg-white rounded-lg'
          key={`${userId} ${emoji}`}
        >
          <div className='w-6 h-6 flex flex-row justify-center items-center text-xl'>
            {emoji}
          </div>
          <span className='text-sm ml-2'>{userEmoji?.[emoji] ?? 0}</span>
        </div>
      )
    })}
  </div>
)

const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object

const TeamReaction = observer(({ repostId }) => {
  const token = AuthStore?.token

  const [isLoading, setIsLoading] = React.useState(false)

  const onClickInviteTeam = () => {
    setIsLoading(true)
    window.location.href = `#shareLink.${JSON.stringify({
      shareType: 'InviteTeamLink',
      teamId: RepostVideoStore?.teamInfo?._id,
      title: '[올웨이즈] 팀과 함께 재밌는 영상 같이 보고, 돈도 벌어요!',
      description:
        '재밌는 영상을 보면서 현금도 모아보세요. 다 모으면 바로 출금 가능해요.',
      imageUrl:
        'https://assets.ilevit.com/67371a43-5877-4222-9db2-4e6a3f43708b.png',
    })}`
    setTimeout(() => setIsLoading(false), 1500)
  }

  const [usersReaction, setUsersReaction] = React.useState({})
  const [usersViewLog, setUsersViewLog] = React.useState({})
  const fetchData = async () => {
    if (!token) return
    const getActivityResult = await backendApis.repostViewerActivityByUser(
      token,
      'GET',
      repostId,
      {},
    )
    if (getActivityResult?.status === 200) {
      // convert data
      const usersReactionObject = getActivityResult.data.reduce((acc, curr) => {
        const { viewerId, reactedEmoji } = curr._id

        if (!acc[viewerId]) {
          acc[viewerId] = {}
        }
        acc[viewerId][reactedEmoji] = curr.count

        return acc
      }, {})
      setUsersReaction(usersReactionObject)
    }
    const getViewLogResult = await backendApis.repostViewLog(token, 'GET', {
      repostId,
    })
    if (getViewLogResult?.status === 200) {
      const usersViewLogObject = getViewLogResult.data.reduce((acc, item) => {
        const key = item.userId
        acc[key] = (acc[key] || 0) + 1
        return acc
      }, {})
      setUsersViewLog(usersViewLogObject)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='w-full flex flex-col justify-center items-center pt-2 pb-9 px-8'>
      <div className='w-full flex flex-col items-center'>
        <p className='font-bold text-xl'>
          {RepostVideoStore?.teamInfo?.teamName}
        </p>
        {/* <p>영상을 같이 볼 팀원을 초대해보세요!</p> */}
      </div>
      <div className='w-full grid grid-cols-2 gap-4 mt-4 pb-6'>
        {RepostVideoStore?.teamInfo?.members.map((member) => (
          <Profile
            name={member.userName}
            img={member.userImageUri}
            key={member.userId}
            userId={member.userId}
            userEmoji={usersReaction[member.userId]}
            isWatched={
              isEmptyObject(usersViewLog)
                ? true
                : usersViewLog[member.userId] > 0
            }
          />
        ))}
        <Invite
          img='/images/plusBlue.png'
          isLoading={isLoading}
          onClick={onClickInviteTeam}
        />
      </div>
    </div>
  )
})

export default TeamReaction
