import React from 'react'

const ScalingCard = ({ text, onClick }) => (
  <div
    aria-hidden='true'
    className='flex flex-col items-center justify-center w-full card-wrapper'
    onClick={onClick}
  >
    <div className='flex items-center justify-center w-full scale-card'>
      <div className='w-3/4 h-30 bg-gradient-to-b from-[#F55329] to-[#F69E25] p-5 rounded-lg mx-2 flex items-center justify-center'>
        <img
          src='/images/questionMark.png'
          alt='questionMark'
          className='w-12 h-12'
        />
      </div>
    </div>
    <div className='text-white font-bold mt-5 w-3/4 py-2 rounded-full bg-gradient-to-r from-[#F5003B] to-[#F5832E] flex items-center justify-center'>
      {text}
    </div>
  </div>
)

export default ScalingCard
