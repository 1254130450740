import { observer } from 'mobx-react-lite'
import React from 'react'
import { Oval } from 'react-loader-spinner'

import useDailyInvite from '../../hooks/useDailyInvite'
import AuthStore from '../../stores/AuthStore'

const InviteComponent = ({ each, onClick }) => {
  if (!each) {
    return (
      <div
        aria-hidden='true'
        className='flex items-center justify-center w-12 h-12 bg-[#F9D9B3] rounded-full'
        onClick={onClick}
      >
        <img src='/images/plus.png' alt='plus' className='w-6 h-6' />
      </div>
    )
  }
  const { userImageUri, coin } = each
  return (
    <div className='flex flex-col items-center w-1/4'>
      <img
        src={
          userImageUri ||
          'http://k.kakaocdn.net/dn/dpk9l1/btqmGhA2lKL/Oz0wDuJn1YV2DIn92f6DVK/img_110x110.jpg'
        }
        alt='userImageUri'
        className='w-12 h-12 mb-1 rounded-full'
      />
      <div className='flex flex-row items-center text-sm'>
        <img
          src='/images/smallCoin.png'
          alt='smallCoin'
          className='w-3 h-3 mr-0.5'
        />
        {coin}
      </div>
    </div>
  )
}

const InviteList = ({ data, onClick }) => (
  <div className='flex flex-row justify-between w-3/4'>
    <InviteComponent each={data?.[0] || null} onClick={onClick} />
    <InviteComponent each={data?.[1] || null} onClick={onClick} />
    <InviteComponent each={data?.[2] || null} onClick={onClick} />
  </div>
)

const DailyInviteContent = observer(() => {
  const token = AuthStore?.token
  const { data, isLoading, setIsLoading } = useDailyInvite({ token })

  const onClick = () => {
    setIsLoading(true)
    window.location.href = `#shareLink.${JSON.stringify({
      shareType: 'shortsDailyInvite',
      title: '[올웨이즈] 재밌는 영상 보고, 돈도 벌자!',
      description:
        '재밌는 영상을 보면서 현금을 모아보세요. 다 모으면 바로 출금 가능해요.',
      imageUrl:
        'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/08ceafe0-ccf8-4069-94f3-877aebfd2b4a.png',
    })}`
    setTimeout(() => setIsLoading(false), 1500)
  }

  return (
    <div className='flex flex-col items-center justify-center w-11/12 h-72 bg-gradient-to-b from-[#FDF1D9] to-[#F5BE5D] rounded-xl'>
      <span className='mb-2 text-lg font-bold'>친구 초대</span>
      <div className='flex flex-col items-center justify-between w-11/12 h-56 py-3 text-center bg-white rounded-xl'>
        {isLoading ? (
          <Oval
            color='#EA3F49'
            strokeWidth='8'
            secondaryColor='pink'
            height='22'
            width='22'
          />
        ) : (
          <>
            <div>
              친구 초대 1명 당{' '}
              <span className='font-bold text-[#CF3930]'>최대 5,000골드</span>
              <br />
              비디오 안 본 친구 초대하면{' '}
              <span className='font-bold text-[#CF3930]'>50,000골드</span>
            </div>
            <InviteList data={data} onClick={onClick} />
            {data?.length < 3 ? (
              <div
                className='w-11/12 py-2 bg-gradient-to-b from-[#EC7058] to-[#C33827] rounded-full text-white text-center font-bold text-lg'
                aria-hidden='true'
                onClick={onClick}
              >
                친구 초대하기
              </div>
            ) : (
              <div className='w-11/12 py-2 text-lg font-bold text-center text-white rounded-full bg-gradient-to-b from-gray-300 to-gray-500'>
                내일 다시 가능해요
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
})

export default DailyInviteContent
