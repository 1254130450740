import React, { useEffect, useState } from 'react'
import Layout from '../components/templates/layout'
import Videos from '../components/organisms/videos'
import Services from '../components/organisms/services'
import UserStore from '../stores/UserStore'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import initialize from '../utils/initialize'
import AuthStore from '../stores/AuthStore'
import ModalStore from '../stores/ModalStore'
import backendApis from '../utils/backendApis'

const AppMain = observer(() => {
  // initialLandingPage 가 있는 유저는 그곳으로 랜딩시킨다.
  const initialLandingPage = UserStore?.initialLandingPage
  const token = AuthStore?.token
  const navigate = useNavigate()

  useEffect(() => {
    // Initialize necessary Store
    initialize.appMain(token)
  }, [token])

  // useEffect(() => {
  //   if (initialLandingPage?.pathname) {
  //     const queryParams = new URLSearchParams(
  //       initialLandingPage.queryObj,
  //     ).toString()
  //     navigate(`${initialLandingPage.pathname}?${queryParams}`)
  //   }
  // }, [initialLandingPage?.pathname])

  useEffect(() => {
    const handleRewardedAdMission = async () => {
      if (UserStore.isWatchingAd === true) {
        const moneyResult = await backendApis.money(token, 'GET')
        const questResult = await backendApis.quest(token, 'GET')
        if (moneyResult?.status === 200) {
          UserStore.setMoneyInfo(moneyResult?.data)
        }
        if (questResult?.status === 200) {
          const quests = questResult?.data
          UserStore.setQuestLog(quests)
          ModalStore.setCurrentModal({
            open: true,
            type: 'quest',
            coin: 5000,
          })
          UserStore.setIsWatchingAd(false)
        }
      }
    }
    setTimeout(() => handleRewardedAdMission(), 1400)
  }, [UserStore.isWatchingAd])

  return (
    <Layout>
      {/* <Header /> */}
      <Services />
      <Videos />
    </Layout>
  )
})

export default AppMain
