import { observer } from 'mobx-react-lite'
import React from 'react'

import UserStore from '../../stores/UserStore'
import QuestCoin from '../molecules/questCoin'
import TomorrowCoin from '../molecules/tomorrowCoin'

const ExtraCoin = observer(() => {
  const quest = UserStore?.ongoingQuest

  if (!quest || !quest?.type) return <TomorrowCoin />

  return <QuestCoin />
})

export default ExtraCoin
