import React, { useEffect, useState } from 'react'

const apiKey = 'AIzaSyASYA0VIsHA5dMlAAlVj_O41QBSctgkeVs'

export default function useStatistics({ src }) {
  const [data, setData] = useState({})

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${src}&key=${apiKey}`,
    )
      .then((res) => res.json())
      .then((res) => {
        const likeCount = res.items[0].statistics?.likeCount || 0
        const commentCount = res.items[0].statistics?.commentCount || 0
        setData({ likeCount, commentCount })
      })
  }, [src])

  return { ...data }
}
