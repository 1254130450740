import { makeObservable, observable, action, autorun } from 'mobx'
import check from '../utils/check'

class AuthStore {
  token = null
  sessionId = null

  constructor() {
    makeObservable(this, {
      token: observable,
      sessionId: observable,

      setToken: action,
      setSessionId: action,
    })
  }

  setToken(token) {
    this.token = token
  }

  setSessionId(sessionId) {
    this.sessionId = sessionId
  }
}

export default new AuthStore()
