import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import UserStore from '../stores/UserStore'
import Header from '../components/molecules/header'
import BackgroundNumber from '../components/atoms/backgroundNumber'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import ModalStore from '../stores/ModalStore'

const counts = [
  { video: 5, coin: 2000 },
  { video: 7, coin: 3000 },
  { video: 10, coin: 4000 },
  { video: 15, coin: 5000 },
  { video: 30, coin: 6000 },
]

const NightProgress = ({ coin, index, count }) => {
  const done = index < count

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='flex flex-row items-center'>
        <div
          className={`px-1 py-0.5 ${
            done ? 'bg-[#FFD2BB] text-[#EF9364]' : 'bg-[#E05745] text-white'
          } rounded-full  text-sm font-bold flex flex-row items-center justify-center`}
        >
          {coin}
          <img
            src='/images/smallCoin.png'
            alt='smallCoin'
            className='w-3 h-3 ml-0.5'
          />
        </div>
        {index < counts.length - 1 && (
          <div
            className={`w-3 h-1 ${done ? 'bg-[#FFD2BB]' : 'bg-[#E05745]'}`}
          />
        )}
      </div>
      <div className='text-xs text-[#A45B54] mr-2 mt-2'>
        {done ? '완료' : '시작 전'}
      </div>
    </div>
  )
}

const NightQuestProgress = ({ count }) => (
  <div className='w-full px-2 py-8 bg-gradient-to-r from-[#FDF9F0] to-white rounded-lg flex flex-col items-center'>
    <div className='text-[#A45B54] text-sm'>
      자기 전에 영상 보고, 골드 받아가세요
    </div>
    <div className='flex flex-row mt-4'>
      {counts.map((each, index) => (
        <NightProgress coin={each.coin} index={index} count={count} />
      ))}
    </div>
  </div>
)

const NightQuestButton = ({ available, token, count, navigate }) => {
  if (!available || counts.length <= count) {
    return (
      <div
        aria-hidden='true'
        className='w-5/6 py-3 text-xl font-bold text-center text-white rounded-full bg-gradient-to-b from-gray-200 to-gray-500'
        onClick={() => {
          navigate('/')
        }}
      >
        오후 7시에 시작할 수 있어요
      </div>
    )
  }
  const target = counts.find((each, index) => index === count)
  const coin = target?.coin
  const video = target?.video

  return (
    <div
      aria-hidden='true'
      className='w-full py-3 bg-gradient-to-b from-[#EC7058] to-[#C33827] text-white text-xl font-bold rounded-full text-center'
      onClick={() => {
        UserStore.setOngoingQuest({
          questCount: video,
          type: 'nightCoin',
          detail: {
            type: 'nightCoin',
            coin,
          },
        })
        navigate('/')
      }}
    >
      영상 {video}초 보고, {coin} 골드 얻기
    </div>
  )
}

const NightCoinScreen = observer(() => {
  const navigate = useNavigate()
  const token = AuthStore.token
  const available = UserStore?.questLog?.nightCoin?.available
  const count = UserStore?.questLog?.nightCoin?.count || 0
  const nightCoin = counts
    .filter((each, index) => index < count)
    .map((each) => each.coin)
    .reduce((a, b) => a + b, 0)

  const callback = {
    goback: () => {
      navigate('/')
    },
  }

  return (
    <div className='w-screen h-screen bg-gradient-to-b from-[#E8806E] to-[#FCEFE2]'>
      <Header callback={callback} goback white />
      <div className='flex flex-col items-center justify-center pt-32 text-xl font-extrabold text-white'>
        자기 전에 골드를 받아요
      </div>
      <div className='flex flex-row items-baseline justify-center w-full mt-8'>
        <BackgroundNumber
          num={nightCoin}
          textColor='#E25A3C'
          backgroundColor='#FDF4EA'
          textSize='3xl'
        />
        <span className='ml-2 text-lg font-bold text-white'>골드</span>
      </div>
      <div className='flex items-center justify-center w-full px-4 mt-8'>
        <NightQuestProgress count={count} />
      </div>
      <div className='flex items-center justify-center w-full px-4 mt-8'>
        <NightQuestButton
          token={token}
          available={available}
          count={count}
          navigate={navigate}
        />
      </div>
      <div className='pt-6 text-[#A45B54] text-sm w-full flex flex-col items-center'>
        <div>* 모으신 골드는 매일 초기화돼요</div>
        <div>* 5단계를 다 채워야 골드를 받아요</div>
        <div>* 매일 밤 7~12시에만 참여 가능해요</div>
      </div>
    </div>
  )
})

export default NightCoinScreen
