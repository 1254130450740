import React from 'react'

const PushExample = ({ title, desc }) => (
  <div className='flex flex-row w-full p-2 bg-white rounded-xl opacity-80 border-[#A45B54] border-2'>
    <div className='flex items-center mr-2'>
      <img src='/images/alwayz.png' alt='alwayz' className='w-7 h-7' />
    </div>
    <div className='text-sm text-black'>
      <div className='font-bold'>{title}</div>
      <div>{desc}</div>
    </div>
  </div>
)

export default PushExample
