import RepostEmoticonReaction from '../../molecules/repost/repostEmoticonReaction'
import RepostVideoStore from '../../../stores/repostVideoStore'
import ModalStore from '../../../stores/ModalStore'
import { observer } from 'mobx-react-lite'
import emojiList from '../../../utils/constants'

const RepostComments = observer(
  ({ reposterId, viewerId, currentVideoIndex }) => {
    if (!RepostVideoStore?.videos?.[currentVideoIndex]) return null

    // TODO : currentIndex 를 받도록 수정
    const currentRepostId = RepostVideoStore?.videos[currentVideoIndex]._id
    const currentRepostVideoId =
      RepostVideoStore?.videos[currentVideoIndex].videoId
    // TODO: Repost Video 는 우선 1번에 공유한 비디오를 가져온다. 추후 Pagination 추가

    const onEmojiClick = async (emoji, emojiName) => {
      setTimeout(() => {
        ModalStore.setCurrentToast({
          open: true,
          text: '영상에 공감 이모티콘을 보냈어요',
          time: 1.8,
        })
      }, 800)
    }

    return (
      <div className='fixed bg-gradient-to-t from-[#000000aa] to-[#00000000] w-full h-24 bottom-0.5'>
        <div className=' bg-[#00000000]'>
          <div className='absolute bottom-5 flex w-full'>
            {emojiList.map((emoji) => (
              <RepostEmoticonReaction
                key={`${emoji.emojiName}`}
                emoji={emoji.emoji}
                emojiName={emoji.emojiName}
                userId={viewerId}
                repostId={currentRepostId}
                videoId={currentRepostVideoId}
                onEmojiClick={onEmojiClick}
              />
            ))}
          </div>
        </div>
      </div>
    )
  },
)

export default RepostComments
